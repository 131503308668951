import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { Loader } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { WCPolicyDetailsService } from 'wni-capability-quoteandbind-wc';
import {
    ValidationIssueUtil,
    // ErrorsAndWarningsUtil,
    // WizardUtil,
    WindowUtil,
    QuoteUtil,
} from 'wni-portals-util-js';
import {
    AgencyAndProducerInfoComponent,
    PrimaryNamedInsuredInputPopup,
    PolicyDBAComponent,
    ANIComponent
} from 'wni-capability-gateway-react';
// import { ErrorsAndWarningsDisplayComponent, ValidationIssuesComponent } from 'wni-components-platform-react';
// import { PortalConstants, WizardConstants } from 'wni-portals-config-js';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import WizardPage from '../../templates/WCWizardPage';

import styles from './WCPolicyDetailsPage.module.scss';
import metadata from './WCPolicyDetailsPage.metadata.json5';
import messages from './WCPolicyDetailsPage.messages';

function WCPolicyDetailsPage(props) {

    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData: updateSubmissionVM,
        updateWizardSnapshot,
        updateWizardReadOnly,
        wizardSnapshot,
        //
        wizardStepToFieldMapping,
        markFollowingWizardStepsUnvisited,
        savePolicyDetailsData,
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        //
        wizardPageData,
        updateWizardPageData,
        readOnlyFields: {
            // effectiveDate = false,
            agencyOfRecord = false,
        },
    } = props;

    const {
        jobID: quoteID, 
        sessionUUID,
        baseData,
        // lobData: {
        //     commercialAuto: {
        //         //
        //     }
        // },
        // bindData,
        // quoteData,
    } = submissionVM.value;

    const {
        // baseState_Ext: baseState,
        primaryNamedInsured_Ext: primaryNamedInsured,
        // productCode
    } = baseData;

    // const history = useHistory();
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    // const ViewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData: { isExternalUser_Ext: isExternalUser } } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;
    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('WCPolicyDetailsPage');

    const [showErrors, updateShowErrors] = useState(false);

    const [validationIssues, updateValidationIssues] = useState(undefined);
    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);
    const policyDetailPath = 'lobData.workersComp.policyDetails';

    useEffect(() => {

    }, []);
    

    const handleAgencyChange = useCallback((value, path) => {
        if (path === 'producerCode_Ext' && _.isObject(value)) {
            _.set(submissionVM.baseData, 'producerOrLicensedAgent_Ext', '');
            _.set(submissionVM.baseData, 'servicingAgent_Ext', '');
            _.set(submissionVM.baseData, 'producerCodePublicID_Ext', value.publicID);
            _.set(submissionVM.baseData, path, value.code);
        } else {
            if (_.get(submissionVM.value.baseData, path) === value) {
                // If value is not updated, then no need to call updateWizardData()
                return;
            }
            _.set(submissionVM.baseData, path, value);
        }
        updateSubmissionVM(submissionVM);
        // if (isUpdateSnapshot) {
        //     updateWizardSnapshot(submissionVM);
        // }
    }, [submissionVM, updateSubmissionVM]);

    const getPrimaryNamedInsuredDisplayName = useCallback(() => {      
        const displayName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.displayName');
        // const firstName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.firstName');
        // const lastName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.lastName');
        return `${displayName}`;
    }, [submissionVM]);

    const getPrimaryNamedInsuredAndDisplay = useCallback(() => {
        if(_.isNil(primaryNamedInsured)) {          
            const displayName = _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].displayName');
            let primaryAddress = _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.recommendCode')
            if(_.isNil(primaryAddress)){
                primaryAddress= _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.displayName') 
            }
            // primaryAddress= _.get(submissionVM.value, 'baseData.accountContacts_Ext[0].primaryAddress.recommendCode') 
            return (
                <div>
                    <div>{displayName}</div>
                    <div>{primaryAddress}</div>
                </div> 
            );
        }
        // Not use Display Name since it is readOnly in PC
        // const displayName = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.displayName');
        let primaryAddress= _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.recommendCode')
        if (_.isNil(primaryAddress)) {
            primaryAddress = _.get(submissionVM.value, 'baseData.primaryNamedInsured_Ext.primaryAddress.displayName')
        }
        return (
            <div>
                <div>{getPrimaryNamedInsuredDisplayName()}</div>
                <div>{primaryAddress}</div>
            </div> 
        );
    }, [submissionVM]);

    const getOrganizationTypeOptions = () => {
        const options =  _.get(submissionVM.value, `lobData.workersComp.policyDetails.organizationTypeOptions`)
        return options.map((type) => {
            return {
                code: type,
                name: type
            } 
        })
    }


    const generateOverrides = useCallback(() => {
        return {
            '@all': {
                readOnly: true
            },
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            agencyAndProducerInfo: {
                model: submissionVM.baseData,
                onAgencyChange: handleAgencyChange,
                displayFields: {
                    agencyOfRecord: false,
                    licensedAgent: true,
                    servicingAgent: true
                },
                readOnlyFields: {
                    agencyOfRecordReadOnly: true
                },
                producerCodePath: 'producerCode_Ext',
                // shouldSetExternalAgencyVal: true,
                // shouldUpdateAgentOptions: !isSkipping,
                onValidate,
                isReadOnly: true
            },
            primaryNamedInsured: {
                value: getPrimaryNamedInsuredAndDisplay(),
            },
            primaryNamedInsuredEditIcon: {
                visible: false
            },
            organizationTypeMultiSelect: {
                availableValues: getOrganizationTypeOptions()
            },
            additionalInsuredSection: {
                model: _.get(submissionVM, `${policyDetailPath}.additionalInsureds`) || {},
                viewModelService,
                industryCode: _.get(submissionVM, 'value.baseData.primaryNamedInsured_Ext.industryCode_Ext'),
                caPolicyDetailsService: WCPolicyDetailsService,
                authHeader,
                sessionUUID,
                jobID: quoteID,
                organizationTypesOptions: getOrganizationTypeOptions(),
                organizationTypes: _.get(submissionVM, `value.${policyDetailPath}.organizationType`),
                policyDetailPath,
                accountContacts: _.get(submissionVM, 'value.baseData.accountContacts_Ext'),
                policyAddress: _.get(submissionVM, 'value.baseData.policyAddress'),
                existingAddlNamedInsureds: _.get(submissionVM, `value.${policyDetailPath}.unassignedAdditionalNamedInsureds`),
                FEIN: _.get(submissionVM, 'baseData.primaryNamedInsured_Ext.feinOfficialId_Ext'),
                isReadOnly: true,
            },
            policyDBA: {
                dbas: _.get(submissionVM, `value.${policyDetailPath}.policyDBAList`),
                isReadOnly: true,
                policyDetailPath
            }
        };
    }, [breakpoint, submissionVM]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            agencyproducerinfo: AgencyAndProducerInfoComponent,
            anicomponent: ANIComponent,
            policydbacomponent: PolicyDBAComponent
        }
    };

    // const isLoading = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            // onNext={isComponentValid ? onPageNext : handleValidation}
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                // onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

WCPolicyDetailsPage.propTypes = {
    ...WizardPage.propTypes,
    savePolicyDetailsData: PropTypes.func,
    readOnlyFields: {
        // effectiveDate: false,
        agencyOfRecord: true,
        // uwqReadonly: false
    }
};

WCPolicyDetailsPage.defaultProps = {
    ...WizardPage.defaultProps,
    savePolicyDetailsData: WCPolicyDetailsService.saveWCPolicyDetailsData,
    readOnlyFields: {
        // effectiveDate: false,
        agencyOfRecord: true,
        // uwqReadonly: false
    }
} 
export default WCPolicyDetailsPage;