import { defineMessages } from 'react-intl';

export default defineMessages({
    address: {
        id: 'gateway.views.new-quote.Address',
        defaultMessage: 'Mailing Address'
    },
    accountNumber: {
        id: 'gateway.views.new-quote-account.Account Number',
        defaultMessage: 'Account Number'
    },
    insured: {
        id: 'gateway.views.new-quote-account.Insured',
        defaultMessage: 'Insured'
    },
    companyAddress: {
        id: 'gateway.views.new-quote.Policy Address',
        defaultMessage: 'Policy Address'
    },
    contactInformationTitle: {
        id: 'gateway.views.new-quote.Contact Information',
        defaultMessage: 'Contact Information'
    },
    contactSSN: {
        id: 'gateway.views.new-quote.SSN',
        defaultMessage: 'SSN'
    },
    descriptionOfBusiness: {
        id: 'gateway.views.new-quote.Description of Business',
        defaultMessage: 'Description of Business'
    },
    officialIdsTitle: {
        id: 'gateway.views.new-quote.Official IDs',
        defaultMessage: 'Official IDs'
    },
    selectProducerTitle: {
        id: 'gateway.views.new-quote.Select Producer',
        defaultMessage: 'Select Producer'
    },
    phoneNumberRequired: {
        id: 'gateway.views.new-quote.A Primary Phone Number is required.',
        defaultMessage: 'A Primary Phone Number is required.'
    },
    requiredPhoneForIssuanceSchema: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.warnings.required.issuance',
        defaultMessage: 'At least one Phone number is required for issuance.'
    },
    requiredForQuoteSchema: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.errors.required.quote',
        defaultMessage: "Field '{fieldName}' is required for quote."
    },
    activitySubject: {
        id: 'gateway.views.new-quote.Agent Risk Reservation Referral',
        defaultMessage: 'Agent Risk Reservation Referral'
    },
    activityDescription: {
        id: 'gateway.views.new-quote.Activity Description',
        defaultMessage: 'Quote submitted by agent has other matching accounts. Please review risk reservation in Risk Analysis screen and note decision for Underwriter'
    },
    createNew: {
        id: 'gateway.views.new-quote.Create New',
        defaultMessage: 'Create New'
    },
    matchingAccountFound: {
        id: 'gateway.views.new-quote.Matching Account Found',
        defaultMessage: 'Matching Account Found'
    },
    productEmptyMessage: {
        id: 'gateway.views.new-quote.At least one product must be selected',
        defaultMessage: 'At least one product must be selected'
    },
    productWarningMessage: {
        id: 'gateway.views.new-quote.Currently only PA, HO can be created in local',
        defaultMessage: 'Currently only PA, HO can be created in local'
    },
    homeSpecialValid: {
        id:'gateway.views.new-quote.home special valid message',
        defaultMessage: 'We are working hard to get our new Home product out to your state and as part of that effort, you are now seeing both the Auto tile and the Home tile.  This Home tile will not take you to a live quoting portal until we are live in your state.  Please stay tuned for details!'
    },
    industryEmptyMessage: {
        id: 'gateway.views.new-quote.Please fill the correct industry code or industry description',
        defaultMessage: 'Please fill the correct industry code or industry description'
    }
});
