
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WniAccountService } from 'wni-capability-gateway';
import { useWniModal } from 'wni-components-platform-react';
import AgencyDetailsPopup from './modal/AgencyDetailsPopup';
import metadata from './AgencyAndProducerInfoComponent.metadata.json5';
import styles from './AgencyAndProducerInfoComponent.module.scss';

const AgencyAndProducerInfoComponent = (props) => {
    const modalApi = useWniModal();
    const {
        model: submissionVM,
        onAgencyChange,
        displayFields: {
            agencyOfRecord: showAgencyOfRecord = false,
            licensedAgent: showLicensedAgent = false,
            servicingAgent: showServicingAgent = false
        },
        producerCodePath,
        showErrors,
        isReadOnly,
        shouldUpdateAgentOptions,
        shouldSetExternalAgencyVal,
        onValidate,
        readOnlyFields: {
            agencyOfRecordReadOnly = false
        },
    } = props;

    const {
        agencyInfoData_Ext: {
            agencyMatchData: originAgencyMatchData,
            licensedAgentData: originLicensedAgentDataList,
            servicingAgentData: originServicingAgentDataList
        }
    } = submissionVM.value

    const { authHeader, authUserData } = useAuthentication();
    const userPublicID = authUserData && authUserData.publicID;
    const isExternalUser = _.get(authUserData, 'isExternalUser_Ext');

    const initAgencyDisplayName = _.get(submissionVM, 'value.producerDisplay_Ext');

    const viewModelService = useContext(ViewModelServiceContext);
    const [licensedAgentOptions, updateLicensedAgentOptions] = useState([]);
    const [servicingAgentOptions, updateServicingAgentOptions] = useState([]);
    const [agencyDisplayName, updateAgencyDisplayName] = useState(initAgencyDisplayName);
    const [externalAgentOptions, updateExternalAgentOptions] = useState([]);
    const [externalDisplayName, updateExternalDisplayName] = useState();
    const [componentInitialized, updatecomponentInitialized] = useState(false);

    // Init search keyword with producer code
    const [searchValue, updateSearchValue] = useState(_.get(submissionVM.value, producerCodePath));
    const [producerCodePublicID, updateProducerCodePublicID] = useState(_.get(submissionVM, 'value.producerCodePublicID_Ext'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateLicensedAndServicingProducerOptions = async(newproducerCodePublicID) => {
        const agencyInfoData = await WniAccountService.getAgencyInfoData('', newproducerCodePublicID, userPublicID, authHeader)

        const newLicensedProducer = _.get(agencyInfoData, 'licensedAgentData')
        if (!_.isEmpty(newLicensedProducer)){
            const licensedAgents = newLicensedProducer.map((value) => {
                return {
                    code: value.publicID,
                    name: value.displayName
                };
            });
            updateLicensedAgentOptions(licensedAgents);
        } else {
            updateLicensedAgentOptions([])
        }
        
        const newServicingProducerOptions = _.get(agencyInfoData, 'servicingAgentData')
        if (!_.isEmpty(newServicingProducerOptions)){
            const servicingAgents = newServicingProducerOptions.map((value) => {
                return {
                    code: value.publicID,
                    name: value.displayName
                };
            });
            updateServicingAgentOptions(servicingAgents);
        }else {
            updateServicingAgentOptions([])
        }
        
    }

    const handleAgentValue = (value, path) => {
        onAgencyChange(value, path);
    };

    const handleExternalAgency = (value) => {
        onAgencyChange(value, producerCodePath);
        updateExternalDisplayName(value);
        updateProducerCodePublicID(_.get(value, 'publicID'));
        updateLicensedAndServicingProducerOptions(_.get(value, 'publicID'));
    };

    const handleAgencyDisplayChange = (changeValue) => {
        let searchKeyWord = changeValue;
        updateAgencyDisplayName(changeValue);
        onAgencyChange(changeValue, producerCodePath);
        // Set search keyword as agency code when agency input match agency value regex
        const fullAgencyValueRegex = /(([\w\d_()]+\s)*[\w\d_()]+\s-\s){2}(\w+\s)*\w+,\s(\w+\s)*\w+/g;
        if (searchKeyWord.match(fullAgencyValueRegex)) {
            const agencyCodeRegex = /([\w\d_()]+\s)*[\w\d_()]+/g;
            [searchKeyWord] = searchKeyWord.match(agencyCodeRegex);
        }
        updateSearchValue(searchKeyWord);
    };

    useEffect(() => {
        updatecomponentInitialized(true);
        if (!shouldUpdateAgentOptions) {
            return;
        }
        // autofill licensed producer field
        // const selectLicensed = _.get(submissionVM, 'producerOrLicensedAgent_Ext.value');
        // onAgencyChange(selectLicensed, 'producerOrLicensedAgent_Ext');
        if (!_.isEmpty(originLicensedAgentDataList)){
            const oriLicensedAgents = originLicensedAgentDataList.map((value) => {
                return {
                    code: value.publicID,
                    name: value.displayName
                };
            });
            updateLicensedAgentOptions(oriLicensedAgents);
        }
        // autofill servicing producer field
        // const selectServicing = _.get(submissionVM, 'servicingAgent_Ext.value');
        // onAgencyChange(selectServicing, 'servicingAgent_Ext');
        if (!_.isEmpty(originServicingAgentDataList)){
            const oriServicingAgents = originServicingAgentDataList.map((value) => {
                return {
                    code: value.publicID,
                    name: value.displayName
                };
            });
            updateServicingAgentOptions(oriServicingAgents);
        }
        if (isExternalUser) {
            // Display Agency field
            const agencies = originAgencyMatchData.map((selectRow) => {
                const agencyDisplay = `${selectRow.code} - `
                    // + `${selectRow.orgName_Ext ? `${selectRow.orgName_Ext}-` : null} `
                    + `${selectRow.description ? `${selectRow.description}-` : null} `
                    + `${selectRow.city_Ext ? selectRow.city_Ext : null}, `
                    + `${selectRow.state_Ext ? selectRow.state_Ext : null}`;
                return {
                    code: selectRow.code,
                    name: agencyDisplay,
                    publicID: selectRow.publicID
                };
            });
            updateExternalAgentOptions(agencies);
            // autofill agency field
            if (!_.isEmpty(initAgencyDisplayName)) {
                const code = _.get(submissionVM.value, producerCodePath);
                const publicID = _.get(submissionVM, 'value.producerCodePublicID_Ext');
                updateExternalDisplayName(
                    {
                        code: code,
                        publicID: publicID
                    }
                );
            }
            // set default agency value if needed
            if (shouldSetExternalAgencyVal && !_.isNil(originAgencyMatchData.length) && originAgencyMatchData.length === 1) {
                onAgencyChange(agencies[0], producerCodePath);
                if (_.isEmpty(initAgencyDisplayName)) {
                    updateExternalDisplayName(agencies[0]);
                }
            }
        } else {
            // eslint-disable-next-line no-lonely-if
            if (_.isEmpty(initAgencyDisplayName)) {
                if (!_.isNil(originAgencyMatchData.length) && originAgencyMatchData.length === 1) {
                    const selectRow = originAgencyMatchData[0];
                    const agencyDisplay = `${selectRow.code} - `
                        // + `${selectRow.orgName_Ext ? `${selectRow.orgName_Ext}-` : null} `
                        + `${selectRow.description ? `${selectRow.description}-` : null} `
                        + `${selectRow.city_Ext ? selectRow.city_Ext : null}, `
                        + `${selectRow.state_Ext ? selectRow.state_Ext : null}`;
                    updateAgencyDisplayName(agencyDisplay);
                    if (onAgencyChange) {
                        onAgencyChange(selectRow, producerCodePath);
                    }
                }

            }
        }
    }, [shouldUpdateAgentOptions]);


    const showModal = useCallback(
        (agencyVM) => {
            const componentProps = {
                title: 'Agency Search Data',
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                actionBtnLabel: 'OK',
                cancelBtnLabel: 'Cancel',
                size: 'lg',
                // authHeader: authHeader,
                agencyVM,
                viewModelService: viewModelService,
                producerCodePath,
                searchValue: searchValue,
                onAgencyChange,
                updateAgencyDisplayName,
                updateSearchValue,
                updateProducerCodePublicID,
                updateLicensedAndServicingProducerOptions
            };
            return modalApi.showModal(
                <AgencyDetailsPopup {...componentProps} />
            );
        },
        [searchValue, authHeader, onAgencyChange, producerCodePath, viewModelService]
    );

    const agencyPopupSearch = useCallback(() => {
        showModal(submissionVM).then((res) => {
            let producerCode = '';
            const code = _.get(res, 'code');
            const publicID = _.get(res, 'publicID');
            if (!_.isUndefined(code)) {
                updateSearchValue(code);
                updateProducerCodePublicID(_.get(res, 'publicID'));
                producerCode = res;
            }
            if (onAgencyChange) {
                onAgencyChange(producerCode, producerCodePath);
            }
            updateLicensedAndServicingProducerOptions(publicID)
        }).catch(() => {
            _.noop();
        });
    }, [submissionVM, producerCodePath, showModal]);

    //-------------------------------------------
    const overrideProps = {
        '@all': {
            readOnly: isReadOnly,
            tabIndex: -1
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true
        },
        agencyOfRecord: {
            visible: showAgencyOfRecord
        },
        iconSearchButton: {
            visible: !isReadOnly
        },
        licensedProducer: {
            path: isReadOnly ? 'producerOrLicensedAgentUserDisplayName_Ext' : 'producerOrLicensedAgent_Ext',
            visible: showLicensedAgent,
            availableValues: licensedAgentOptions,
            onValueChange: handleAgentValue
        },
        accountContact: {
            path: isReadOnly ? 'servicingAgentDisplayName_Ext' : 'servicingAgent_Ext',
            visible: showServicingAgent,
            availableValues: servicingAgentOptions,
            onValueChange: handleAgentValue
        },
        personalAutoAgency: {
            value: agencyDisplayName,
            // used for internal user
            // readOnly: agencyOfRecordReadOnly
        },
        internalAgencyInfoContainer: {
            visible: !isExternalUser,
        },
        externalAgencyList: {
            visible: isExternalUser,
            required: componentInitialized,
            availableValues: externalAgentOptions,
            onValueChange: handleExternalAgency,
            value: externalDisplayName,
            readOnly: agencyOfRecordReadOnly
        }
    };

    const readValue = (id, path) => {
        if (id === 'personalAutoAgency') {
        // Don't reset agencyDisplayName as producerDisplay when user clear agency input
            if (producerCodePath === 'producerCode') {
                return agencyDisplayName;
            }
            return _.get(submissionVM, 'value.producerDisplay_Ext');
        }
        return readViewModelValue(
            metadata.componentContent,
            submissionVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            agencyPopupSearch: agencyPopupSearch,
            handleAgencyDisplayChange
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={submissionVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
            onValueChange={onAgencyChange}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
};

AgencyAndProducerInfoComponent.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    onAgencyChange: PropTypes.func,
    displayFields: PropTypes.shape({
        agencyOfRecord: PropTypes.bool,
        licensedAgent: PropTypes.bool,
        servicingAgent: PropTypes.bool,
    }),
    producerCodePath: PropTypes.string,
    showErrors: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    /**
     * Whether to update the options for Producer/Licensed Agent and Servicing Agent.
     *
     * Note that this flag also controls whether to set the default Service Agent:
     * i.e. When the servicingAgent is <none>, whether set it to current Portal User
     * (If it is external user).
     *
     * Default to true for backward compatability.
     */
    shouldUpdateAgentOptions: PropTypes.bool,
    shouldSetExternalAgencyVal: PropTypes.bool,
    onValidate: PropTypes.func,
};

AgencyAndProducerInfoComponent.defaultProps = {
    model: {},
    onAgencyChange: undefined,
    displayFields: {},
    producerCodePath: 'producerCode',
    showErrors: true,
    isReadOnly: false,
    shouldUpdateAgentOptions: true,
    shouldSetExternalAgencyVal: false,
    onValidate: _.noop
};

export default AgencyAndProducerInfoComponent;
