import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class WniAccountQuoteService {
    static getAQPrendingTransactionsForAccount(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('WniAccountQuoteHandler'),
            'getAQPrendingTransactionsForAccount', [accountNumber], additionalHeaders);
    }

    static retrieveAccountQuoteData(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('WniAccountQuoteHandler'),
            'retrieveAccountQuoteData', [accountNumber], additionalHeaders);
    }

    static saveAccountHolder(saveAccountHolderReqDTO, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('WniAccountQuoteHandler'),
            'saveAccountHolder', [saveAccountHolderReqDTO], additionalHeaders);
    }

    static isPUSuggested(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('WniAccountQuoteHandler'),
            'isPUSuggested', [accountNumber], additionalHeaders);
    }

    static calculateHazardRatingLevel(saveAccountHolderReqDTO, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('WniAccountQuoteHandler'),
            'calculateHazardRatingLevel', [saveAccountHolderReqDTO], additionalHeaders);
    }
}
