import {
    useState,
    useCallback,
    useMemo,
    useEffect,
} from 'react';
import _ from 'lodash';
import { resolveComponentFromName } from '@jutro/uiconfig';
import { WizardStepUtil } from 'wni-portals-util-js';
/**
 * Attempts to resolve the string component
 * used in the wizard step. If no match is found or if the `component`
 * is left unchanged
 *
 * @param {WizardStep} stepDefinition a step definition
 * @returns {WizardStep}
 */
function getStepToRender(stepDefinition) {
    const { component, stepProps = {}, ...otherStepDefinitions } = stepDefinition;
    const { template } = stepProps;
    if (!_.isString(component)) {
        // component can be rendered already
        return stepDefinition;
    }
    const { component: resolvedComponent } = resolveComponentFromName(component) || { component };
    const { component: resolvedTemplate } = resolveComponentFromName(template)
        || { component: template };
    return {
        ...otherStepDefinitions,
        component: resolvedComponent,
        stepProps: {
            ...stepProps,
            template: resolvedTemplate
        }
    };
}

/**
 * Increments the `currentStepIndex` without overflowing
 *
 * @param {number} currentStepIndex the current index
 * @param {number} numOfSteps the total number of steps
 * @returns {number} the incremented number
 */
function nextIndex(currentStepIndex, numOfSteps) {
    return _.min([currentStepIndex + 1, numOfSteps - 1]);
}

/**
 * Decrements the `currentStepIndex` without overflowing
 * @param {number} currentStepIndex the current index
 * @returns {number} the decremented number
 */
function previousIndex(currentStepIndex) {
    return _.max([currentStepIndex - 1, 0]);
}

/**
 * Retuns a safe index to jump to.
 * A safe index is one smaller or equal to the `furthestIndexVisited` and greather
 * or equal than 0.
 * If the index is not in range the current step index is returned
 * @param {number} index the step to which to jump to
 * @param {number} currentStepIndex the current step index
 * @param {number} furthestIndexVisited the furthest step already visited
 * @returns {number} a checked index to jump to or the current index
 */
function jumpToIndex(index, currentStepIndex, furthestIndexVisited) {
    const isInRange = _.range(0, furthestIndexVisited + 1) // range is not inclusive
        .includes(index);
    return isInRange ? index : currentStepIndex;
}

/**
 * Replaces steps that are after a certain index with the given ones
 * @param {Array} wizardSteps the current steps for the wizard
 * @param {number} currentStepIndex the index of the current step
 * @param {Array} nextSteps the steps used to replace the existing steps that are
 *                          after the current index
 * @returns {Array}
 */
function replaceSteps(wizardSteps = [], currentStepIndex, nextSteps) {
    return _(wizardSteps)
        .take(currentStepIndex + 1) // index is 0 based
        .value()
        .concat(nextSteps);
}

/**
 * @typedef {Object} WizardStep
 * @property {string} path the relative path at which this step will render
 * @property {string} id the id for the step
 * @property {Object} component the React component to render for this step
 * @property {string} title a string/displayKey describing the title of the page
 * @property {boolean} [visited] whethera step has been visited or not
 * @property {boolean} [visited] whethera step has been submitted or not
 * @property {Object} [stepProps] an object that is passed to the step as properties
 */

/**
 * Returns an array of wizard steps including whether a step has been visited or not
 * @param {Array<WizardStep>} steps the steps in the wizard
 * @param {number} furthestIndexVisited the index of the furthest step in the wizard already visited
 * @param {number} furthestIndexSubmitted the index of the furthest step
 *                                      in the wizard that has been already submitted
 * @returns {Array<WizardStep>}
 */
function withAccessInfo(steps, furthestIndexVisited, furthestIndexSubmitted) {
    const retval = steps.map((step, index) => ({
        ...step,
        visited: index <= furthestIndexVisited,
        submitted: _.isNil(furthestIndexSubmitted) ? false : index <= furthestIndexSubmitted
    }));
    return retval;
}

/**
 * @callback parameterlessCallback a function taking no parameters
 *
 * @callback jumpToCallback a function to jump to the next step
 * @param {number} index the index to which to jump to
 *
 * @callback changeNextStepsCallback a function to replace the nexts steps in a wizard
 * @param {Array<WizardStep>} nextSteps steps that should replace those after the current step
 */

/**
 * @typedef {Object} UseWizardStepsHook
 * @property {Array<WizardStep>} steps of the wizard
 * @property {WizardStep} currentStep current step
 * @property {number} currentStepIndex index of the current step
 * @property {parameterlessCallback} goNext function to go to the next step. Index must be
 *                                          between 0 and the furthest step visited
 * @property {parameterlessCallback} goPrevious function to go to the previous step
 * @property {jumpToCallback} jumpTo function to jump to a visited step
 * @property {changeNextStepsCallback} changeNextSteps function to change what are the next steps
 *                                                     in a wizard
 * @property {parameterlessCallback} markStepSubmitted marks a step as submitted
 * @property {parameterlessCallback} clearVisitedStepsAfterCurrent function to clear the visited
 *                                                          status for steps after the current one
 * @property {boolean} isSkipping whether the wizard is skipping pages (goes forward until stopped)
 * @property {parameterlessCallback} stopSkipping a function to stop the wizard from skipping
 */

/**
 * Returns fields to manage
 * @param {Array<WizardStep>} initialSteps the initial steps of this wizard
 * @param {boolean} initialSkipState the initial state for the
 * @returns {UseWizardStepsHook} all the hooks parameters
 */
function useBaseWizardSteps(initialSteps, initialSkipState = false) {
    const [wizardSteps = initialSteps, updateWizardSteps] = useState(undefined);
    // const [wizardSteps, updateWizardSteps] = useState(initialSteps);
    const [currentStepIndex, updateCurrentStep] = useState(0);
    const [furthestIndexVisited, updateFurthestIndexVisited] = useState(0);
    const [furthestIndexSubmitted, updateFurthestIndexSubmitted] = useState(undefined);
    const [isSkipping = initialSkipState, updateSkipState] = useState(undefined);

    useEffect(() => {
        updateWizardSteps(initialSteps);
        // check if the total number of steps has been shortened
        const newTotalSteps = initialSteps.length;
        if (newTotalSteps <= currentStepIndex) {
            const newIndex = newTotalSteps - 1;
            updateCurrentStep(newIndex);
        }
    // Note: check ONLY change of initialSteps, not currentStepIndex
    }, [initialSteps]);
    /*
        Transitions
     */
    const numOfSteps = wizardSteps.length;
    const goNext = useCallback(
        () => {
            const newIndex = nextIndex(currentStepIndex, numOfSteps);
            updateCurrentStep(newIndex);
            updateFurthestIndexVisited(_.max([furthestIndexVisited, newIndex]));
        },
        [currentStepIndex, numOfSteps, furthestIndexVisited]
    );
    const goPrevious = useCallback(
        () => {
            const newIndex = previousIndex(currentStepIndex);
            updateCurrentStep(newIndex);
        },
        [currentStepIndex]
    );

    const jumpTo = useCallback(
        (index, furthestIndex = undefined) => {
            const newFurthestIndex = furthestIndex || furthestIndexVisited;
            const newIndex = jumpToIndex(index, currentStepIndex, newFurthestIndex);
            updateCurrentStep(newIndex);
        },
        [currentStepIndex, furthestIndexVisited]
    );

    /**
     * Change the following steps and mark them as unvisited
     */
    const changeNextStepsInternal = useCallback(
        (nextSteps, markFolliwingStepsUnvisited = false) => {
            updateWizardSteps(replaceSteps(wizardSteps, currentStepIndex, nextSteps));
            if (markFolliwingStepsUnvisited) {
                updateFurthestIndexVisited(currentStepIndex);
            }
        },
        [wizardSteps, currentStepIndex]
    );

    /*
        Change steps
     */
    const changeNextSteps = useCallback(
        (nextSteps) => {
            changeNextStepsInternal(nextSteps, true);
        },
        [changeNextStepsInternal]
    );

    /*
        Access
     */
    const clearVisitedStepsAfterCurrent = useCallback(
        () => {
            updateFurthestIndexVisited(currentStepIndex);
            if (furthestIndexSubmitted !== undefined) {
                updateFurthestIndexSubmitted(
                    (currentFurtherIndexSubmitted) => _.min(
                        [currentFurtherIndexSubmitted, currentStepIndex]
                    )
                );
            }
        },
        [currentStepIndex, furthestIndexSubmitted]
    );

    const stepsToRender = useMemo(
        () => {
            const retval = wizardSteps.map(getStepToRender);
            return retval;
        },
        [wizardSteps]
    );

    const stepsWithDetails = useMemo(
        () => {
            const retval = withAccessInfo(stepsToRender, furthestIndexVisited,
                furthestIndexSubmitted);
            return retval;
        },
        [stepsToRender, furthestIndexVisited, furthestIndexSubmitted]
    );

    const markStepSubmitted = useCallback(
        () => {
            // Update step.submitted
            updateFurthestIndexSubmitted(
                (currentFurtherIndexSubmitted) => _.max(
                    [currentFurtherIndexSubmitted, currentStepIndex]
                )
            );
            // // Update step.visited as well
            // updateFurthestIndexVisited(
            //     (currentFurthestIndex) => _.max(
            //         [currentFurthestIndex, currentStepIndex]
            //     )
            // );
        },
        [currentStepIndex]
    );

    /*
        Skipping
     */
    const stopSkipping = useCallback(
        () => {
            updateSkipState(false);
        },
        []
    );

    /**
     * Specify the furtest page that has been visited
     *
     * Usage Example:
     * updateFurthestPageVisited('PAQuotePage');
     *
     * @param {string | number} PageID or PageIndex
     */
    const updateFurthestPageVisited = useCallback(
        (pageIdOrIndex) => {
            const pageIndex = WizardStepUtil.getWizardPageIndex(pageIdOrIndex, wizardSteps);
            if (pageIndex >= 0) {
                updateFurthestIndexVisited(pageIndex);
            }
        },
        [wizardSteps, currentStepIndex, updateFurthestIndexSubmitted]
    );

    /**
     * Mark the pages after pageIdOrIndex as unvisited
     *
     * USAGE EXAMPLE:
     * clearVisitedStepsAfterPage(4)
     *
     * @param pageIdOrIndex
     */
    const clearVisitedStepsAfterPage = useCallback(
        (pageIdOrIndex) => {
            const pageIndex = WizardStepUtil.getWizardPageIndex(pageIdOrIndex, wizardSteps);
            if (pageIndex < 0 || pageIndex > furthestIndexVisited) {
                return;
            }
            updateFurthestIndexVisited(pageIndex);
            if (furthestIndexSubmitted !== undefined) {
                updateFurthestIndexSubmitted(
                    (currentFurtherIndexSubmitted) => _.min(
                        [currentFurtherIndexSubmitted, pageIndex]
                    )
                );
            }
        },
        [currentStepIndex, furthestIndexVisited, furthestIndexSubmitted, wizardSteps]
    );

    // ==================================
    // /**
    //  * Mark the pages unvisisted, starting from QuotePage
    //  *
    //  * USAGE EXAMPLE:
    //  * clearVisitedStepsFromQuotePage();
    //  */
    // const clearVisitedStepsFromQuotePage = useCallback(() => {
    //     const quotePageIndex = WizardStepUtil.getQuotePageIndex(wizardSteps);
    //     clearVisitedStepsAfterPage(quotePageIndex - 1);
    // }, [clearVisitedStepsAfterPage, wizardSteps]);

    /**
     * Toggle single step display base on pageId or pageIndex.
     * 
     * Notes:
     * 1, The pageIndex is checked against initial Steps instead of current Steps.
     * 2, This method only works on a single page in a following position. For multiple
     *   pages, please introduce a new method.
     * 3, This method does not mark following steps as unvisited. If there is such need, 
     *   you can follow toggleStepDisplay() with clearVisitedStepsAfterCurrent().
     * 4, 
     * 
     * Sample Usage:
     * toggleStepDisplay('PaymentPage', true);
     * toggleStepDisplay('PaymentPage', false);
     * toggleStepDisplay(5, true);
     * toggleStepDisplay(5, false);
     */
    const toggleStepDisplay = useCallback((pageIdOrIndex, showPage = true) => {
        let pageIndex = pageIdOrIndex;
        if (_.isString(pageIdOrIndex)) {
            pageIndex = initialSteps.findIndex((step) => step.id === pageIdOrIndex);
        }
        
        if ( pageIndex <= currentStepIndex) {
            return;
        }
        
        let nextSteps;
        // let shouldUpdateNextSteps = false;
        if (showPage) {
            // No need to update next steps when the page is already shown
            if (stepsWithDetails.length < initialSteps.length) {
                // shouldUpdateNextSteps = true;
                nextSteps = initialSteps.slice(currentStepIndex + 1);
            }
        // else if (!showPage && stepsWithDetails.length === initialSteps.length)
        } else if (stepsWithDetails.length === initialSteps.length) {
            // shouldUpdateNextSteps = true;
            const fromCurrentToTargetPage = initialSteps.slice(currentStepIndex + 1, pageIndex);
            const fromTargetPageOn = initialSteps.slice(pageIndex + 1);
            nextSteps = [...fromCurrentToTargetPage, ...fromTargetPageOn];
        }

        // if (shouldUpdateNextSteps) {
        if (nextSteps) {
            // changeNextSteps(nextSteps);
            changeNextStepsInternal(nextSteps, false);
        }
    }, [currentStepIndex, initialSteps, stepsWithDetails, changeNextSteps]);

    return {
        steps: stepsWithDetails,
        currentStep: stepsWithDetails[currentStepIndex],
        currentStepIndex,
        goNext,
        goPrevious,
        jumpTo,
        changeNextSteps,
        markStepSubmitted,
        clearVisitedStepsAfterCurrent,
        isSkipping,
        stopSkipping,
        updateFurthestPageVisited,
        clearVisitedStepsAfterPage,
        //
        // clearVisitedStepsFromQuotePage,
        toggleStepDisplay,
    };
}

export default useBaseWizardSteps;
