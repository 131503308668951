const sectionFieldsMap = {
    vehicleDetailsAccordion: [
        'vehicleType_Ext',
        'vin',
        'year',
        'make',
        'model',
        'costNew',
        'licenseState',
        'purchaseDate_Ext',
        'owners_Ext',
        'statedValue_Ext',
        'leaseOrRent_Ext',
        'annualMileage',
        'noVehicleNamedNonOwned_Ext'
    ],
    vehicleQuestionsAccordion: [
        'vehDrivenToWorkSchool_Ext',
        'milesToWorkSchoolOneWay_Ext',
        'vehUsedInBusOwned_Ext',
        'vehicleExplain_Ext',
        'vehExclusiveUseToFarmOwned_Ext',
        'motorHomeRented_Ext',
        'vehicleRentPercent_Ext',
        'vehicleQuestionSets_Ext.children[0]',
        'vehUsedInBizExplainOther_Ext'
    ],
    vehicleGaragingAccordion: [
        'garagedAt_Ext',
    ],
    vehicleAdditionalInterestAccordion: [
        'additionalInterests',
    ]
};

const paAdditionalInterestAvailableValueCodes = {
    Person: [
        'THIRDPARTYDESIGNEE',
        'CERTIFICATEOFINSURANCETRUST_Ext',
        'DESIGNATEDINSURED_Ext',
        'JOINTOWNERSHIP_Ext',
    ],
    Company: [
        'ADDITIONALINSURED_Ext',
        'LOSSP',
        'LOSSPA_Ext'
    ]
};

const paRelationshipToInsuredCodes = [
    'Parent_Ext',
    'SIBLING_Ext',
    'SP',
    'Ot',
    'Child_Ext'
];
const primaryNamedRelationshipToInsuredCodes = [
    'Insured_Ext',
    'Spouse_Ext',
    'Partner_Ext',
    'Child_Ext',
    'Employee_Ext',
    'Ot',
    'Parent_Ext',
    'SIBLING_Ext'
];

// deprecated
const messagesInAssignmentPage = [
    'must be assigned to a vehicle', // BR.PL.0109
    'Youthful operators cannot be assigned to the same vehicle unless all vehicles have a youthful assigned', // BR.PL.0112
    'All the vehicles must have a primary driver', // BR.PL.0139
    'assigned as primary operator on the', // BR.PL.0162
    'must be assigned as a primary operator', // BR.PL.0202, BR.PL.0227
    'has a Corporate Vehicle', // BR.PL.0228
    'All covered drivers must be assigned to a separate vehicle as Primary Operator or Student Away Operator', // BR.PL.0231
    'All Vehicles must have Primary Operator or a Student Away Operator assigned', // BR.PL.0232
    'must be assigned as Primary operator or Student Away Operator', // BR.PL.0233
    'Vehicle owner assigned as primary operator for', // BR.PL.0143, trigger when assign an owner, cannot be triggered in Assignment page
];

// deprecated
const hardStopMessagesInVehiclePage = [
    'Add liability or other than collision coverage to', // BR.PL.0001
    'Roadside Assistance Coverage is ineligible', // BR.PL.0005
    'cannot have both other than collision coverage and stated amount other than collisions coverage', // BR.PL.0015
    'New Vehicle Replacement Cost Coverage requires vehicle Purchase Date from Vehicle screen', // BR.PL.0084
    'Garaging Address is required', // BR.PL.0090
    'Street Name for Garaging Location is required', // BR.PL.0091
    'Require City, County, State, and Zip Code for Garage Address', // BR.PL.0092
    'Below required fields for issuance are missing', // BR.PL.0206
    'New vehicle replacement cost coverage requires cost new amount', // BR.PL.0218
];


export default {
    sectionFieldsMap,
    paAdditionalInterestAvailableValueCodes,
    paRelationshipToInsuredCodes,
    primaryNamedRelationshipToInsuredCodes
};
