import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wniCommonChange'), method, data, additionalHeaders);
}

/**
 * This is the server responsible for dealing with the Quote and Buy backend operations
 *
 * @export
 * @class EndorsementService
 */
export default class WniCommonChangeService {

    /**
     * Invokes CommonPolicyChangeHandler.createPolicyChange()
     * @param {object} policyChangeCreateDTO
     * @param {object} additionalHeaders
     * @returns {PolicyChangeWizardDataDTO}
     */
    static createPolicyChange(policyChangeCreateDTO, additionalHeaders = {}) {
        return processSubmission('createPolicyChange', [policyChangeCreateDTO], additionalHeaders);
    }

    /**
     * Invokes CommonPolicyChangeHandler.createPolicyChangeAndGetJobID()
     * @param {object} policyChangeCreateDTO
     * @param {object} additionalHeaders
     * @returns {string} jobID
     */
    static createPolicyChangeAndGetJobID(policyChangeCreateDTO, additionalHeaders = {}) {
        return processSubmission('createPolicyChangeAndGetJobID', [policyChangeCreateDTO], additionalHeaders);
    }

    /**
     * Invokes CommonPolicyChangeHandler.isUwLocked()
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {object} additionalHeaders
     * @returns {Promise}
     */
    static isUwLocked(jobNumber, sessionUUID, additionalHeaders = {}) {
        return processSubmission('isUwLocked', [jobNumber, sessionUUID], additionalHeaders);
    }

    /**
     * Note: only { locked } is retrieved from backend now.
     * Invokes CommonPolicyChangeHandler.loadWizardExitData()
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {object} additionalHeaders
     * @returns {Promise}
     */
    static loadWizardExitData(jobNumber, sessionUUID, additionalHeaders = {}) {
        return processSubmission('loadWizardExitData', [jobNumber, sessionUUID], additionalHeaders);
    }

    /**
     * Invokes CommonPolicyChangeHandler.loadWizardExitDataWithActiveQuotes()
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static loadWizardExitDataWithActiveQuotes(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('loadWizardExitDataWithActiveQuotes', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes CommonPolicyChangeHandler.getActiveQuotes()
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {object} authHeader
     * @returns {Promise}
     */
    static getActiveQuotes(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getActiveQuotes', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes CommonPolicyChangeHandler.saveWizardExitData()
     * @param {string} jobNumber
     * @param {string} sessionUUID
     * @param {object} portalData
     * @param {object} additionalHeaders
     * @returns {Promise}
     */
    static saveWizardExitData(jobNumber, sessionUUID, portalData, additionalHeaders = {}) {
        return processSubmission('saveWizardExitData', [jobNumber, sessionUUID, portalData], additionalHeaders);
    }

    /**
     * Invokes CommonPolicyChangeHandler.retrievePolicyChangeWizardDataStub()
     * @param {object} policyChangeRetrievalDTO
     * @param {object} additionalHeaders
     * @returns {Promise}
     */
    static retrievePolicyChangeWizardDataStub(policyChangeRetrievalDTO, additionalHeaders = {}) {
        return processSubmission('retrievePolicyChangeWizardDataStub', [policyChangeRetrievalDTO], additionalHeaders);
    }

    /**
     * Invokes CommonPolicyChangeHandler.retrievePolicyChangeWizardData()
     * @param {object} policyChangeRetrievalDTO
     * @param {object} additionalHeaders
     * @returns {Promise}
     */
    static retrievePolicyChangeWizardData(policyChangeRetrievalDTO, additionalHeaders = {}) {
        return processSubmission('retrievePolicyChangeWizardData', [policyChangeRetrievalDTO], additionalHeaders);
    }

    /**
     * Invokes CommonPolicyChangeHandler.generateNewWizardSessionID()
     * 
     * @param {object} policyChangeRetrievalDTO
     * @param {object} additionalHeaders
     * @returns {Promise}
     * Invokes CommonPolicyChangeHandler.updateCurrentBranch()
     * 
     */
    static generateNewWizardSessionID(policyChangeRetrievalDTO, additionalHeaders = {}) {
        return processSubmission('generateNewWizardSessionID', [policyChangeRetrievalDTO], additionalHeaders);
    }

    /**
     * Invokes CommonPolicyChangeHandler.updateCurrentBranch()
     * 
     * @param {string} jobID
     * @param {string} sessionID
     * @param {string} periodPublicID
     * @param {object} authHeader
     * @returns {string} 'success' or 'failure'
     */
    static updateCurrentBranch(jobID, sessionID, periodPublicID, authHeader = {}) {
        return processSubmission('updateCurrentBranch', [jobID, sessionID, periodPublicID], authHeader);
    }

    /**
     * Invokes CommonPolicyChangeHandler.updateCurrentBranchAndRefreshWizardData()
     * 
     * @param {string} jobID
     * @param {string} sessionID
     * @param {string} periodPublicID
     * @param {object} authHeader
     * @returns {object} the new WizardDataDTO
     */
    static updateCurrentBranchAndRefreshWizardData(jobID, sessionID, periodPublicID, authHeader = {}) {
        return processSubmission('updateCurrentBranchAndRefreshWizardData', [jobID, sessionID, periodPublicID], authHeader);
    }

    /**
     * Invokes CommonPolicyChangeHandler.referToUnderwriter()
     * 
     * @param {string} jobID
     * @param {string} sessionID
     * @param {string} noteForUnderwriter
     * @param {object} authHeader
     * @returns {object} the new WizardDataDTO
     */
    static referToUnderwriter(jobID, sessionID, noteForUnderwriter, authHeader = {}) {
        return processSubmission('referToUnderwriter', [jobID, sessionID, noteForUnderwriter], authHeader);
    }
}
