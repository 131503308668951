

const WizardPageDataKeyPrefix = 'WPDK_';

const WizardPageDataKeysRegistry = [
    'accountClearanceData', // -> 'WPDK_accountClearanceData',
    'paymentViewType', // -> 'WPDK_paymentViewType',
    'sideBySideData', // ->  'WPDK_sideBySideData',
    'isUwLocked', // ->  'WPDK_isUwLocked',
    //
    'copySubmission', // ->  'WPDK_copySubmission',
    'landingPage', // -> 'WPDK_landingPage',
    //
    'policyDiffData', // -> 'WPDK_policyDiffData',
    'paymentPlans', // -> 'WPDK_paymentPlans',
    'paymentMethods', // -> 'WPDK_paymentMethods',
    'dueDateExt', // -> 'WPDK_dueDateExt',
    'lastExitWizardPage', // -> 'WPDK_lastExitWizardPage',
    //
    'driverCoverages', // -> 'WPDK_driverCoverages',
    //
    'yearForMakeOptions', // -> 'WPDK_yearForMakeOptions',
    'yearMakeForModelOptions', // -> 'WPDK_yearMakeForModelOptions',
    'yearMakeModelForBodyTypeOptions', // ->  'WPDK_yearMakeModelForBodyTypeOptions',
    //
    'productSelected', // ->  'WPDK_productSelected',
    'isNewAccount', // -> 'WPDK_isNewAccount'
    //
    'selectedProducerCode', // -> 'WPDK_selectedProducerCode'
    'prendingTransactions', // -> 'WPDK_prendingTransactions'
    'issuedApprovedQuotes', // -> 'WPDK_issuedApprovedQuotes'
    'externalAgencyOptions', // -> 'WPDK_externalAgencyOptions'
    'licensedAgentOptions', // -> 'WPDK_licensedAgentOptions'
    'agentStatus', // -> 'WPDK_agentStatus'
    'servicingAgentOptions', // -> 'WPDK_servicingAgentOptions'
    'externalAgencyValue', // -> 'WPDK_externalAgencyValue'
    'licensedAgentValue', // -> 'WPDK_licensedAgentValue'
    'servicingAgentValue', // -> 'WPDK_servicingAgentValue'
    //
    'accountActiveQuotes', // -> 'WPDK_accountActiveQuotes',
    'accountCompletedQuotes', // -> 'WPDK_accountCompletedQuotes',
    //
    'policyChangeSource', // ->'WPDK_policyChangeSource'
    // 'RCTCallback',  // -> 'WPDK_RCTCallback'
    'policyChangePaymentData', // -> 'WPDK_policyChangePaymentData',
    'policyChangeDocumentsData', // -> 'WPDK_policyChangeDocumentsData',
    'policyChangeNewVersionAdded', // -> 'WPDK_policyChangeNewVersionAdded'
    'haveShownPNIPopup', // -> 'WPDK_haveShownPNIPopup'
    //
    'policySummaries', // -> 'WPDK_policySummaries'
];


// =============
/**
 * Return a map with name -> WPDK_name
 * 
 * @returns {object}
 */
function getKeyCacheMap() {
    const retval = {};
    WizardPageDataKeysRegistry.forEach((key) => {
        retval[key] = `${WizardPageDataKeyPrefix}${key}`;
    });
    return retval;
}

const WizardPageDataKeys = getKeyCacheMap();

export default WizardPageDataKeys;
