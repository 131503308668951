import React, { useContext, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
    ViewModelForm,
    ViewModelServiceContext,
} from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    AgencyInformation,
    ProductsField,
    ValidationIssuesComponent,
} from 'wni-components-platform-react';
import {
    WniAccountQuoteService,
    WniAccountService,
} from 'wni-capability-gateway';
import { AgencyOfServiceComponentForCL } from 'wni-capability-gateway-react';
import { WindowUtil, WniProductsUtil } from 'wni-portals-util-js';
import { useProductsData } from 'wni-portals-util-react';
import { useTranslator } from '@jutro/locale';
import { ServiceManager } from '@jutro/services';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import metadata from './CLPolicyHolderPage.metadata.json5';
import AccountEligibity from './AccountEligibity/AccountEligibity';
import messages from './CLPolicyHolderPage.messages';

const DTO_PATH = 'wni.edge.capabilities.gateway.account.dto.SaveAccountHolderReqDTO';
const xCenter = 'pc';

function CLPolicyHolderPage(props) {
    const viewModelService = useContext(ViewModelServiceContext);

    const history = useHistory();
    const { accountNumber } = useParams();

    const {
        location: {
            state: { 
                AOEffectiveDate, 
                baseState, 
                productSelected: AOProductSelected,
                isNewAccount } = {}, // location status could be null during local test
        },
    } = history;
    const {
        isComponentValid,
        onValidate,
        invalidFields
    } = useValidation('CLPolicyHolderPage');
    const { lobQuoteURL } = appConfig;

    const [productSelected, updateProductSelected] = useState(AOProductSelected);
    const [showErrors, updateShowErrors] = useState(false);
    const [accountDetails, updateAccountDetails] = useState({});
    const [policyHolderVM, updatePolicyHolderVM] = useState(null);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [isHazardRatingBlock, updateHazardRatingBlock] = useState(false);
    const [hazardRatingNotification, updateHazardRatingNotification] = useState();
    // for internal user functionality - search Agency
    const { authHeader, authUserData } = useAuthentication();
    const userPublicID = authUserData && authUserData.publicID;
    const isExternalUser = _.get(authUserData, 'isExternalUser_Ext');
    const [licensedAgentOptions, updateLicensedAgentOptions] = useState();
    const [servicingAgentOptions, updateServicingAgentOptions] = useState();
    const [internalAgencyValue, updateinternalAgencyValue] = useState();
    const [producerCodeDisplayName, updateProducerCodeDisplayName] = useState('');
    const [licensedAgentValue, updateLicensedAgentValue] = useState();
    const [servicingAgentValue, updateServicingAgentValue] = useState();
    const { getAvailableEffectiveDate } =  useProductsData();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies(['loadingMask']);

    const localeService = ServiceManager.getService('locale-service');
    const defaultCountryCode = localeService.getDefaultCountryCode();

    const translator = useTranslator();

    const initModelData = async (accountDetailsResponse) => {
        setLoadingMask(true);
        const newVM = await viewModelService.create(accountDetailsResponse, xCenter, DTO_PATH);
        updatePolicyHolderVM(newVM);
        setLoadingMask(false);
    };
    
    const initAccountDetails = async () => {
        setLoadingMask(true);
        const accountDetailsResponse = await WniAccountQuoteService.retrieveAccountQuoteData(accountNumber, authHeader);
        updateAccountDetails(accountDetailsResponse);
        initModelData(accountDetailsResponse)
        setLoadingMask(false);
    };

    useEffect(() => {
        if (!viewModelService) {
            return;
        }
        initAccountDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModelService]);

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        WindowUtil.scrollToInvalidField(invalidFields);
        return false;
    }, [updateShowErrors, invalidFields]);

    const validProductsComponent = () => {
        const productKey = 'productSelectedValidation';
        const productValidations = {
            key: productKey,
            type: 'error',
            reason: translator(messages.productEmptyMessage),
        };
        const validations = validationIssues.filter(
            (msg) => msg.key !== productKey
        );
        if (_.isEmpty(productSelected)) {
            updateValidationIssues([...validations, productValidations]);
            return false;
        }
        updateValidationIssues(validations);
        return true;
    };
    
    useEffect(() => {
        updateHazardRatingBlock(false);
        updateHazardRatingNotification(null);
    }, [productSelected]);

    const validHazardRating = async(level) => {
        let hazardRating = true;
        switch(level){
            case 'l_1':
                hazardRating = true;
                break;
            case 'l_2':
            case 'l_3':
                if(!isHazardRatingBlock) {
                    updateHazardRatingBlock(true);
                    updateHazardRatingNotification(messages[level]);
                    window.scrollTo(0, 0)
                    hazardRating = false;
                } else {
                    updateHazardRatingBlock(false);
                    updateHazardRatingNotification(null);
                    hazardRating = true;
                }
                break;
            case 'l_4':
            case 'l_5':
                hazardRating = false;
                if(!isHazardRatingBlock) {
                    updateHazardRatingBlock(true);
                    // const productNames = productSelected.map((item)=> getProductName(item)).join(', ');
                    const hazardMessages = translator(messages[level])
                    updateHazardRatingNotification(hazardMessages);
                    window.scrollTo(0, 0)
                } else {
                    updateHazardRatingBlock(false);
                    updateHazardRatingNotification(null);
                    history.push({
                        pathname: '/cl-account-quotes/submission-block',
                        state: {
                            blockProducts: productSelected
                        }
                    })
                }                
                break;
            default:
                hazardRating = true;
                break
        }
        return hazardRating
    };
    const generateNewSubmissionDTO = (productSelectedMap = []) => {
        const quoteBaseState =
            baseState || _.get(accountDetails, 'accountHolder.primaryAddress.state');
        const externalProducerCode = _.get(policyHolderVM.value, 'agencyData.producerCode');
        const internalProducerCode = (typeof(internalAgencyValue) === 'string') ? internalAgencyValue : _.get(internalAgencyValue,'publicID');
        return productSelectedMap.map((product) => {
            return {
                accountNumber: accountNumber,
                country: defaultCountryCode,
                effectiveDate: getAvailableEffectiveDate(product, quoteBaseState) || accountDetails.systemDate,
                producerCode: externalProducerCode || internalProducerCode,
                productCode: product,
                state: quoteBaseState,
            };
        });
    };
    const getLicensedAgentOptions = async (producerCodePublicID) => {
        const res = await WniAccountService
            .getLicensedAgentData(producerCodePublicID, authHeader);
        const options = res.map((value) => {
            return {
                code: value.publicID,
                name: value.displayName
            };
        });
        return options;
    };
    const getServicingAgentOptions = async (producerCodePublicID) => {
        const res = await WniAccountService.getServicingAgentData(producerCodePublicID, authHeader);
        const options = res.map((value) => {
            return {
                code: value.publicID,
                name: value.displayName
            };
        });
        // setServicingAgentDefaultValue
        const servicingDefaultValue = _.find(options, (item) => item.code === userPublicID);
        const servicingDefaultValueCode = _.get(servicingDefaultValue, "code")
        updateServicingAgentValue(servicingDefaultValueCode);
        return options;
    };
    const updateInternalAgency = async (value) => {
        const producerCodePublicID = value.publicID;
        const servicingRes = await getServicingAgentOptions(producerCodePublicID);
        const licensedAgentsRes = await getLicensedAgentOptions(producerCodePublicID);
        const servicingDefaultValue = _.find(servicingRes, (item) => item.code === userPublicID);
        const servicingDefaultValueCode = _.get(servicingDefaultValue, "code");
        const licensedDefaultValue = _.filter(licensedAgentsRes, (item) => item.code === userPublicID);
        updateLicensedAgentOptions(licensedAgentsRes);
        updateServicingAgentOptions(servicingRes);
        updateinternalAgencyValue(producerCodePublicID);
        updateLicensedAgentValue(licensedDefaultValue[0]);
        updateServicingAgentValue(servicingDefaultValueCode);
        updateProducerCodeDisplayName(_.get(value, 'name'))
    };
    
    const calculateHazardRatingLevel = async() => {
        /** calculate hazard rating code */
        const requestData = {
            accountNumber,
            baseState: baseState || _.get(accountDetails, 'accountHolder.primaryAddress.state'),
            newSubmissionDTO: generateNewSubmissionDTO(productSelected),
        }
        const res = await WniAccountQuoteService.calculateHazardRatingLevel(requestData,authHeader);
        return _.get(res, 'level', 'l_1');
    };

    const onNext = async () => {
        if (!isComponentValid) {
            handleValidation();
            return false;
        }
        if (!validProductsComponent()) {
            return false;
        }
        
        const level = await calculateHazardRatingLevel();
        if(!await validHazardRating(level)) {
            return false;
        }
        const sortProductSelected = await WniProductsUtil.getSortProductSelected(
            productSelected
        );
        setLoadingMask(true);
        const newAccountQuoteData = await WniAccountQuoteService.saveAccountHolder(
                {
                    ...policyHolderVM.value,
                    selectedProducts: sortProductSelected,
                    servicingAgent: _.get(policyHolderVM.value, 'agencyData.servicingAgent'),
                    producerOrLicensedAgent: _.get(policyHolderVM.value, 'agencyData.producerOrLicensedAgent'),
                    newSubmissionDTO: generateNewSubmissionDTO(sortProductSelected),
                    naicsClassLevel: level
                },
                authHeader
            );

        const submissionToOpen = _.get(newAccountQuoteData, 'submissionToOpen');
        const postalCode = _.get(newAccountQuoteData, 'accountHolder.primaryAddress.postalCode');
        setLoadingMask(false);

        history.push(lobQuoteURL[sortProductSelected[0]], {
            isReadOnly: false,
            quoteentry: {
                postalCode: postalCode,
                quoteID: submissionToOpen,
            },
        });
        return false;
    };

    const onPrevious = useCallback(async () => {
        return history.push(`/accounts/${accountNumber}/summary`)
    }, [accountNumber, history]);

    const onCancel = () => {
        updateHazardRatingBlock(false);
        updateHazardRatingNotification(null)
    };

    const writeValue = (value, path) => {
        const newVM = viewModelService.clone(policyHolderVM);
        _.set(newVM, path, value);
        updatePolicyHolderVM(newVM);
    };

    const getAccountProductAvailable = () => {
        const productsMaps = _.get(accountDetails, 'accountHolder.productAvailable_Ext', []);
        return productsMaps.map((item) => {
            return {
                ...item,
                isAvailable: isHazardRatingBlock ? false : item.isAvailable
            }
        })
    }
    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: 'left',
            disabled: isHazardRatingBlock
        },
        hazardRatingNotification: {
            visible: isHazardRatingBlock,
            message: hazardRatingNotification
        },
        validations: {
            validationIssues: validationIssues,
            visible: validationIssues.length > 0,
            scrollToIssues: true,
        },
        accountEligibity: {
            model: policyHolderVM,
            basePath: 'accountHolder',
            onValueChange: writeValue,
            onValidate,
            showErrors,
        },
        agencyServiceContent: {
            visible: isExternalUser,
            model: policyHolderVM,
            basePath: 'agencyData',
            onValueChange: writeValue,
            isExternalUser,
            onValidate,
            showErrors,
        },
        agencySearchServiceContent: {
            visible: !isExternalUser,
            licensedAgentOptions,
            servicingAgentOptions,
            // the prop name is called external, but actually is used for internal user, not suggest to change the old common component
            updateExternalAgency: updateInternalAgency,
            externalAgencyName: producerCodeDisplayName,
            externalAgencyPublicID: internalAgencyValue,
            updateLicensedAgent: updateLicensedAgentValue,
            updateServicingAgent: updateServicingAgentValue,
            licensedAgentValue,
            servicingAgentValue,
            updateLicensedAgentOptions,
            updateServicingAgentOptions,
        },
        createAccountMsg: {
            visible: !!isNewAccount,
        },
        productMapsContent: {
            productSelected,
            updateProductSelected,
            productVisible: true,
            viewModelService,
            accountType: 'company',
            accountProductAvailableMaps: getAccountProductAvailable(),
            baseState: baseState || _.get(accountDetails, 'accountHolder.primaryAddress.state')
        },
        // when hazard rating warning is show, previous button should be hide, and cancel button should be show
        previousAction: {
            visible: !isHazardRatingBlock
        },
        cancelAction: {
            visible: isHazardRatingBlock
        }
        
    };
    const resolvers = {
        resolveCallbackMap: {
            onPrevious,
            onCancel,
            onNext
        },
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
            agencyServiceinfo: AgencyInformation,
            agencySearchServiceinfo: AgencyOfServiceComponentForCL,
            productsfield: ProductsField,
            underwritingquestion: AccountEligibity
        },
    };

    if (!policyHolderVM) {
        return null;
    }
    //---------------------
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={policyHolderVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
}

export default CLPolicyHolderPage;
