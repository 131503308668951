import React, {useEffect, useCallback, useState} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WniAccountContactUtil } from 'wni-portals-util-js';
import { useWniModal } from 'wni-components-platform-react';
import { WniANIService } from 'wni-capability-quoteandbind';
import PropTypes from 'prop-types';
import {
    DropdownMenuHeader,
    DropdownMenuLink,
    Loader
} from '@jutro/components';
import ANIDetailComponent from '../ANIDetailComponent/ANIDetailComponent'
import metadata from './ANIComponent.metadata.json5';
import messages from './ANIComponent.messages';
import AddressBookPopup from '../AddressBookPopup/AddressBookPopup';

function ANIComponent(props) {
    const {
        model,
        onValueChange,
        onValidate,
        isReadOnly,
        isRequiredForIssuance,
        viewModelService,
        industryCode,
        policyDetailsService,
        authHeader,
        sessionUUID,
        jobID,
        organizationTypesOptions,
        organizationTypes,
        accountContacts,
        existingAddlNamedInsureds,
        policyDetailPath,
        policyAddress
    } = props;

    const modalApi = useWniModal();

    const translator = useTranslator();
    const [currentRow, updateCurrentRow] = useState();
    const [showErrors, updateShowErrors] = useState(false);
    const [originCurrentRow, updateOriginCurrentRow] = useState();
    const [selection, updateSelection] = useState([]);
    const [loading, updateLoading] = useState(false);

    const buildAddItemList = () => {
        return [
            { code: 'Company', name: 'New Company'},
            { code: 'Person', name: 'New Person'},
            { code: 'AddressBook', name: 'From Address Book'},
            {
                code: 'ExistingAddlNamedInsured',
                name: 'Existing Additional Named Insured',
                existingAddl: existingAddlNamedInsureds
            }
        ];
    };

    const updateSubmiaasionByResponse = (response) => {
        onValueChange(response.additionalInsureds, `${policyDetailPath}.additionalInsureds`);
        onValueChange(response.unassignedAdditionalNamedInsureds, `${policyDetailPath}.unassignedAdditionalNamedInsureds`);
        onValueChange(response.accountContacts, 'baseData.accountContacts_Ext');
    };

    const updateAdditionalInsureds = () => {
        const ANIsValue = _.get(model, 'value');
        const newANIsValue = _.map(ANIsValue, (ani) => {
            const contactCode = _.get(ani, 'contactCode_Ext');
            if (contactCode === 'Person') {
                _.set(ani, 'person_Ext.country_Ext', policyAddress.country);
            }

            return ani;
        })
        onValueChange(newANIsValue, `${policyDetailPath}.additionalInsureds`);
    };

    useEffect(() => {
        if(!isReadOnly) {
            updateAdditionalInsureds()
        }
    }, []);

    const viewOrEdit = useCallback((value, index) => {
        const childrenVM = _.get(model, 'children');
        const itemVM = childrenVM.find((item) => item.value.rowIdPath_Ext === index);
        updateShowErrors(false);
        // const contactCode = _.get(itemVM, 'value.contactCode_Ext');
        // if (contactCode === 'Person') {
        //     _.set(itemVM, 'person_Ext.country_Ext', policyAddress.country);
        // }
        updateCurrentRow(itemVM);
        updateOriginCurrentRow(viewModelService.clone(itemVM));
    }, [model, viewModelService])

    const saveExsitingContactForANI = async (publicID, displayName) => {
        const addlIds = _.map(model.value || [], (addl) => {
            return addl.contactDenormPublicID_Ext
        })
        if (_.includes(addlIds, publicID)) {
            modalApi.showConfirm({
                title: translator(
                    messages.addExistingContactWarning,
                    {
                        name: displayName
                    }
                ),
                status: 'warning',
                icon: 'gw-error-outline'
            });
            return;
        }
        updateLoading(true);
        const res = await WniANIService.saveExsitingContactForANI(jobID, sessionUUID, [publicID], authHeader);
        updateLoading(false);
        updateSubmiaasionByResponse(res);
        return res;
    }

    const searchContact = async (searchCriteria) => {
        const res = await WniANIService.searchAddressBook(jobID, sessionUUID, searchCriteria, authHeader);
        return res;
    }

    const showAddressBookModal = useCallback(() => {
        const searchCriteriaVM = viewModelService.create(
            { country: policyAddress.country },
            'pc',
            'wni.edge.capabilities.gateway.contact.dto.SearchAddressBookCriteriaDTO'
        )
        const componentProps = {
            searchCriteriaVM,
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            size: 'lg',
            onValueChange,
            searchContact,
            viewModelService,
            selectContact: saveExsitingContactForANI
        };
        return modalApi.showModal(
            <AddressBookPopup {...componentProps} />
        ).then((res) => {
            //
        }).catch(() => {
            _.noop();
        });;
    }, []);

    const addItemFn = useCallback((item) => {
        if (item.code === 'AddressBook') {
            showAddressBookModal();
        }
        if (item.code === 'Company' || item.code === 'Person') {
            const DTO_PATH = 'edge.capabilities.gateway.job.submission.dto.AdditionalNamedInsuredDTO';
            const xCenter = 'pc';
            const addItem = WniAccountContactUtil.setANIDefaultObj(item.code, industryCode, policyAddress)
            const newVM = viewModelService.create(addItem, xCenter, DTO_PATH);
            model.pushElement(newVM);
            updateShowErrors(false);
            updateCurrentRow(newVM)
            updateOriginCurrentRow(newVM);
        }
    }, [industryCode, model, showAddressBookModal, viewModelService]);

    const removeFn = useCallback(async () => {
        updateLoading(true);
        const res = await WniANIService.removeAddtionalInsureds(jobID, sessionUUID, selection, authHeader)
        if (res.removeErrorMessage) {
            modalApi.showConfirm({
                message: res.removeErrorMessage,
                status: 'error',
                icon: 'gw-error-outline'
            });
        } else {
            updateSubmiaasionByResponse(res);
        }
        updateLoading(false);
        updateCurrentRow(null);
        updateSelection([]);
    }, [selection])

    const isAddBtnDisabled = useCallback(() => {
        const items = _.get(model, 'value');
        const newlyItem = _.filter(items, (item) => {
            return !item.publicID
        })
        if (_.isEmpty(newlyItem)) {
            return false;
        }
        return true;
    }, [model]);

    const saveAddlInsured = useCallback(async () => {
        const addlInsured = currentRow.value
        if (!_.isEmpty(_.get(addlInsured, 'company')) && !_.get(addlInsured, 'company.ssnofficialID_Ext')) {
            delete addlInsured.company.ssnofficialID_Ext
        }
        updateLoading(true);
        const res = await WniANIService.saveAddtionalInsured(jobID, sessionUUID, addlInsured, authHeader);
        updateLoading(false);
        updateSubmiaasionByResponse(res);
        updateCurrentRow(null);
    }, [currentRow])

    const selectExistingAddl = async (addl) => {
        const publicIDs = [addl.contactDenormPublicID_Ext]
        updateLoading(true);
        const res = await WniANIService.saveExsitingContactForANI(jobID, sessionUUID, publicIDs, authHeader);
        updateLoading(false);
        updateSubmiaasionByResponse(res);
    };

    const renderAddItemContent = () => {
        const addItemList = buildAddItemList();
        return _.map(addItemList, (item, index) => {
            const {existingAddl} = item
            if (item.code === 'ExistingAddlNamedInsured') {
                return (
                    <DropdownMenuHeader title={item.name} key={item.code}>
                        {!_.isEmpty(existingAddl) &&
                        existingAddl.map((addl, i) => {
                            return (
                                <DropdownMenuLink key={addl.publicID} onClick={(e) => { selectExistingAddl(addl) }}>
                                    {addl.displayName_Ext}
                                </DropdownMenuLink>
                            );
                        })}
                    </DropdownMenuHeader>
                )
            }
            return (
                <DropdownMenuLink key={item.code} onClick={(e) => { addItemFn(item) }}>
                    {item.name}
                </DropdownMenuLink>
            )
        })
    }

    //---------------------
    const overrides = {
        '@field': {
            showRequired: true,
            labelPosition: 'left',
            readOnly: isReadOnly,
            isRequiredForIssuance
        },
        removeItem: {
            disabled: _.isEmpty(selection),
            visible: !isReadOnly
        },
        addItem: {
            disabled: isAddBtnDisabled(),
            content: renderAddItemContent(),
            visible: !isReadOnly
        },
        ANIsTable: {
            data: model.value,
            onSelectionChange: (rows) => updateSelection(rows),
            rowIdPath: 'rowIdPath_Ext'
        },
        name: {
            renderCell: (item) => {
                if (item.contactCode_Ext === 'Company') {
                    return item.company.contactName || ''
                }
                if (item.contactCode_Ext === 'Person') {
                    return `${item.person_Ext.firstName || ''} ${item.person_Ext.lastName || ''}`
                }
            }
        },
        ANIDetailContainer: {
            visible: Boolean(currentRow)
        },
        industryCode: {
            visible: false
        },
        viewOrEditLink: {
            label: isReadOnly ? 'View' : 'View/Edit',
            disabled: Boolean(currentRow)
        },
        ANIComponent: {
            currentRow,
            updateCurrentRow,
            onValueChange,
            originCurrentRow,
            ANIsVM: model,
            saveAddlInsured,
            organizationTypesOptions,
            organizationTypes,
            accountContacts,
            policyDetailPath,
            isReadOnly,
            policyDetailsService,
            authHeader,
            sessionUUID,
            jobID,
            viewModelService
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            viewOrEditANI: viewOrEdit,
            removeFn
        },
        resolveComponentMap: {
            caanidetailcomponent: ANIDetailComponent
        }
    };
    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, model, fieldId, fieldPath, overrides
        );
    };

    if (loading) {
        return <Loader loaded={!loading} />;
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={model}
            overrideProps={overrides}
            onValueChange={onValueChange}
            onValidationChange={onValidate}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

ANIComponent.propTypes = {
    onValueChange: PropTypes.func
};
ANIComponent.defaultProps = {
    onValueChange: _.noop
};

export default ANIComponent;
