import { setComponentMapOverrides } from '@jutro/uiconfig';

import GLDummyPage from './pages/Dummy/GLDummyPage';
import GLDummyReadOnlyPage from './pages/Dummy/GLDummyPage.readonly';

import GLQuotePage  from './pages/Quote/GLQuotePage';
import GLQuoteReadOnlyPage from './pages/Quote/GLQuotePage.readonly';


setComponentMapOverrides(
    {
        GLDummyPage: {component: 'GLDummyPage'},
        GLDummyReadOnlyPage: { component: 'GLDummyReadOnlyPage' },

        //
        GLQuotePage: {component: 'GLQuotePage'},
        GLQuoteReadOnlyPage: {component: 'GLQuoteReadOnlyPage'},

    },
    {
        GLDummyPage,
        GLDummyReadOnlyPage,

        //
        GLQuotePage,
        GLQuoteReadOnlyPage,
    }
);


export {
    GLDummyPage,
    GLDummyReadOnlyPage,
    //
};

// eslint-disable-next-line import/prefer-default-export
export { default as GLSubmissionWizard } from './GLSubmissionWizard';
export { default as GLWizardPage } from './templates/GLWizardPage';

export { default as GLQuoteIssueRenderHelper } from './pages/Quote/util/GLQuoteIssueRenderHelper';
export { default as GLQuotePageConstants } from './pages/Quote/config/GLQuotePage.config';
