import React, {
    useState,
    useEffect,
    useMemo,
} from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { SubmissionService, JobService } from 'gw-capability-gateway';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WniSubmissionService, WniActivitiesService } from 'wni-capability-gateway';
import { useTranslator } from '@jutro/locale';
import { useBusinessData } from 'wni-portals-util-react';
import { SummaryUtil, WniProductsUtil } from 'wni-portals-util-js';
import { PageHeaderComponent as FormattedPageHeader } from '../Components/FormattedHeaderComponent/FormattedHeaderComponent';
import metadata from './QuoteDetails.metadata.json5';
import CommonRoutes from '../Common/CommonRouting';
import pageRouting from '../Common/QuoteDetails-config.json5';
import messages from './QuoteDetails.messages';

function QuoteDetails(props) {
    const {
        match: {
            url,
            params: { jobNumber }
        },
        location: {
            pathname,
            state: locationState = {}
        },
        history
    } = props;

    const { authHeader, authUserData } = useAuthentication();
    const { enableCommercialLine } = useBusinessData();

    const [currentView, updateCurrentView] = useState('summary');
    const [tilesCountSubmissionData, updateTilesCountSubmissionData] = useState({});
    const [quotes, updateQuotes] = useState({});
    const { loadingMask: { setLoadingMask } } = useDependencies(['loadingMask']);
    const payStatus = _.get(history, 'location.state.payStatus');
    const accountNumber = _.get(history, 'location.state.accountNumber');
    const billingId = _.get(history, 'location.state.billingId');
    const AOLink = {
        payStatus,
        accountNumber,
        billingId
    };

    // const [isCLLine, updateIsCLLine] = useState(false);

    const isCLLine = useMemo(() => {
        const submission = quotes;
        if (_.isEmpty(submission)) {
            return false
        }
        const productCode = _.get(submission, 'productCode');

        return WniProductsUtil.isCLProduct(productCode);
    }, [quotes])
    const translator = useTranslator();

    const getActivities = async () => {
        setLoadingMask(true);
        const getAvailableActivities = await WniActivitiesService.getActivitiesForJob(
            jobNumber,
            authHeader
        );
        setLoadingMask(false);
        return getAvailableActivities;
    };

    const getQuoteSummaryCount = async () => {
        const routePath = pathname && (pathname.trim().split('/') || []);
        const selectedPageFromPath = routePath[routePath.length - 1];
        const activeTile = selectedPageFromPath || 'summary';

        const submissionSummary = await SubmissionService.getSubmissionSummary(jobNumber,
            authHeader);
        let submission = _.get(locationState, 'submissionDTO');
        if (!_.isEmpty(quotes)) {
            submission = quotes;
        } else if (!submission) {
            submission = await JobService.findJobByJobNumber(jobNumber, authHeader);
        }
        const productCode = _.get(submission, 'productCode');

        const isCL = WniProductsUtil.isCLProduct(productCode);
        let numberOfDocuments = 0;
        if (enableCommercialLine) {
            // for CL line = documents + attachments
            const docs = await WniSubmissionService
                .getDocsAndAttachsForJob([jobNumber, isCL], authHeader);
            numberOfDocuments = docs.length;
        } else {
            // for PL line = attachments
            const attachments = await WniSubmissionService
                .getPortalAttachmentsForSubmission([jobNumber, isCL], authHeader);
            numberOfDocuments = attachments.length;
        }
        submissionSummary.numberOfDocuments = numberOfDocuments;
        // const openActivities = await getActivities();
        // submissionSummary.numberOfOpenActivities = openActivities.length;
        updateCurrentView(activeTile);

        updateTilesCountSubmissionData(submissionSummary);
    };

    const updateCountInfoDriectly = (type, value) => {
        const newResponse = _.clone(tilesCountSubmissionData);
        _.set(newResponse, `numberOf${type}`, value);
        updateTilesCountSubmissionData(newResponse);
    };

    const updateQuoteSummary = (newQuotes) => {
        updateQuotes(newQuotes);
    };

    const getJobByJobNumber = async () => {
        // const submission = await JobService.findJobByJobNumber(jobNumber, authHeader);
        // from PAQuotePage::markAsNotTaken
        let submission = _.get(locationState, 'submissionDTO');
        if (!submission) {
            submission = await JobService.findJobByJobNumber(jobNumber, authHeader);
        }
        updateQuoteSummary(submission);
    };

    const addToRecentlyView = async () => {
        await WniSubmissionService.addRecentlyViewedSubmission(jobNumber, authHeader);
    };

    useEffect(() => {
        setLoadingMask(true);
        Promise.all([
            getJobByJobNumber(),
            getQuoteSummaryCount(),
            addToRecentlyView()
        ]).then(() => {
            setLoadingMask(false)
        })
        // getJobByJobNumber();
        // getQuoteSummaryCount();
        // addToRecentlyView();
    }, [jobNumber]);

    const handleTilesOnClick = (id) => {
        updateCurrentView(id);
    };

    // ==============================================================
    const renderContent = () => {
        if (_.isEmpty(quotes)) {
            return null;
        }

        const overrides = {
            [currentView]: {
                active: true
            },
            activities: {
                visible: false
            },
            notes: {
                visible: false
            },
            documents: {
                title: (enableCommercialLine) ? translator(messages.documents) : translator(messages.attachments)
            }
        };
        const quoteVM = { quotes, tilesCountSubmissionData };
        const resolvers = {
            // resolveClassNameMap: styles,
            resolveCallbackMap: {
                handleTilesOnClick: handleTilesOnClick
            },
            resolveComponentMap: {
                pageheadercomponent: FormattedPageHeader,
            }
        };
        const QuoteDetailspage = (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={quoteVM}
                overrideProps={overrides}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        );

        const quoteData = {
            jobNumber: jobNumber,
            loadQuoteSummary: quotes,
            updateJobSummary: updateQuoteSummary,
            getQuoteSummaryCount: getQuoteSummaryCount,
            getActivities: getActivities,
            AOLink,
            updateCountInfoDriectly: updateCountInfoDriectly
        };
        return (
            <>
                {QuoteDetailspage}
                <CommonRoutes
                    steps={pageRouting}
                    basePath={url}
                    quoteDetailsData={quoteData}
                />
            </>
        );
    };

    return renderContent();
}

QuoteDetails.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            jobNumber: PropTypes.string
        }),
        url: PropTypes.string
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
        state: PropTypes.object,
    }).isRequired
};

export default withRouter(QuoteDetails);
