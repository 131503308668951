import { useEffect } from 'react';
import _ from 'lodash';
import appConfig from 'app-config';
import moment from 'moment';
import { LocalDateUtil } from '@xengage/gw-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { WniDateUtil, WniProductsUtil, WniProductsDateUtil } from 'wni-portals-util-js';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { AOConfig } from 'wni-portals-config-js';


const { aoStates } = AOConfig;
const isMaxDate = (date1, date2) => {
    return moment(date1).isAfter(date2) || moment(date1).isSame(date2);
}; 

function useProductsData(useAuthenticationData) {
    const translator = useTranslator();
    const {
        authUserData: {
            isExternalUser_Ext: isExternalUser,
            licenseInformation_Ext: licenseInformation = [],
            businessData_Ext: {
                featureToggles = [],
                productLookup_Ext: productLookup = {},
                systemDate
            }
        },
    } = useAuthenticationData || useAuthentication();
    const localDate = LocalDateUtil.today();
    // get enableProductsBeforeCreationDateForPE from pc feature toggle
    const filterEnableProductsFeature = featureToggles.find((feature) => feature.code === 'EnableProductsBeforeCreationDateForPE') || {}
    const enableProductsBeforeCreationDateForPE = filterEnableProductsFeature.enabled;

    // compare systemDate with LocalDate to get the lastest date
    const newLocalDate = isMaxDate(systemDate, localDate) ? systemDate : localDate;

    const allProducts = WniProductsUtil.getAllProductsMap();
    
    const getAvailableStateAll = (data) => {
        return data.filter((item) => aoStates.includes(item.state));
    }
    // POI-26606: personal lines licensed state about user
    /** POI-41751:  for new requirement  Remove Licensed state check         */
    // const personalLinesLicenseState = licenseInformation
    //     // .filter((item) => item.linesOfAuthority === 'paline')
    //     .map((v) => v.state);

    const renderProductLookup = (productCode) => {
        const renderProduct = productLookup[productCode] || [];
        const newProduct = [];
        // following the PC, if product is available but not has state, this product is available for all state
        if (renderProduct.length === 1 && !renderProduct[0].state) {
            aoStates.forEach((item) => {
                newProduct.push({...renderProduct[0], state: item})
            });
            return newProduct;
        };
        return renderProduct;
    };  
    // product abailable is true or false according 'Available' state min startEffectiveDate
    const renderProductsMap = () => {
        const newALllProducts = allProducts.map((product) => {
            const productStateRules = renderProductLookup(product.code);
            const availableStatesAll = getAvailableStateAll(productStateRules);
            // get the 'Available' state in availableStates
            const availableStatesEnabled = availableStatesAll.filter((item) => item.availability === 'Available');

            if (!_.isEmpty(availableStatesEnabled)) {
                // get availableStates min startEffectiveDate
                const minStartEffectiveDate = availableStatesEnabled.reduce((a, b) =>!isMaxDate(a.startEffectiveDate, b.startEffectiveDate) ? a : b).startEffectiveDate;
                // whether local date is greater than the min startEffectiveDate
                const timeEnabled = isMaxDate(newLocalDate, minStartEffectiveDate);

                // get product available state array
                const availableStateCodeMap = availableStatesEnabled.map((item) => item.state);
                const stateEnabled = !_.isEmpty(availableStateCodeMap);
                // get production launch date enabled
                // const productionEnabled = product.productionDate ? isMaxDate(newLocalDate, product.productionDate) : true;
                // get product enabled
                const productEnabled = stateEnabled
                /**        for new requirement POI-41751 Remove Licensed state check         */
                // let stateEnabled;
                // if (isExternalUser && product.code !== 'PersonalAuto' && product.code !== 'HOPHomeowners') {
                //     // External user: get whether 'availableStateCodeMap' and 'personalLinesLicenseState' intersect
                //     stateEnabled = !_.isEmpty(
                //         _.intersection(
                //             availableStateCodeMap,
                //             personalLinesLicenseState
                //         )
                //     );
                // } else {
                //     // Interanl user: only judge whether there is available state
                //     stateEnabled = !_.isEmpty(availableStateCodeMap);
                // }
                /**       for new requirement POI-41751 Remove Licensed state check  --- end                       */
                return {
                    ...product,
                    name: product.productName,
                    tooltip: translator(product.tooltip),
                    available: productEnabled,
                    startEffectiveDate: minStartEffectiveDate,
                    minDate: timeEnabled ? newLocalDate : minStartEffectiveDate,
                    availableStates: availableStatesAll,
                    productRules: productStateRules
                };
            }
            return {
                ...product,
                name: product.productName,
                tooltip: translator(product.tooltip),
                available: false,
                availableStates: availableStatesAll,
                productRules: productStateRules
            };
        });
        return newALllProducts;
    };
    const generateAllProducts = renderProductsMap();
    /**
     *
     * @returns {array} available products
     */
    const getProductsMap = (accountType) => {
        const availableProducts = generateAllProducts.filter((product) => product.available);
        // filter by account type 
        if (accountType) {
            return availableProducts.filter((item) => item.accountType === accountType);
        }

        return availableProducts
    };

    /**
     *
     * @param {string} code product code
     * @returns {object} return item product
     */

    const getProductItem = (code) => {
        return _.find(generateAllProducts, (item) => item.code === code);
    };
    /**
     *
     * @param {string} code
     * @returns {string|object}
     */

    const getProductName = (code) => {
        if (!code) {
            return '';
        }
        const productItem = _.find(allProducts, (item) => item.code === code);
        return translator(_.get(productItem, 'name'));
    };

    /**
     *
     * @param {string} code product code
     * @returns {boolean} product abailable or not
     */
    const getProductEnabled = (code) => {
        const productItem = getProductItem(code);
        return productItem.available;
    };

    /**
     *
     * @param {string} code product code
     * @param {object|string} AOEffectiveDate date from ao
     * @returns {object} return effective date
     */
    const getProductEffectiveDate = (code, AOEffectiveDate) => {
        const productItem = getProductItem(code);
        const productMinDate = productItem.minDate;
        if (!AOEffectiveDate) {
            return productMinDate;
        }

        const AOEffectiveDateFormat = WniDateUtil.getDateObj(AOEffectiveDate);
        const maxDateWithAOAndMin = isMaxDate(AOEffectiveDateFormat, productMinDate) ? AOEffectiveDateFormat : productMinDate;
        return maxDateWithAOAndMin
    };

    const getProductMinDate = (code, policyState) => {
        const productItem = getProductItem(code);
        const { productRules } = productItem;

        const filterProductWithState = _.find(productRules, (item) => { return item.state === policyState} );
        if(policyState && filterProductWithState) {
            return isMaxDate(filterProductWithState.startEffectiveDate, newLocalDate) ? filterProductWithState.startEffectiveDate : newLocalDate
        }
        
        return productItem.minDate;
    };


    const getProductStartEffectiveDate = (code, policyState) => {
        const productItem = getProductItem(code);
        const { productRules } = productItem;

        const filterProductWithState = _.find(productRules, (item) => { return item.state === policyState} );
        if(policyState && filterProductWithState) {
            return filterProductWithState.startEffectiveDate
        }
        
        return productItem.minDate;
    };

    const getProductAvailableStates = (code) => {
        const productItem = getProductItem(code);
        const { availableStates = [] } = productItem;
        return availableStates.map((a) => a.state);
    };

    const getAvailableProducts = (accountType, state) => {
        const availableProducts = generateAllProducts.filter((product) => product.available);
        // filter by account type 
        let accountProducts = availableProducts
        if (accountType) {
            accountProducts = availableProducts.filter((item) => item.accountType === accountType);
        }
        // set this enable state from PC Feature Administration, if enable products before creation Date( in order to QA testing), return all available products. 
        if(enableProductsBeforeCreationDateForPE) {
            return accountProducts;
        };

        const { productsDateMaps } = WniProductsDateUtil;
        // if have not select state, will be available when the min creation date 
        if(!state) {
            const fetchProducts = accountProducts.map((item) => {
                const productCode = item.code;
                const productDateMap = productsDateMaps[productCode];
                if(!productDateMap) {
                    return item;
                }
                const filterAvailableStateForProducts = productDateMap.filter((v) => aoStates.includes(v.state));
                const minCreationDate = filterAvailableStateForProducts.reduce((a, b) =>!isMaxDate(a.creationDate, b.creationDate) ? a : b).creationDate;
                const isAfterCreationDate = isMaxDate(newLocalDate, minCreationDate);
                return {
                    ...item,
                    available: isAfterCreationDate
                }
            })
            return fetchProducts.filter((product) => product.available);
        }
        // ---------------------------------------

        // if has state, filter the available product for selected state
        const fetchProductsForState = accountProducts.map((item) => {
            const productCode = item.code;
            
            const productDateMap = productsDateMaps[productCode];
            if(!productDateMap) {
                return item;
            }
            const dateForState = productDateMap.find((eachData) => eachData.state === state);
            // if select state without dateMap, will be available whne the min creation date
            if(!dateForState) {
                const minCreationDate = productDateMap.reduce((a, b) =>!isMaxDate(a.creationDate, b.creationDate) ? a : b).creationDate;
                const isAfterCreationDate = isMaxDate(newLocalDate, minCreationDate);
                return {
                    ...item,
                    available: isAfterCreationDate
                }
            }
            const { creationDate } = dateForState;
            const isAfterCreationDate = isMaxDate(newLocalDate, creationDate);
            return {
                ...item,
                available: isAfterCreationDate
            }
        });
        
        return fetchProductsForState.filter((product) => product.available)
    };

    const getAvailableEffectiveDate = (productCode, baseState, AOEffectiveDate) => {
        const { productsDateMaps } = WniProductsDateUtil;
        const productDateMap = productsDateMaps[productCode];
        // if not find data, return minDate
        if(!productDateMap){
            const productItem = getProductItem(productCode);
            return productItem.minDate
        }
        const dateForState = productDateMap.find((eachData) => eachData.state === baseState);
        if(!dateForState) {
            return newLocalDate
        }
        const { effectiveDate } = dateForState;

        const effectiveDateFormat = WniDateUtil.getDateObj(effectiveDate);
        const availableEffectiveDate = isMaxDate(effectiveDateFormat, newLocalDate) ? effectiveDateFormat : newLocalDate;
        if(!AOEffectiveDate) {
            return availableEffectiveDate
        }
        const AOEffectiveDateFormat = WniDateUtil.getDateObj(AOEffectiveDate);
        return isMaxDate(availableEffectiveDate, AOEffectiveDateFormat) ? availableEffectiveDate : AOEffectiveDateFormat;
    };

    const getAvailablePolicyState = (productCode, policyEffectivaDate) => {
        if(!policyEffectivaDate) {
            return aoStates
        };
        const { productsDateMaps } = WniProductsDateUtil;
        const productDateMap = productsDateMaps[productCode];
        const availableState = [];
        productDateMap.forEach((item) => {
            const { state, effectiveDate } = item;
            if(isMaxDate(policyEffectivaDate, effectiveDate)) {
                availableState.push(state);
            }
        });
        return _.intersection(availableState, aoStates);
    };

    const getProductVisibleForState = (productCode, baseState) => {
        /** find available or not in PC Product Model ---satrt */ 
        const productItem = getProductItem(productCode);
        if(!productItem) {
            return false
        }
        const availableStates = _.get(productItem,'availableStates')
        const filterProductWithState = _.find(availableStates, (item) => item.state === baseState );
        if (( _.get(filterProductWithState, 'availability') !== 'Available')) {
            return false;
        };
        /** find available or not in PC Product Model ---end */ 

        /** find available or not with the localDate ---satrt */ 
        const { productsDateMaps } = WniProductsDateUtil;
        const productDateMap = productsDateMaps[productCode];
        if(!productDateMap) {
            return true;
        }
        const dateForState = productDateMap.find((eachData) => eachData.state === baseState);
        if(!dateForState) {
            return false
        }
        const { creationDate } = dateForState;
        return isMaxDate(newLocalDate, creationDate)

    };

    const getProductsMinDate = (productCode, baseState) => {
        const { productsDateMaps } = WniProductsDateUtil;
        const productDateMap = productsDateMaps[productCode];
        // if not find data, return minDate
        if(!productDateMap){
            const productItem = getProductItem(productCode);
            return productItem.minDate
        }
        const dateForState = productDateMap.find((eachData) => eachData.state === baseState);

        return dateForState.rateBookDate;
    };

    return {
        getProductsMap,
        getProductItem,
        getProductName,
        getProductEnabled,
        getProductEffectiveDate,
        getProductMinDate,
        getProductAvailableStates,
        getProductStartEffectiveDate,

        // for go live story
        getAvailableProducts,
        getAvailableEffectiveDate,
        getAvailablePolicyState,
        getProductVisibleForState,

        getProductsMinDate
    };
}

export default useProductsData;
