import { defineMessages } from 'react-intl';

export default defineMessages({
    agencyBillStatements: {
        id: 'gateway.statements.gateway-statements.Agency Bill Statements',
        defaultMessage: 'Agency Bill Statements'
    },
    commissionStatements: {
        id: 'gateway.statements.gateway-statements.Commission Statements',
        defaultMessage: 'Commission Statements'
    },
});
