import _ from 'lodash';


// from PAPolicySummaryChangePage
const TYPE_MOBILE = 'mobile';
const TYPE_HOME = 'home';
const TYPE_WORK = 'work';

function getPrimaryAddressDisplayName(primaryAddress) {
    const addressLine = _.get(primaryAddress, 'pobox_Ext.value', null) || _.get(primaryAddress, 'addressLine1.value', null);
    const addressLine2 = _.get(primaryAddress, 'addressLine2.value');
    const city = _.get(primaryAddress, 'city.value');
    const stateCode = _.get(primaryAddress, 'state.value.code');
    const zipCode = _.get(primaryAddress, 'postalCode.value');
    return `${addressLine}, ${addressLine2 ? `${addressLine2},` : ''} ${city}, \n ${stateCode}, ${zipCode}`;
}

function getPrimaryPhoneNumberDisplay(primaryNamedInsured) {
    const primaryPhoneType = _.get(primaryNamedInsured, 'primaryPhoneType.value.code');
    let phoneNumber = '';
    switch (primaryPhoneType) {
        case TYPE_MOBILE:
            phoneNumber = formatPhoneNumber(_.get(primaryNamedInsured, 'cellNumber.value'));
            phoneNumber += ' (Mobile)';
            break;
        case TYPE_HOME:
            phoneNumber = formatPhoneNumber(_.get(primaryNamedInsured, 'homeNumber.value'));
            phoneNumber += ' (Home)';
            break;
        case TYPE_WORK:
            phoneNumber = formatPhoneNumber(_.get(primaryNamedInsured, 'workNumber.value'));
            phoneNumber += ' (Work)';
            break;
        default: break;
    }
    return phoneNumber;
}

function truncatedString(str) {
    if (str) {
        const numStr = str.replace(/[^0-9]/ig, '');
        _.set(str, numStr.substring(numStr.length - 10));
        return numStr.substring(numStr.length - 10)
    }
    return str;
}

function getPrimaryPhone(accountContactVM) {
    const primaryPhoneType = _.get(accountContactVM.value, 'primaryPhoneType');
        switch (primaryPhoneType) {
            case 'mobile':
                const cellNumber = _.get(accountContactVM.value, 'cellNumber');
                return truncatedString(cellNumber);
            case 'home':
                const homeNumber = _.get(accountContactVM.value, 'homeNumber');
                return truncatedString(homeNumber);
            case 'work':
                const workNumber = _.get(accountContactVM.value, 'workNumber');
                return truncatedString(workNumber);
            default:
                break;
        }
        return null;
}

function formatPhoneNumber(phoneNumberString) {
    const cleaned = (`${  phoneNumberString}`).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `${  match[1]  }-${  match[2]  }-${  match[3]}`;
    }
    return null;
}

const isPNIValid = (PNI) => {
    const {
        firstName,
        lastName,
        primaryPhoneType,
        primaryAddress: {
            addressLine1,
            pobox_Ext: poboxExt,
        },
    } = PNI;
    let primaryPhonePath = null;
    switch (primaryPhoneType) {
        case 'mobile':
            primaryPhonePath = 'cellNumber';
            break;
        case 'home':
            primaryPhonePath = 'homeNumber';
            break;
        case 'work':
            primaryPhonePath = 'workNumber';
            break;
        default:
            break;
    }
    const isValid = !_.isEmpty(firstName)
            && !_.isEmpty(lastName)
            && !_.isEmpty(primaryPhoneType)
            && !_.isEmpty(_.get(PNI, primaryPhonePath, null))
            && (!_.isEmpty(addressLine1) || !_.isEmpty(poboxExt));
    return isValid;
};


/**
 * Comes from PAPolicySummaryChangePage::getPhoneNumber().
 * 
 * @param {object} accountContactDTO 
 * @returns {string}
 */
function getPrimaryPhoneNumber(accountContactDTO) {
    const primaryPhoneType = _.get(accountContactDTO, 'primaryPhoneType');
    let phoneNumber = '';
    // Further refactoring is needed for this switch statement
    switch (primaryPhoneType) {
        case TYPE_MOBILE:
            phoneNumber = _.get(accountContactDTO, 'cellNumber');
            break;
        case TYPE_HOME:
            phoneNumber = _.get(accountContactDTO, 'homeNumber');
            break;
        case TYPE_WORK:
            phoneNumber = _.get(accountContactDTO, 'workNumber');
            break;
        default: break;
    }
    return phoneNumber;
}

/**
 * This method verified all data from PNI
 * @param {object} accountContactDTO
 * @returns 
 */
function isNameAndAddressValid(accountContactDTO){
    const {
        firstName,
        lastName,
        contactType_Ext: contactType,
        contactName,
        primaryAddress: {
            pobox_Ext: pobox,
            addressLine1,
            postalCode,
            city,
            county,
            state
        }
    } = accountContactDTO.value;
    const companynameValid = !_.isEmpty(contactName)
    const personNameValid = !_.isEmpty(firstName) && !_.isEmpty(lastName);
    const contactDetailsValid = contactType === 'company' ? companynameValid : personNameValid
    const addressValidField = !_.isEmpty(postalCode)
        && !_.isEmpty(county)
        && !_.isEmpty(city)
        && !_.isEmpty(state);
    const addressIsValid = !_.isEmpty(pobox) ? addressValidField
        : (addressValidField && !_.isEmpty(addressLine1));
    if (addressIsValid && contactDetailsValid) {
        return true;
    }
    return false;
}

export default {
    getPrimaryAddressDisplayName,
    getPrimaryPhoneNumberDisplay,
    isPNIValid,
    getPrimaryPhoneNumber,
    isNameAndAddressValid,
    getPrimaryPhone
};
