import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import {
    AdditionalInterestComponent,
    VehicleDetailsComponent,
    VehicleGaragesComponent,
    VehicleQuestionsComponent,
} from 'wni-capability-gateway-react';
import {
    VehicleValidationUtil,
    WniAccordionValidationUtil
} from 'wni-portals-util-js';
import metadata from './VehicleComponent.metadata.json5';
import styles from './VehicleComponent.module.scss';


function VehicleComponent(props) {
    const {
        vehicleVM,
        labelPosition,
        id,
        onValidate,
        onValueChange,
        availableOwners,
        garagedAtOptions,
        coverages,
        isQuoteForNamedNonOwner,
        accountNumber,
        accountContacts,
        accountHolderBirthday,
        additionalInterestType,
        showErrors,
        readOnly,
        validVinInfo,
        updateValidVinInfo,
        quoteStatus,
        quoteFlow,
        submittingQuote,
        showGaragedAtErrors,
        updateShowGaragedAtErrors,
        getAdditionalInterestTypeOptions,
        accordionOpendIds,
        updateAccordionOpendIds,
        checkCanViewAndEdit,
        updateVehicleType,
        updateCacheOptions,
        getCacheOptions,
        isRequiredForIssuance,
        shouldSkipInitialization,
    } = props;
    // const {
    //     onValidate: setComponentValidation,
    //     isComponentValid,
    // } = useValidation(id);
    const { authHeader } = useAuthentication();

    const {
        // statedValue_Ext: statedValue,
        vehicleType_Ext: vehicleType,
        licenseState,
        // leaseOrRent_Ext: leaseOrRent
    } = vehicleVM.value;
    // const isRequiredForIssuance = quoteFlow !== 'draft' && quoteFlow !== 'firstquote' && submittingQuote;
    const isNamedNonOwner = isQuoteForNamedNonOwner;
    const isStatedValueRequired = VehicleValidationUtil
        .checkStatedValueRequired(vehicleType, coverages, isRequiredForIssuance);

    // useEffect(() => {
    //     if (onValidate) {
    //         onValidate(isComponentValid, id);
    //     }

    // }, [id, onValidate, isComponentValid]);

    const handleValueChange = useCallback((value, changedPath) => {
        // validation bypass: delete the whole AmountDTO if input is empty
        // const amountDTOs = ['statedValue_Ext', 'costNew'];
        // if (amountDTOs.includes(changedPath) && value && !value.amount) {
        //     onValueChange(changedPath, undefined);
        // } else {
        //     onValueChange(value, changedPath);
        // }
        onValueChange(value, changedPath);
    }, [onValueChange]);


    // const statedAmountNotificationVisible = useCallback(() => {
    //     const statedAmount = statedValue && statedValue.amount;
    //     return !statedAmount && isStatedValueRequired;
    // }, [isStatedValueRequired, vehicleVM]);

    const getAccordionStatesOverrides = useCallback(() => {
        return WniAccordionValidationUtil.getAccordionOverridesForVehicles(
            vehicleVM,
            isRequiredForIssuance,
            coverages,
            isQuoteForNamedNonOwner
        );
    }, [vehicleVM]);

    const getDefaultOpenedId = () => {
        const errorStateObj =
            WniAccordionValidationUtil.getAccordionOverridesForVehicles(
                vehicleVM,
                isRequiredForIssuance,
                coverages,
                isQuoteForNamedNonOwner
            );
        const filterErrorStateObj = _.pickBy(errorStateObj, (v) => { return v.errorState});
        const openedArr = _.keys(filterErrorStateObj);
       
        if(!_.isEmpty(openedArr)) {
            return _.take(openedArr)
        }
        const accordionCardIds = _.keys(errorStateObj);
        return _.take(accordionCardIds);
    };

    useEffect(() => {
        updateAccordionOpendIds(getDefaultOpenedId());
    }, []);


    const overrideProps = {
        '@all': {
            readOnly
        },
        '@field': {
            showOptional: false,
            showRequired: true,
            labelPosition,
        },
        vehicleAccordion: {
            onUpdateAccordionStates: (ids) => updateAccordionOpendIds(ids),
            accordionStates: accordionOpendIds
        },
        statedAmountNotification: {
            visible: false
        },
        vehicleDetailsSection: {
            model: vehicleVM,
            readOnly,
            showErrors,
            onValueChange,
            isOtherThanCollisionSelected: VehicleValidationUtil.isCoverageSelected(coverages, 'Other Than Collision'),
            isNewVehicleReplacementSelected: VehicleValidationUtil.isNewVehicleReplacementCoverageSelected(coverages),
            isQuoteForNamedNonOwner,
            isStatedValueRequired,
            availableOwners,
            quoteStatus,
            quoteFlow,
            validVinInfo,
            updateValidVinInfo,
            submittingQuote,
            onValidate,
            checkCanViewAndEdit,
            updateVehicleType,
            updateCacheOptions,
            getCacheOptions,
            isRequiredForIssuance,
            shouldSkipInitialization,
        },
        vehicleQuestionsSection: {
            model: vehicleVM,
            readOnly,
            onValueChange,
            showErrors,
            isQuoteForNamedNonOwner,
            onValidate
        },
        vehicleGaragesSection: {
            model: vehicleVM,
            readOnly,
            onValueChange,
            showErrors,
            garagedAtOptions,
            showGaragedAtErrors,
            updateShowGaragedAtErrors,
            onValidate
        },
        vehicleAdditionalInterestAccordion: {
            visible: !isNamedNonOwner,
        },
        additionalInterestComponentId: {
            value: _.get(vehicleVM, 'additionalInterests'),
            accountHolderBirthday,
            accountNumber,
            accountContacts,
            authHeader: authHeader,
            initialContext: {
                AdditionalInterestType: additionalInterestType
            },
            licenseStateCode: licenseState ? licenseState.code : '',
            readOnly,
            getAdditionalInterestTypeOptions
        },
        ...getAccordionStatesOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: onValidate,
        },
        resolveClassNameMap: styles,
        resolveComponentMap: {
            additionalinterestcomponent: AdditionalInterestComponent,
            vehicledetailscomponent: VehicleDetailsComponent,
            vehiclequestionscomponent: VehicleQuestionsComponent,
            vehiclegaragescomponent: VehicleGaragesComponent,
        }
    };

    const readValue = (innerId, innerPath) => {
        return readViewModelValue(metadata.pageContent, vehicleVM, innerId, innerPath, overrideProps);
    };

    const isVehicleSelected = vehicleVM && vehicleVM.value;
    return isVehicleSelected ? (
        <ViewModelForm
            model={vehicleVM}
            resolveValue={readValue}
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            onValidationChange={onValidate}
            onValueChange={handleValueChange}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    ) : (null);
}

VehicleComponent.propTypes = {
    vehicleVM: PropTypes.shape({ value: {} }).isRequired,
    labelPosition: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    showErrors: PropTypes.bool,
    readOnly: PropTypes.bool,
    isQuoteForNamedNonOwner: PropTypes.bool,
    availableOwners: PropTypes.arrayOf(PropTypes.shape({})),
    garagedAtOptions: PropTypes.arrayOf(PropTypes.shape({})),
    coverages: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]).isRequired,
    accountNumber: PropTypes.string.isRequired,
    accountContacts: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]),
    accountHolderBirthday: PropTypes.shape({ value: {} }).isRequired,
    additionalInterestType: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
    validVinInfo: PropTypes.shape({}).isRequired,
    updateValidVinInfo: PropTypes.func.isRequired,
    quoteStatus: PropTypes.shape({ code: PropTypes.string }).isRequired,
    quoteFlow: PropTypes.string.isRequired,
    showGaragedAtErrors: PropTypes.bool,
    updateShowGaragedAtErrors: PropTypes.func,
    submittingQuote: PropTypes.bool,
    getAdditionalInterestTypeOptions: PropTypes.func,
    accordionOpendIds: PropTypes.shape([]),
    checkCanViewAndEdit: PropTypes.func,
    updateVehicleType: PropTypes.func,
    updateCacheOptions: PropTypes.func,
    getCacheOptions: PropTypes.shape({}),
    isRequiredForIssuance: PropTypes.bool.isRequired,
    shouldSkipInitialization: PropTypes.bool,
};
VehicleComponent.defaultProps = {
    labelPosition: 'left',
    showErrors: true,
    readOnly: false,
    isQuoteForNamedNonOwner: false,
    availableOwners: [],
    garagedAtOptions: [],
    accountContacts: [],
    showGaragedAtErrors: false,
    updateShowGaragedAtErrors: _.noop,
    submittingQuote: false,
    getAdditionalInterestTypeOptions: _.noop,
    checkCanViewAndEdit: _.noop,
    updateVehicleType: _.noop,
    accordionOpendIds: [],
    updateCacheOptions: _.noop,
    getCacheOptions: {},
    shouldSkipInitialization: false,
};
export default VehicleComponent;
