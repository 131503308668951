import React, {
    useCallback,
    useState,
    useEffect
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useWniModal } from 'wni-components-platform-react';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from '@jutro/components';
import { WindowUtil } from 'wni-portals-util-js';
import { WniBCEmailService, WniEmailService } from 'wni-capability-quote';
import { WniSubmissionService } from 'wni-capability-gateway';
import { WniGatewayBillingSubmissionService } from 'wni-capability-gateway-billing';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { AppCacheConstants } from 'wni-portals-config-js';
import BaseActionWithEditTableComponent from '../BaseActionWithEditTableComponent/BaseActionWithEditTableComponent';
import metadata from './BaseDocumentSendViaEmailPopup.metadata.json5';
import styles from './BaseDocumentSendViaEmailPopup.module.scss';
import messages from './BaseDocumentSendViaEmailPopup.messages';

const generateDocuments = (docs = []) => {
    return docs.map((item) => {
        return {
            ...item,
            unselectable: !item.docUID
        }
    })
}

const BaseDocumentSendViaEmailPopup = (props) => {
    const {
        isOpen,
        size,
        onReject,
        onResolve,
        submission,
        documents,
        useJobNumber,
        viewModelService,
        useAuthenticationData,
        useDependenciesData,
        useAccountNumber,
        accountNumber,
        accountName,
        isBC,
        emailAddress
    } = props;
    const modalApi = useWniModal();
    const translator = useTranslator();
    const { isComponentValid, onValidate, invalidFields } = useValidation('BaseDocumentSendViaEmailPopup');
    const [showCCRecipientBtn, updateShowCCRecipientBtn] = useState(true);
    const [showBCCRecipientBtn, updateShowBCCRecipientBtn] = useState(true);
    const [showDocumentNotification, updateShowDocumentNotification] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);
    const { authUserData, authHeader } = useAuthenticationData || useAuthentication();
    const {
        applicationCache: { updateAppCacheDataFn, appCacheData },
        loadingMask: { setLoadingMask },
        domainCompany
    } = useDependenciesData || useDependencies(['applicationCache', 'loadingMask']);
    const [tableDocuments, updateTableDocuments] = useState(generateDocuments(documents));

    const [docSelectedRows, updateDocSelectedRows] = useState([]);

    const MAX_LIMIT = 10;
    useEffect(() => {
        async function fetchDocUIDs(noneUIDDocs) {
            let rs;
            if (isBC) {
                rs = await WniGatewayBillingSubmissionService.getDocumentDocUIDs(noneUIDDocs, authHeader);
            } else {
                rs = await WniSubmissionService.getDocumentDocUIDs(noneUIDDocs, authHeader);
            }
            return rs;
        }
        function updateDocUIDWithAppCache(initTableData) {
            const appDocumentForDocUIDCache = _.get(appCacheData, 'documentForDocUID', {});
            _.each(initTableData, (doc) => {
                const publicID = _.get(doc, 'publicID');
                const docUID = _.get(doc, 'docUID');
                const cachedDocUID = appDocumentForDocUIDCache[publicID];
                if (_.isEmpty(docUID) && cachedDocUID) {
                    _.set(doc, 'docUID', cachedDocUID);
                }
            });
        }

        const initTableData = documents ? documents.map((option) => {
            return {
                ...option,
                code: option.publicID,
                // hard code to set the fileSize to 1MB since currenly no file will reach 1MB
                fileSize: '1MB'
            };
        }) : [];
        if (!_.isEmpty(initTableData)) {
            // check docUIDs exist in app level cache
            updateDocUIDWithAppCache(initTableData);
            // find documents with none docUID
            const noneUIDDocs = _.filter(initTableData, (doc) => {
                const docUID = _.get(doc, 'docUID');
                return _.isEmpty(docUID);
            });
            // get the none docUID document publicIds
            const noneUIDDocPublicIDs = noneUIDDocs.map((doc) => _.get(doc, 'publicID'));
            // fetch the server
            if (_.isEmpty(noneUIDDocPublicIDs)) {
                // update the tableData
                const newDocuments = _.cloneDeep(initTableData);
                updateTableDocuments(generateDocuments(newDocuments));
            } else {
                fetchDocUIDs(noneUIDDocPublicIDs).then((res) => {
                    const toUpdate = {};
                    // update the docUID with response
                    _.each(noneUIDDocs, (doc) => {
                        const fetchedDoc = _.find(res,
                            (docDTO) => docDTO.publicID === doc.publicID);
                        _.set(doc, 'docUID', _.get(fetchedDoc, 'docUID'));
                        // reset fileSize to empty when docUID is still not getted
                        if (_.isEmpty(doc.docUID)) {
                            _.set(doc, 'fileSize', '');
                        } else {
                            // update the docUID to cache
                            toUpdate[doc.publicID] = doc.docUID;
                        }
                    });
                    // update the tableData
                    const newDocuments = _.cloneDeep(initTableData);
                    updateTableDocuments(generateDocuments(newDocuments));
                    // update cache data
                    updateAppCacheDataFn({ [AppCacheConstants.documentForDocUID]: toUpdate });
                });
            }
        }
    }, [appCacheData, authHeader, documents, isBC, updateAppCacheDataFn]);

    const createEmptyVM = useCallback(() => {
        // default saveAsDocument to true
        return viewModelService.create({ saveAsDocument: true }, 'pc', 'wni.edge.capabilities.quote.mailing.dto.WniEmailDTO');
    }, [viewModelService]);

    const createRecipientEmptyVM = useCallback(() => {
        return viewModelService.create({}, 'pc', 'wni.edge.capabilities.quote.mailing.dto.WniEmailRecipientDTO');
    }, [viewModelService]);

    const [dtoVM, updateDtoVM] = useState(createEmptyVM());

    const writeValue = useCallback(
        (value, path) => {
            const d = viewModelService.clone(dtoVM);
            _.set(d, path, value);
            updateDtoVM(d);
        },
        [dtoVM, viewModelService]
    );

    const handleShowCCRecipient = useCallback(() => {
        updateShowCCRecipientBtn(false);
    }, []);

    const handleShowBCCRecipient = useCallback(() => {
        updateShowBCCRecipientBtn(false);
    }, []);

    const getInitialData = useCallback(() => {
        // set PNI as default recipient
        const name = _.get(submission, 'policy.account.accountHolder.displayName')
            || _.get(submission, 'value.baseData.primaryNamedInsured_Ext.displayName')
            || accountName;
        let email = _.get(submission, 'policy.account.accountHolder.emailAddress1')
            || _.get(submission, 'value.baseData.primaryNamedInsured_Ext.emailAddress1')
            || emailAddress;
        if (_.isNil(email)) {
            email = _.get(submission, 'policy.account.accountHolder.emailAddress2_Ext')
            || _.get(submission, 'value.baseData.primaryNamedInsured_Ext.emailAddress2_Ext');
        }
        const data = [{
            id: '1',
            name: name,
            emailAddress: email
        }];
        return data;
    }, [accountName, submission]);

    const scrollToTarget = useCallback((target) => {
        setTimeout(() => {
            if (typeof target === 'string') {
                WindowUtil.scrollTo(target);
            } else {
                WindowUtil.scrollToInvalidField(target, true); // scroll to the invalid fields
            }
        }, 500);
    }, []);

    const sendEmail = useCallback(async () => {
        // reset no document selected alert
        updateShowDocumentNotification(false);
        // component check
        if (!isComponentValid) {
            updateShowErrors(true);
            scrollToTarget(invalidFields);
            return false;
        }
        // document selected check
        if (_.get(docSelectedRows, 'length') === 0) {
            updateShowDocumentNotification(true);
            scrollToTarget('documentNotification');
            return false;
        }
        _.set(dtoVM, 'value.documentPublicIDs', docSelectedRows);
        if (useJobNumber) {
            const jobNumber = _.get(submission, 'jobNumber');
            _.set(dtoVM, 'value.quoteID', jobNumber);
        } else if (_.get(submission, 'policy') !== null && _.get(submission, 'policy.issued')) {
            const policyNumber = _.get(submission, 'policyNumber_Ext');
            _.set(dtoVM, 'value.policyID', policyNumber);
        } else if (useAccountNumber) {
            _.set(dtoVM, 'value.accountID', accountNumber);
        } else {
            // information page does not contain jobNumber and only have quoteID
            const jobNumber = _.get(submission, 'jobNumber') || _.get(submission, 'value.quoteID');
            _.set(dtoVM, 'value.quoteID', jobNumber);
        }

        try {
            setLoadingMask(true);
            let result;
            if (isBC) {
                result = await WniBCEmailService.sendEmail(_.get(dtoVM, 'value'), authHeader);
            } else {
                result = await WniEmailService.sendEmail(_.get(dtoVM, 'value'), authHeader);
            }
            setLoadingMask(false);
            return onResolve(result);
        } catch (e) {
            setLoadingMask(false);
            return onResolve(false);
        }
    }, [isComponentValid, docSelectedRows, dtoVM, useJobNumber,
        submission, useAccountNumber, scrollToTarget, invalidFields,
        accountNumber, setLoadingMask, authHeader, onResolve, isBC]);

    const updateChildValidation = useCallback((isValid, fieldID) => {
        onValidate(isValid, fieldID);
    }, [onValidate]);

    const onSelectionChange = (rows) => {
        if(rows.length > MAX_LIMIT) {
            modalApi.showAlert({
                title: '',
                message: messages.docCannotExceedTotal,
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            }).then(() => {
                updateDocSelectedRows([])
            }, _.noop);
            return false;
        } 
        updateDocSelectedRows(rows)
    }

    const overrideProps = {
        '@all': {},
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
        },
        toRecipient: {
            infoLabel: translator(messages.toRecipient),
            initialData: getInitialData(),
            dtoVM: _.get(dtoVM, 'value.toRecipients'),
            requiredVM: createRecipientEmptyVM(),
            updateVM: (data) => { writeValue(data, 'value.toRecipients'); },
            onValidate: (isValid, fieldID) => { updateChildValidation(isValid, fieldID); },
            showErrors,
            prefix: 'to',
            viewModelService
        },
        toCCRecipient: {
            infoLabel: translator(messages.toCCRecipient),
            visible: !showCCRecipientBtn,
            dtoVM: _.get(dtoVM, 'value.ccrecipients'),
            requiredVM: createRecipientEmptyVM(),
            updateVM: (data) => { writeValue(data, 'value.ccrecipients'); },
            onValidate: (isValid, fieldID) => { updateChildValidation(isValid, fieldID); },
            showErrors,
            prefix: 'cc',
            viewModelService
        },
        toBCCRecipient: {
            infoLabel: translator(messages.toBCCRecipient),
            visible: !showBCCRecipientBtn,
            dtoVM: _.get(dtoVM, 'value.bccrecipients'),
            requiredVM: createRecipientEmptyVM(),
            updateVM: (data) => { writeValue(data, 'value.bccrecipients'); },
            onValidate: (isValid, fieldID) => { updateChildValidation(isValid, fieldID); },
            showErrors,
            prefix: 'bcc',
            viewModelService
        },
        addCCRecipientBtn: {
            content: translator(messages.addCCRecipientBtn),
            visible: showCCRecipientBtn
        },
        addBCCRecipientBtn: {
            content: translator(messages.addBCCRecipientBtn),
            visible: showBCCRecipientBtn
        },
        name: {
            label: translator(messages.senderName),
            defaultValue: _.get(authUserData, 'displayName')
        },
        email: {
            label: translator(messages.senderEmail),
            defaultValue: _.get(authUserData, 'email')
        },
        subject: {
            label: translator(messages.senderSubject),
            defaultValue: `Your ${_.get(domainCompany, 'domainName')} Insurance Documents`
        },
        documentNotification: {
            visible: showDocumentNotification
        },
        documentTableData: {
            data: tableDocuments,
            selectedRows: docSelectedRows,
            onSelectionChange: (rows) => onSelectionChange(rows),
            rowIdPath: 'code'
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleShowCCRecipient,
            handleShowBCCRecipient
        },
        resolveComponentMap: {
            baseActionWithEditTableComponent: BaseActionWithEditTableComponent
        },
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={messages.email} onClose={onReject} />
            <ModalBody id="BaseDocumentSendViaEmailPopupModal">
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={dtoVM}
                    onValidationChange={onValidate}
                    onValueChange={writeValue}
                    overrideProps={overrideProps}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    showErrors={showErrors}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">{translator(messages.cancel)}</Button>
                <Button onClick={sendEmail}>{translator(messages.send)}</Button>
            </ModalFooter>
        </ModalNext>
    );
};

BaseDocumentSendViaEmailPopup.propTypes = {
    isOpen: PropTypes.bool,
    size: PropTypes.string,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
    submission: PropTypes.shape({}),
    documents: PropTypes.arrayOf(PropTypes.shape({})),
    useJobNumber: PropTypes.bool,
    useAccountNumber: PropTypes.bool,
    accountName: PropTypes.string,
    isBC: PropTypes.bool,
    emailAddress: PropTypes.string
};

BaseDocumentSendViaEmailPopup.defaultProps = {
    isOpen: true,
    size: 'lg',
    onReject: _.noop,
    onResolve: _.noop,
    submission: {},
    documents: [],
    useJobNumber: false,
    useAccountNumber: false,
    accountName: '',
    isBC: false,
    emailAddress: null
};

export default BaseDocumentSendViaEmailPopup;
