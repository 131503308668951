import _ from 'lodash';
import { WizardStepConstants } from 'wni-portals-config-js';

const INVALID_PAGE_INDEX = -1;


/**
 * Get pageIndex from current pageInfo provided
 * @param {string | number} pageInfo the pageIndex or pageId
 * @param {array} wizardSteps
 * @returns {number}
 */
function getWizardPageIndex(pageInfo, wizardSteps = []) {
    // if (Number.isInteger(pageInfo)) {    //not supported by IE
    if (_.isNumber(pageInfo)) {
        return pageInfo;
    }
    let pageIndex = INVALID_PAGE_INDEX;
    if (pageInfo) { // pageId
        pageIndex = wizardSteps.findIndex((step) => step.id === pageInfo);
    }
    return pageIndex;
}


/**
 * Get QuotePageIndex, which is described by th "isQuotePgae" property in pa-wizard-config.json
 * EXAMPLE in the wizard config file:
 * {
 *   "id": "PAQuotePage",
 *   "title": { "id":"quoteandbind.wizard.step.Quote", "defaultMessage": "Quote" },
 *   "path": "/quote",
 *   "component": "PAQuotePage",
 *   "stepProps": {
 *        "template": "WizardPageTemplateWithTitle"
 *   },
 *   "isQuotePage": true
 * }
 * @param {array} wizardSteps
 * @returns {number} page index of the quote page
 */
function getQuotePageIndex(wizardSteps) {
    const retval = wizardSteps.findIndex((step) => step[WizardStepConstants.isQuotePage]);
    return retval;
}


/**
 * Checks whether the Wizard should stay on current page
 * @param {Object} param0 wizard data info
 * @returns {boolean}
 */
function shouldStayOnCurrentPage({
    periodStatus,
    currentStepIndex,
    lastExitWizardPageIndex,
    quotePageIndex,
}) {
    if (periodStatus !== 'Draft') {
        return false;
    }
    const furthestPageIndex = Math.min(lastExitWizardPageIndex, quotePageIndex - 1);
    return currentStepIndex >= furthestPageIndex;
}


export default {
    getWizardPageIndex,
    getQuotePageIndex,
    shouldStayOnCurrentPage,
};
