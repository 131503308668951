
import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { PortalConstants } from 'wni-portals-config-js';
import { useWniModal } from 'wni-components-platform-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { Icon, DropdownMenuLink, FormattedNumber } from '@jutro/components';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WniTableRowUtil } from 'wni-portals-util-react';
import {
    ErrorsAndWarningsUtil,
    // WizardUtil,
    WindowUtil,
    QuoteUtil,
    WniPNIUtil,
    ValidationIssueUtil,
} from 'wni-portals-util-js';

import { WCCoveragesService, WCStateInfoService } from 'wni-capability-quoteandbind-wc';
import CoveragesUtil from '../../../Coverages/util/WCCoverageUtil'
import WCStateSpecificUtil from '../../util/WCStateSpecificUtil';
import CoveredEmployeesDetails from '../CoverdEmployeesDetails/CoverdEmployeesDetails';
import CoveredEmployeeStandardCoverages from '../CoveredEmployeeStandardCoverages/CoveredEmployeeStandardCoverages';
import CoveredEmployeeSplittableCoverages from '../CoveredEmployeeSplittableCoverages/CoveredEmployeeSplittableCoverages';
import metadata from './CoveredEmployees.metadata.json5';

function CoveredEmployees(props) {
    const modalApi = useWniModal();
    const {
        submissionVM,
        stateSpecificVM,
        onValueChange,
        updateWizardSnapshot,
        syncWizardData: updateCurrentWizardData,
        updateWizardData,
        updateSubmissionVMForResponse,
        resetWizardDataToSnapshot,
        generateValidationIssues,
        messages,
        isReadOnly,
        isPolicyChange,
        extendProps: {
            jobID,
            sessionUUID,
            authHeader
        },
        showErrors,
        updateShowErrors,
        onValidate: setComponentValidation,
    } = props;

    const VALIDATION_ICON_MAP = {
        success: 'gw-check-circle',
        warning: 'gw-warning',
        error: 'gw-error',
    };

    const {
        baseData: {
            selectedVersion_Ext: selectedVersionPublicID,
        },
        lobData: {
            workersComp: {
                offerings,
            }
        },
    } = submissionVM.value;

    const [loadingClauses, setLoadingClauses] = useState(false);
    const [isEditing, setIsEditing] = useState(false)

    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [validationIssues, updateValidationIssues] = useState(undefined);

    const currentStatePublicId = _.get(stateSpecificVM.value, 'publicID');
    const coveredEmployeesVM = _.get(stateSpecificVM, 'coveredEmployees');
    const validLocations = _.get(stateSpecificVM.value, 'validLocations') || [];
    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('WCCoveredEmployees');
    const [currentRow, updateCurrentRowInteranl] = useState(null);
    const [coveredEmployeeData, updateCoveredEmployeeData] = useState([]);
    const [selection, updateSelection] = useState([]);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    // const [accordionStates, updateAccordionStates] = useState([]);

    useEffect(() => {
        setRowIdPath();
    }, [coveredEmployeesVM]);

    useEffect(() => {
        if (setComponentValidation) {
            setComponentValidation(isComponentValid, 'WCCoveredEmployees');
        }
        return () => {
            if (setComponentValidation) {
                setComponentValidation(true, 'WCCoveredEmployees');
            }
        }
    }, [setComponentValidation, isComponentValid, showErrors])
    const setRowIdPath = useCallback(() => {
        const coveredEmployees = _.get(coveredEmployeesVM, `value`);
        const newData = coveredEmployees.map((item) => {
            return {
                ...item,
                rowIdPath: item.publicID || item.rowIdPath,
                unselectable: !item.isEditEffective
            }
        });
        updateCoveredEmployeeData(newData);
    }, [coveredEmployeesVM]);

    const highlightRowFn = (activeRow) => {
        const activePublicID = activeRow ? _.get(activeRow.value, 'publicID') : null;
        WniTableRowUtil.setTablePublicIDSelected(activePublicID, 'coveredEmployeesTable');
    };

    const accordionErrorId = useCallback(() => {
        if (!currentRow) {
            return [];
        }
        const errorStateObj = WCStateSpecificUtil
            .getAccordionOverridesForStateSpecific(currentRow);
        const errorIds = Object.keys(errorStateObj).filter((key) => errorStateObj[key].errorState);
        return errorIds;
    }, [currentRow]);

    // const defaultOpenedId = useCallback(() => {
    //     const errorStateObj = WCStateSpecificUtil.getAccordionOverridesForStateSpecific(currentRow);
    //     const entries = Object.entries(errorStateObj);
    //     const openedArr = entries.find((entry) => entry[1].errorState);
    //     const openedId = _.get(openedArr, '0') || _.get(entries, '0.0');

    //     return openedId;
    // }, [currentRow]);

    const getAccordionStatesOverrides = useCallback(() => {
        return WCStateSpecificUtil.getAccordionOverridesForStateSpecific(currentRow);
    }, [currentRow]);

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        // updateAccordionStates(accordionErrorId());
        setTimeout(() => {
            WindowUtil.scrollToInvalidField(invalidFields); // scroll to the invalid fields
        }, 500);
        return false;
    }, [invalidFields]);

    const sortColumn = (a, b, sortType) => {
        highlightRowFn(currentRow);
        return DatatableUtil[sortType](a, b);
    };

    useEffect(() => {
        highlightRowFn(currentRow);
    }, [currentRow]);

    const renderAddressCell = (item, index) => {
        const childrenVM = _.get(coveredEmployeesVM, `children`);
        const itemVM = childrenVM.find((vm) => vm.value.publicID === index);
        return WCStateSpecificUtil.getPrimaryAddressDisplayName(_.get(itemVM, `location.address_Ext`))
    }

    // covered employee coverages
    const selectedVersion = offerings
        .find((offering) => offering.publicID_Ext === selectedVersionPublicID);
    const selectedVersionIndex = offerings
        .findIndex((offering) => offering.publicID_Ext === selectedVersionPublicID);
        
    const selectedState = _.get(stateSpecificVM.value, 'jurisdiction');

    const stateCoveragesIndex = _.get(selectedVersion, 'coverages.stateSpecificCoverages', [])
        .findIndex((stateSpecificCoverages) => stateSpecificCoverages.state === selectedState);

    const renderValidationCell = (item, index) => {
        const childrenVM = _.get(coveredEmployeesVM, `children`);
        const itemVM = childrenVM.find((vm) => vm.value.publicID === index);
        const hasIssuanceInvalidFields = WCStateSpecificUtil.hasIssuanceInvalidFields(itemVM);
        const {
            publicID: employeePublicID
        } = itemVM.value
        const employeeCoveragesDTO = _.get(selectedVersion, `coverages.stateSpecificCoverages[${stateCoveragesIndex}].stateEmployees`, [])
            .find(eDTO => eDTO.publicID === employeePublicID)
        const employeeCoveragesValid = !!(employeeCoveragesDTO && CoveragesUtil.isStateEmployeeCoveragesValid(employeeCoveragesDTO))
        let type;
        if(!_.get(itemVM, 'aspects.valid') || !_.get(itemVM, 'aspects.subtreeValid') || !employeeCoveragesValid) {
            type = 'error';
        } else if (hasIssuanceInvalidFields) {
            type = 'warning';
        } else {
            type = 'success';
        }
        const iconDom = <Icon id={`validationIcon${item.publicID}`} icon={VALIDATION_ICON_MAP[type]} className={`wni-icon-${type}`} />
        return WniTableRowUtil.renderCell(item.publicID, iconDom)
    };

    const updateCurrentRow = (rowData, updateSubmissionData) => {
        if(!rowData) {
            updateCurrentRowInteranl(rowData);
            return false;
        }
        // const {
        //     _dtoName,
        //     _xCenter,
        // } = rowData;
        // const initCurrentRow = viewModelService.create(rowData.value, _xCenter, _dtoName);
        updateCurrentRowInteranl(rowData);
        if(updateSubmissionData && !isReadOnly) {
            const newSubmissionVM = updateSubmission(rowData);
            updateSubmissionData(newSubmissionVM)
        }
    };

    const updateSubmission = (currentVM) => {
        const publicID = _.get(currentVM.value, 'publicID');
        const allCoveredEmployees = _.get(stateSpecificVM.value, 'coveredEmployees');
        const currentIndex = allCoveredEmployees.findIndex((item) => item.publicID === publicID); 
        const newStateSpecificVM = viewModelService.clone(stateSpecificVM);
        _.set(newStateSpecificVM.value, `coveredEmployees[${currentIndex}]`, currentVM.value);
        return newStateSpecificVM;
    };

    const syncWizardDataSnapshot = (currentVM) => {
        updateCurrentRow(currentVM, updateWizardSnapshot);
    };

    const syncWizardData = (currentVM) => {
        updateCurrentRow(currentVM, updateCurrentWizardData);
    };

    const coveredEmployeeService = useCallback(async(serviceName, serviceData, isAdd) => {
        setLoadingMask(true);
        const newStateSpecificVM = _.clone(stateSpecificVM);
        const oldCoveredEmpoyees = _.clone(coveredEmployeesVM.value) || [];
        const oldCoveredEmpoyeeIds = oldCoveredEmpoyees.map((v) => v.publicID);
        const res = await WCStateInfoService[serviceName](jobID, sessionUUID, currentStatePublicId, serviceData, authHeader);
        const stateSpecifics = _.get(res, 'wcstateSpecificInfo.stateSpecifics');
        const curerntState = stateSpecifics.find(elt => elt.publicID === currentStatePublicId);
        _.set(newStateSpecificVM, 'value', curerntState);
        updateCurrentWizardData(newStateSpecificVM);
        updateSubmissionVMForResponse(res);
        setRowIdPath();
        updateSelection([]);
        updateShowErrors(false);
        // for add new location
        if(isAdd) {
            // if find new location, this is add new data
            const newCoveredEmployee = _.get(newStateSpecificVM, `coveredEmployees.children`)
                .find((vm) => !oldCoveredEmpoyeeIds.includes(vm.value.publicID) && vm.value.isEditEffective);
            updateCurrentRow(newCoveredEmployee);
            setLoadingMask(false);
            return false;
        }
        generateValidationIssues(res.errorsAndWarnings);
    
        updateCurrentRow(null);
        setLoadingMask(false);
    }, [authHeader, jobID, sessionUUID, setLoadingMask, coveredEmployeesVM, stateSpecificVM]);


    const updateCoveredEmployee = useCallback(async() => {
        if(!isComponentValid || !currentRow.aspects.valid || !currentRow.aspects.subtreeValid || WCStateSpecificUtil.hasIssuanceInvalidFields(currentRow)) {
            handleValidation();
            return false;
        };

        await coveredEmployeeService('updateCoveredEmployee', currentRow.value);
        return true;
    }, [currentRow, handleValidation, isComponentValid, coveredEmployeeService]);

    const addCoveredEmployee = (location) => {
        coveredEmployeeService('updateCoveredEmployee', {location}, 'add');
    }
    
    const removeCoveredEmployee = () => {
        modalApi.showConfirm({
            title: messages.removeCoveredEmployeeTitle,
            message: messages.removeCoveredEmployeeDescription,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: messages.Ok,
            cancelButtonText: messages.Cancel
        }).then(async(result) => {
            if (result === 'cancel') {
                return _.noop();
            }
            syncWizardData(null);
            coveredEmployeeService('removeCoveredEmployee', selection);
        })
    }

    const onNextCoveredEmployee = useCallback(() => {
        const childrenVM = _.get(coveredEmployeesVM, `children`);
        let index = _.findIndex(childrenVM,
            (vm) => vm.value.publicID === currentRow.value.publicID);
        if (index === childrenVM.length - 1) {
            index = 0;
        }else{
            index += 1;
        }
        const indexID = _.get(childrenVM[index], 'value.publicID');
        syncWizardData(null);
        viewOrEditCoveredEmployee(null, indexID)
        WindowUtil.scrollToTop()
    }, [currentRow, coveredEmployeesVM]);

    const viewOrEditCoveredEmployee = (value, index) => {
        setLoadingMask(true);
        const childrenVM = _.get(stateSpecificVM, `coveredEmployees.children`);
        const coveredEmployeeVM = childrenVM.find((item) => item.value.publicID === index);
        syncWizardData(coveredEmployeeVM);
        setLoadingMask(false);
    }

    const renderValidLocationMenuItem = () => {
        const retval = validLocations.map((menuItem) => {
            const address = _.get(menuItem, 'address_Ext');
            const {
                publicID,
                displayName
            } = address;

            const addCoveredEmployeeFromLocation = () => {
                addCoveredEmployee(menuItem);
            }

            return (
                <DropdownMenuLink
                    type="action"
                    onClick={addCoveredEmployeeFromLocation}
                    key={`${publicID}-MenuLink`}
                >
                    {displayName}
                </DropdownMenuLink>
            );
        })

        return retval;
    }

    const writeValue = (value, path) => {
        if(currentRow) {
            _.set(currentRow, path, value);
            syncWizardData(currentRow);
        }
    };

    const cancelCoveredEmployee = () => {
        // resetWizardDataToSnapshot();
        syncWizardData(null)
    }

    

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataToDraft = useCallback((wizardData) => {
        // Refactoring Notice: Consider to extract this into a common method
        const newSubmissionVM = setWizardDataToDraft(wizardData)
        updateWizardData(newSubmissionVM);
    }, [setWizardDataToDraft, updateWizardData]);

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = useCallback((errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }, [updateValidationIssues]);

    const lobName = 'workersComp';
    
    const selectedEmployeeID = _.get(currentRow, 'value.publicID');
    const commonCoverageComponentProps = {
        selectedVersion,
        selectedVersionIndex,
        selectedEmployeeID,
        submissionVM,
        updateWizardData: updateWizardDataToDraft,
        onValidate,
        showErrors,
        updateErrorsAndWarnings: updateErrorsAndWarningsForCovComponent,
        isEditing,
        setIsEditing,
        lobName,
        coveragesService: WCCoveragesService,
        loadingClauses,
        setLoadingClauses,
        stateCoveragesIndex
    }

    //----------------------------------
    const overrideProps = {
        '@all': {
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            readOnly: isReadOnly,
            isEditable: !isReadOnly,
        },
        removeCoveredEmployee: {
            visible: !isReadOnly && !isPolicyChange,
            disabled: _.isEmpty(selection)
        },
        addCoveredEmployee: {
            visible: !isReadOnly && !isPolicyChange,
            content: renderValidLocationMenuItem(),
        },
        coveredEmpoyeeValidationIcon: {
            renderCell: renderValidationCell
        },
        coveredEmployeesTable: {
            data: coveredEmployeeData,
            onSelectionChange: (rows) => {
                updateSelection(rows)
            },
            rowIdPath: 'rowIdPath'
        },
        effectiveDate: {
            renderCell: WCStateSpecificUtil.renderEffectiveDateCell
        },
        expirationDate: {
            renderCell: WCStateSpecificUtil.renderExpirationDateCell
        },
        coveredEmployeesAccordion: {
            visible: currentRow != null
        },
        coveredEmployeesCoveragesAccordion: {
            visible: currentRow != null && !isPolicyChange,
        },
        annualPayroll: {
            renderCell: (row) => <FormattedNumber value={row.annualPayroll} />
        },
        viewOrEditLink: {
            disabled: currentRow || selection.length > 1,
            label: isReadOnly ? messages.wcViewLabel : messages.wcViewAndEditLabel
        },
        coveredEmployeesDetailsSection: {
            visible: !_.isEmpty(currentRow),
            coveredEmployeeVM: currentRow,
            onValueChange: writeValue,
            syncWizardData,
            syncWizardDataSnapshot,
            updateValidationIssues,
            updateSubmissionVMForResponse,
            onValidate,
            showErrors,
            isReadOnly,
            isPolicyChange,
            statePublicID: _.get(stateSpecificVM.value, 'publicID'),
            extendProps: {
                jobID,
                sessionUUID,
                authHeader
            }
        },
        coveredEmployeeStandardSection: {
            visible: !_.isEmpty(currentRow),
            ...commonCoverageComponentProps
        },
        coveredEmployeeSplittableSection: {
            visible: !_.isEmpty(currentRow),
            ...commonCoverageComponentProps
        },
        saveButtons: {
            visible: !isReadOnly
        },
        saveNextButton: {
            visible: coveredEmployeesVM.value.length > 1
        },
        ...getAccordionStatesOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            removeCoveredEmployee,
            addCoveredEmployee,
            viewOrEditCoveredEmployee,
            cancelCoveredEmployee,
            // saveAndNextCoveredEmployee: () => {
            //     updateCoveredEmployee().then((valid) => {
            //         if (valid) {
            //             syncWizardData(null);
            //         }
            //     });
            // },
            saveCoveredEmployee: () => {
                updateCoveredEmployee().then((valid) => {
                    if (valid) {
                        syncWizardData(null);
                    }
                });
            },
            sortString: (a, b) => sortColumn(a, b, 'sortString'),
            sortDate: (a, b) => sortColumn(a, b, 'sortDate'),
            sortNumber: (a, b) => sortColumn(a, b, 'sortNumber'),
            renderAddressCell,
        },
        resolveComponentMap: {
            coveredemployeesdetails: CoveredEmployeesDetails,
            CoveredEmployeeStandardCoverages: CoveredEmployeeStandardCoverages,
            CoveredEmployeeSplittableCoverages: CoveredEmployeeSplittableCoverages
        },
    };

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent,
            coveredEmployeesVM,
            fieldId,
            fieldPath,
            overrideProps
        );
    };
    //---------
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={coveredEmployeesVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            // onModelChange={updateModelValue}
            resolveValue={readValue}
            onValueChange={onValueChange}
            showErrors={showErrors}
        />
    );
}

CoveredEmployees.propTypes = {

};
CoveredEmployees.defaultProps = {

};

export default CoveredEmployees;
