
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { WniDateUtil, WniAccountsUtil } from 'wni-portals-util-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ProductsField } from 'wni-components-platform-react';
import { AddressInputComponent } from 'wni-capability-gateway-react';
import ContactInformation from '../ContactInformation/ContactInformation';
import metadata from './PersonalAccountContact.metadata.json5';

const {
    PERSONAL_ACCOUNT_CODE
} = WniAccountsUtil;

const PersonalAccountContact = (props) => {
    const {
        model: accountContactDtoVM,
        writeValue,
        showErrors,
        onValidate,
        products,
        viewModelService
    } = props;
    //-------------------------------------------
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showRequired: true,
            showOptional: false,
            autoComplete: false
        },
        accountAddressContainer: {
            model: accountContactDtoVM,
            dataPath: 'primaryAddress',
            onAddressChange: writeValue,
            hideFieldType: {
                addressType: true,
                country: true
            },
            showErrors,
            onValidate,
            viewModelService
        },
        dateOfBirthContainer: {
            maxDate: new Date(),
            minDate: WniDateUtil.backEarlyDate(),
            showErrors,
        },
        contactInfoCard:{
            model: accountContactDtoVM,
            writeValue,
            onValidate,
            showErrors,

        },
        productsSection: {
            ...products,
            productVisible: true,
            accountType: PERSONAL_ACCOUNT_CODE,
            baseState: _.get(accountContactDtoVM.value, 'primaryAddress.state')
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            accountContactDtoVM,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            addressinputcomponent: AddressInputComponent,
            productsfield: ProductsField,
            contactinformation: ContactInformation
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={accountContactDtoVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={readValue}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
};

export default PersonalAccountContact;
