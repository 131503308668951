import _ from 'lodash';
import { AllTranslation as messages } from 'wni-platform-translations';
import WniAccountUtil from './WniAccountsUtil';

const { PERSONAL_ACCOUNT_CODE, COMMERCIAL_ACCOUNT_CODE } = WniAccountUtil;
/**
 * ====== Qucik Link =====
 * personalLinesAdvisor: 'Personal Lines Advisor',
 * solidFuelQuestionnaire： 'solid Fuel Questionnaire',
 * earthquakeQuestionnaire: 'earthquake Questionnaire',
 * replacementCostEstimator: 'View/Edit Replacement Cost Estimator',
 * myUnderwriter: 'My Underwriter',
 * uploadAttachments: 'Upload Attachments',
 * copySubmission: 'Copy Submission',
 * viewCoverageForms: 'View Coverage Forms',
 * createHomeQuote: 'Create a Home Quote',
 * createAutoQuote: 'Create an Auto Quote'
 */

const PA_PRODUCT_CODE = 'PersonalAuto';
const HOP_PRODUCT_CODE = 'HOPHomeowners';
const HO_PRODUCT_CODE = 'Homeowners';
const DP_PRODUCT_CODE = 'DwellingProperty';
const WAL_PRODUCT_CODE = 'Watercraft';
const RT_PRODUCT_CODE = 'RoadTrail';
const PU_PRODUCT_CODE = 'PersonalUmbrella';
const CA_PRODUCT_CODE = 'CA7CommAuto';
const WCM_PRODUCT_CODE = 'WCMWorkersComp';
const GL_PRODUCT_CODE = 'GL7GeneralLiability';
const CP_PRODUCT_CODE = 'CP7CommercialProperty'
// PRODUCT MAP LOB START Dummy
const DUMMY_PRODUCT_CODE = 'Dummy';
// PRODUCT MAP LOB END Dummy



const productsMap = [
    {
        code: PA_PRODUCT_CODE,
        name: messages.PA,
        productName: 'Personal Auto',
        productShortName: 'PA',
        icon: 'cust-personalauto',
        tooltip: messages.PATooltip,
        type: 'plan',
        sort: 10,
        accountType: PERSONAL_ACCOUNT_CODE,
        quickLink: {
            personalLinesAdvisor: true,
            solidFuelQuestionnaire: true,
            earthquakeQuestionnaire: true,
            replacementCostEstimator: false,
            myUnderwriter: true,
            uploadAttachments: true,
            copySubmission: true,
            viewCoverageForms: true,
            createHomeQuote: true,
            createAutoQuote: false,
            createDwellingPropertyQuote: true,
        },
    },
    {
        code: HOP_PRODUCT_CODE,
        name: messages.Home,
        productName: 'Home',
        productShortName: 'HO',
        icon: 'cust-homeowner',
        tooltip: messages.HOTooltip,
        type: 'plan',
        sort: 20,
        accountType: PERSONAL_ACCOUNT_CODE,
        quickLink: {
            personalLinesAdvisor: true,
            solidFuelQuestionnaire: true,
            earthquakeQuestionnaire: true,
            replacementCostEstimator: true,
            myUnderwriter: true,
            uploadAttachments: true,
            copySubmission: true,
            viewCoverageForms: true,
            createHomeQuote: false,
            createAutoQuote: true,
            createDwellingPropertyQuote: true,
        },
    },
    {
        code: HO_PRODUCT_CODE,
        name: messages.Home,
        productName: 'Home',
        productShortName: 'HO',
        icon: 'cust-homeowner',
        tooltip: messages.HOTooltip,
        type: 'plan',
        sort: 20,
        accountType: PERSONAL_ACCOUNT_CODE,
        quickLink: {
            personalLinesAdvisor: true,
            replacementCostEstimator: true,
            myUnderwriter: true,
            uploadAttachments: true,
            copySubmission: true,
            viewCoverageForms: true,
            createHomeQuote: false,
            createAutoQuote: true,
        },
    },
    {
        code: DP_PRODUCT_CODE, // code: 'Dwelling',
        name: messages.Dwelling,
        productName: 'Dwelling',
        productShortName: 'DP',
        icon: 'cust-dwelling',
        tooltip: messages.DwellingTooltip,
        type: 'plan',
        sort: 30,
        accountType: PERSONAL_ACCOUNT_CODE,
        quickLink: {
            personalLinesAdvisor: true,
            solidFuelQuestionnaire: true,
            earthquakeQuestionnaire: true,
            replacementCostEstimator: true,
            myUnderwriter: true,
            uploadAttachments: true,
            copySubmission: true,
            viewCoverageForms: true,
            createHomeQuote: true,
            createAutoQuote: true,
        },
    },
    {
        code: PU_PRODUCT_CODE,
        name: messages.PU,
        productName: 'Personal Umbrella',
        productShortName: 'PU',
        icon: 'cust-personalumbrella',
        tooltip: messages.PUTooltip,
        type: 'plan',
        sort: 40,
        accountType: PERSONAL_ACCOUNT_CODE,
        quickLink: {
            personalLinesAdvisor: true,
            replacementCostEstimator: false,
            myUnderwriter: true,
            uploadAttachments: true,
            copySubmission: true,
            viewCoverageForms: true,
            createHomeQuote: false,
            createAutoQuote: false,
        },
    },
    {
        code: WAL_PRODUCT_CODE,
        name: messages.Watercraft,
        productName: 'Watercraft',
        productShortName: 'WAL',
        icon: 'cust-watercraft',
        tooltip: messages.WatercraftTooltip,
        type: 'other',
        sort: 80,
        accountType: PERSONAL_ACCOUNT_CODE,
        productionDate: '08/22/2023', // Date when the production environment can be used
        quickLink: {
            personalLinesAdvisor: true,
            replacementCostEstimator: false,
            myUnderwriter: true,
            uploadAttachments: true,
            copySubmission: true,
            viewCoverageForms: true,
            createHomeQuote: false,
            createAutoQuote: true,
        },
    },
    {
        code: RT_PRODUCT_CODE,
        name: messages.RoadTrail,
        productName: 'Road & Trail',
        productShortName: 'RT',
        icon: 'cust-roadtrail',
        tooltip: messages.RoadTrailTooltip,
        type: 'other',
        sort: 90,
        accountType: PERSONAL_ACCOUNT_CODE,
        productionDate: '08/22/2023', // Date when the production environment can be used
        quickLink: {
            personalLinesAdvisor: true,
            replacementCostEstimator: false,
            myUnderwriter: true,
            uploadAttachments: true,
            copySubmission: true,
            viewCoverageForms: true,
            createHomeQuote: false,
            createAutoQuote: true,
        },
    },
// PRODUCT MAP LOB START Dummy
    {
        code: 'Dummy',
        name: messages.Dummy,
        productName: 'Dummy Proudct',
        productShortName: 'Dummy',
        icon: 'cust-dummy',
        tooltip: messages.DummyTooltip,
        type: 'other',
        sort: 90,
        quickLink: {
            personalLinesAdvisor: true,
            replacementCostEstimator: false,
            myUnderwriter: true,
            uploadAttachments: true,
            copySubmission: true,
            viewCoverageForms: true,
            createHomeQuote: false,
            createAutoQuote: false,
        },
    },
// PRODUCT MAP LOB END Dummy
    {
        code: CA_PRODUCT_CODE,
        accountAvailableCode: 'comm_auto',
        name: messages.CA7CommAuto,
        productName: 'Commercial Auto',
        productShortName: 'CA',
        icon: 'cust-commercialauto',
        tooltip: messages.CA7CommAutoTooltip,
        sort: 100,
        accountType: COMMERCIAL_ACCOUNT_CODE,
        type: 'other',
        quickLink: {
            personalLinesAdvisor: false,
            replacementCostEstimator: false,
            myUnderwriter: true,
            uploadAttachments: true,
            copySubmission: true,
            viewCoverageForms: true,
            createWCMQuote: false,
        },
    },
// PRODUCT MAP LOB START WCMWorkersComp
    {
        code: WCM_PRODUCT_CODE,
        accountAvailableCode: 'workers_comp',
        name: messages.WCMWorkersComp,
        productName: 'Workers Compensation',
        productShortName: 'WCM',
        icon: 'cust-workerscompensation',
        tooltip: messages.WCMWorkersCompTooltip,
        sort: 110,
        accountType: COMMERCIAL_ACCOUNT_CODE,
        type: 'other',
        quickLink: {
            personalLinesAdvisor: false,
            replacementCostEstimator: false,
            myUnderwriter: true,
            uploadAttachments: true,
            copySubmission: true,
            viewCoverageForms: true,
            createCAQuote: false,
        },
    },
// PRODUCT MAP LOB END WCMWorkersComp
// // PRODUCT MAP LOB START GeneralLiability
//     {
//         code: GL_PRODUCT_CODE,
//         name: messages.GeneralLiability,
//         productName: 'General Liability',
//         productShortName: 'GL',
//         icon: "cust-generalliability",
//         tooltip: messages.GeneralLiabilityTooltip,
//         type: 'other',
//         sort: 120,
//         accountType: COMMERCIAL_ACCOUNT_CODE,
//         quickLink: {
//             personalLinesAdvisor: true,
//             replacementCostEstimator: false,
//             myUnderwriter: true,
//             uploadAttachments: true,
//             copySubmission: true,
//             viewCoverageForms: true,
//             createHomeQuote: false,
//             createAutoQuote: false
//         }
//     },
// // PRODUCT MAP LOB END GeneralLiability
// // PRODUCT MAP LOB START CP7CommercialProperty
//     {
//         code: CP_PRODUCT_CODE,
//         accountAvailableCode: 'comm_package',
//         name: messages.CP7CommercialProperty,
//         productName: 'Commercial Property',
//         productShortName: 'CPP',
//         icon: "cust-commercialproperty",
//         tooltip: messages.CP7CommercialPropertyTooltip,
//         type: 'other',
//         sort: 130,
//         accountType: COMMERCIAL_ACCOUNT_CODE,
//         quickLink: {
//             personalLinesAdvisor: true,
//             replacementCostEstimator: false,
//             myUnderwriter: true,
//             uploadAttachments: true,
//             copySubmission: true,
//             viewCoverageForms: true,
//             createAutoQuote: true
//         }
//     },
// // PRODUCT MAP LOB END CP7CommercialProperty
// PRODUCT MAP LOB SECTION ANCHOR
];

const getAllProductsMap = () => {
    return productsMap;
};

/**
 *
 * @param {string} code product code
 * @returns {string} return product icon
 */

const getProductIcon = (code) => {
    if (!code) {
        return '';
    }
    const productItem = _.find(productsMap, (item) => item.code === code);
    return productItem.icon;
};
/**
 *
 * @param {string} code
 * @returns {string|object}
 */

const getProductName = (code) => {
    if (!code) {
        return '';
    }
    const productItem = _.find(productsMap, (item) => item.code === code);
    return _.get(productItem, 'productName');
};

/**
 *
 * @param {array} productSelected
 * @returns {array} return the sort data
 */
const getSortProductSelected = (productSelected = []) => {
    const selectedData = productsMap.filter((item) => productSelected.includes(item.code));
    const sortSlectedData = _.orderBy(selectedData, (obj) => obj.sort);
    return sortSlectedData.map((item) => item.code);
};

const getQuickLink = (productCode) => {
    const quickLinkProduct = productsMap.find(
        (item) => item.code === productCode
    );
    return quickLinkProduct.quickLink;
};

const getProductShortName = (productCode) => {
    const productItem = _.find(productsMap, (item) => item.code === productCode);
    return _.get(productItem, 'productShortName');
};

const isCLProduct = (productCode) => {
    const product = productsMap.find((item) => item.code === productCode);
    return _.get(product, 'accountType') === COMMERCIAL_ACCOUNT_CODE;
};

const isDPOrHOProduct = (productCode) => {
    if (productCode === HO_PRODUCT_CODE
        || productCode === HOP_PRODUCT_CODE
        || productCode === DP_PRODUCT_CODE) {
            return true;
        }
    return false;
}

const isProductAvailableForAccount = (productCode, accountProductAvailableMaps = []) => {
    const productItem = _.find(productsMap, (item) => item.code === productCode);
    if(!productItem) {
        return false;
    }
    const findMatch = accountProductAvailableMaps.find((item) => item.productCode === productItem.accountAvailableCode);
    return findMatch ? findMatch.isAvailable : true
}

export default {
    /** for product code */
    PA_PRODUCT_CODE,
    HOP_PRODUCT_CODE,
    HO_PRODUCT_CODE,
    DP_PRODUCT_CODE,
    WAL_PRODUCT_CODE,
    RT_PRODUCT_CODE,
    PU_PRODUCT_CODE,
    CA_PRODUCT_CODE,
    WCM_PRODUCT_CODE,
    GL_PRODUCT_CODE,
    CP_PRODUCT_CODE,
    // PRODUCT MAP LOB START Dummy
    DUMMY_PRODUCT_CODE,
    // PRODUCT MAP LOB END Dummy

    /** for product func */
    getAllProductsMap,
    getProductIcon,
    getProductName,
    getSortProductSelected,
    getQuickLink,
    getProductShortName,
    isCLProduct,
    isDPOrHOProduct,
    isProductAvailableForAccount
};
