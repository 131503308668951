import React, {
    useEffect, useState, useCallback, useContext,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Redirect, withRouter } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { ViewModelServiceContext, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WniCommonQuoteService } from 'wni-capability-quoteandbind';
import { PortalConstants, WizardConstants } from 'wni-portals-config-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { useWniModal } from 'wni-components-platform-react';
import {
    ServiceErrorUtil, WizardUtil, WniUrlUtil, ActiveQuoteUtil,
    WizardPageJumpUtil,
} from 'wni-portals-util-js';
import {
    WizardSidebarLink,
    ActiveQuotesDropdownComponent,
    ActiveQuotesListComponent,
    WizardActiveQuotesDropdownComponent,
    WizardActiveQuotesListComponent,
} from 'wni-capability-gateway-react';
import { MultiModeWizard, MultiModeWizardWithErrorContext } from 'wni-portals-wizard-react';
import { WniSubmissionService } from 'wni-capability-gateway';
import { HORCTService } from 'wni-capability-quoteandbind-ho';

import { WniPlatformMessages as customMessages } from 'wni-platform-translations';
import messages from './HOSubmissionWizard.messages';

import HOWizardSidebarLink from './components/HOWizardSidebarLink';

import wizardConfig from './config/ho-wizard-config.json5';
import wizardReadOnlyConfig from './config/ho-wizard-readonly-config.json5';
import wizardStepToFieldMapping from './config/ho-wizard-step-to-field-mapping.json5';

const RESIDENCE_PAGE_INDEX = 1;
// const Offering_PREFIX_LENGTH = 3; // For example, offering type is 'HOPPlus_Ext', the length of 'HOP'.
// const Offering_SUFFIX_LENGTH = 7; // For example, offering type is 'HOPPlus_Ext', the length of 'HOPPlus'.

const { steps, title } = wizardConfig;
const { steps: readOnlySteps } = wizardReadOnlyConfig;

/**
 * Missing Features:
 * 1) readonly mode;
 *
 * Reafctoring Notice:
 * 1) Extract the common featureh between PASubmissionWizard and HOSubmissionWizard into CommonSbmisisonWizard
 * 2) Replace QuoteDataDTO with SubmisisonWizardDataDTO
 * @param {object} props
 * @returns {function}
 */
function HOSubmissionWizard(props) {
    const modalApi = useWniModal();
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();

    const [initialSubmission, setInitialSubmission] = useState(null);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    // const [isLoading, setIsLoading] = useState(true);
    // Is the Submission obtained by copying, updateWizardSnapshot
    // Whether the Submission has been copied, Shows that it has been successfully copied
    const { history, location } = props;
    // const [readOnlySteps, updateReadOnlySteps] = useState(initialReadOnlySteps);

    const [initialWizardPageData, setInitialWizardPageData] = useState(undefined);
    // const [otherActiveJobs, setOtherActiveJobs] = useState([]);

    const {
        state: {
            isReadOnly = false,
            quoteentry: {
                quoteID,
                postalCode,
                // periodPublicID,
            } = {},
            RCTCallback,
        } = {}
    } = location;

    const isRCTCallback = RCTCallback === 'residence';
    const isRCTCallbackToResidencePage = RCTCallback === 'residencePage';
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const updateActiveJobsData = useCallback(async (jobID, sessionUUID) => {
        let wizardExitData;
        if (isReadOnly) {
            wizardExitData = await WniCommonQuoteService.loadWizardExitData(jobID, sessionUUID, authHeader);
        } else {
            wizardExitData = await WniCommonQuoteService.loadWizardExitDataWithActiveQuotes(jobID, sessionUUID, authHeader);
        }
        return wizardExitData;
        
        // const otherActiveJobsNewVal = WizardUtil.getOtherActiveJobs(jobID, wizardExitData.accountJobs);
        // setOtherActiveJobs(otherActiveJobsNewVal);

        // return {
        //     wizardExitData,
        //     // otherActiveJobs: otherActiveJobsNewVal,
        // };
    }, [isReadOnly]);

    const retrieveSubmission = useCallback(async (
        quoteRetrievalParam
    ) => {
        const viewModelContext = {
            AccountEmailRequired: false,
            AccountDOBRequired: false
        };
    
        const requestData = quoteRetrievalParam
        setLoadingMask(true);
        let response;

        // ==========POI-24758: disable branch switching according to clarification from QA===========================
        // let newWizardSessionID;
        // if (!_.isEmpty(periodPublicID) || isRCTCallback) {
        //     newWizardSessionID = await WniCommonQuoteService.generateNewWizardSessionID(requestData, authHeader);
        // }
        // if (!_.isEmpty(periodPublicID)) {
        //     await WniCommonQuoteService.updateCurrentBranch(requestData.quoteID, newWizardSessionID, periodPublicID, authHeader);
        // }
        // ===========================================================================================================
        if (isRCTCallback) {
            const newWizardSessionID = await WniCommonQuoteService.generateNewWizardSessionID(requestData, authHeader);
            response = await HORCTService.syncRCTInfo(requestData.quoteID, newWizardSessionID, authHeader);
            // set RCTCallback to 'residencePage' to avoid hoRCT service call again after page refresh(F5) and keep the page skip
            _.set(location, 'state.RCTCallback', 'residencePage');
        } else {
            response = await WniCommonQuoteService.retrieveSubmissionWizardData(requestData, authHeader);
        }
        response.persons = [response.baseData.accountHolder];
    
        const { jobID, sessionUUID } = response;
        const wizardExitData = await updateActiveJobsData(jobID, sessionUUID);

        const submission = viewModelService.create(
            response,
            'pc',
            'wni.edge.capabilities.quote.dto.SubmissionWizardDataDTO',
            viewModelContext
        );
        
        const initPageData = WizardUtil.getInitialWizardPageData(wizardExitData);
        if (isRCTCallback) {
            // initPageData[WizardConstants.RCTCallback] = RCTCallback;
            initPageData[WizardConstants.lastExitWizardPage] = RESIDENCE_PAGE_INDEX;
        } else if (isRCTCallbackToResidencePage) {
            const lastExitWizardPage = initPageData[WizardConstants.lastExitWizardPage];
            initPageData[WizardConstants.lastExitWizardPage] = RESIDENCE_PAGE_INDEX > lastExitWizardPage ? RESIDENCE_PAGE_INDEX : lastExitWizardPage;
        }
        
        setInitialSubmission(submission);
        setInitialWizardPageData(initPageData);
        // setIsLoading(false);
        
        setLoadingMask(false);
    }, [authHeader, location, viewModelService]);

    useEffect(() => {
        if (!viewModelService) {
            return;
        }
        if (!location.state) {
            history.push('/');
            return;
        }

        retrieveSubmission({
            quoteID,
            postalCode,
        });
    },
    // Disabled so we don't rerun this function on every rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // []);
    [viewModelService]);

    const onLeaveWizard = useCallback(async ({
        wizardData: submissionVM,
        wizardPageData,
        currentStepIndex,
    }) => {
        const {
            jobID: quoteIDParam, sessionUUID,
        } = submissionVM.value;
        // setIsLoading(true);

        const wizardExitData = WizardUtil.getQuoteWizardExitData(wizardPageData, { currentStepIndex });
        const updateResult = await WniCommonQuoteService.saveWizardExitData(quoteIDParam, sessionUUID, wizardExitData, authHeader);

        // setIsLoading(false);

        return true;
    }, [authHeader]);

    const handleCancel = useCallback(({
        wizardData: submissionVM,
    }) => {
        const {
            jobID: quoteIDParam,
        } = submissionVM.value;

        WizardPageJumpUtil.goToQuoteDetailsSummaryPage(history, { jobID: quoteIDParam});
    }, [history]);

    const handleReadOnlyCancel = useCallback(({ wizardData: submissionVM }) => {
        const quoteIDParam = _.get(submissionVM.value, 'jobID');
        WizardPageJumpUtil.goToQuoteDetailsSummaryPage(history, { jobID: quoteIDParam});
    }, [history]);

    const handleError = useCallback((error) => {
        // const isQuotePage = _.includes(_.toLower(_.get(error, 'gwInfo.method')), 'quote');
        const quoteIDFromWizard = _.get(error, 'gwInfo.params[0].quoteID');
        const quoteIDFromLocation = _.get(location, 'state.quoteentry.quoteID');
        const quoteIDVal = quoteIDFromWizard || quoteIDFromLocation;
        const redirectPath = `/quotes/${quoteIDVal}/summary`;
        const state = {
            pathname: redirectPath,
            state: error
        };

        const errorMsg = ServiceErrorUtil.getErrorMessage(error, messages.anErrorOccurred);
        return modalApi.showAlert({
            title: messages.anErrorOccurredTitle,
            message: errorMsg,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: customMessages.OK
        }).then(() => {
            setHasErrorOccurred(true);
            return <Redirect to={state} />;
        });
    }, [location]);

    const handleKnockout = useCallback(({ knockOutErrors }) => {
        history.push('/knockoutpage', { underwritingIssues: knockOutErrors });
    }, [history]);

    const handleCopySubmission = async ({
        updateWizardPageData
    }) => {
        // const copySubmissionID = _.get(initialSubmission.value, 'jobID');
        const {
            jobID, sessionUUID,
        } = initialSubmission.value;
        // setLoadingMask(true);
        const copiedSubmission = await WniSubmissionService.copySubmission(jobID, authHeader);
        
        // const {
        //     otherActiveJobs: otherActiveJobsNewVal,
        // } = await updateActiveJobsData(jobID, sessionUUID);
        const activeJobsNewVal = await WniCommonQuoteService.getActiveQuotes(jobID, sessionUUID, authHeader);
        // setLoadingMask(false);
        
        //
        // setOtherActiveJobs(otherActiveJobsNewVal);
        updateWizardPageData({
            [WizardConstants.accountActiveQuotes]: activeJobsNewVal,
            [WizardConstants.copySubmission]: copiedSubmission,
        });
    };

    const wizardPageHeaderFormatter = ({
        wizardData: wizardSubmission,
        wizardPageData: wizardPageDataParam,
    }) => {
        const {
            baseData: {
                selectedVersion_Ext: selectedVersionPublicID,
            },
            lobData: {
                homeowners: {
                    offerings,
                    householdInfoDTO_Ext: {
                        coveragesForm
                    }
                }
            },
            jobID
        } = wizardSubmission.value;

        const {
            [WizardConstants.accountActiveQuotes]: accountActiveQuotes,
        } = wizardPageDataParam;

        const accountJobPeriodDTO = ActiveQuoteUtil.findAccountJobPeriodDTO(accountActiveQuotes, jobID, selectedVersionPublicID);

        if (!accountJobPeriodDTO) {
            return {};
        }

        const {
            branchName: selectedBranchName,
            branchCode: selectedBranchCode,
            policyType_Ext: selectedPolicyType,
        } = accountJobPeriodDTO;
        
        // when initialize a submission, should have a default policy type as 'Signature'
        let jobStatusInfo = 'Signature';
        let versionName = '';
        
        const policyTypeDisplayName = selectedPolicyType;
        // let offeringType = selectedBranchCode; 
        // if offering type updated from back-end, map it with title, for example: leaving residence page
        const offeringType = offerings[0].branchCode;
       
        
        const hasMultiplePeriods = ActiveQuoteUtil.hasMultiplePeriodsForJob(accountActiveQuotes, jobID);
        if (hasMultiplePeriods && selectedBranchName !== 'CUSTOM') {
            versionName = selectedBranchName;
        }
        // wizard title format: <Offering> + <Coverage Form> + <Version name>. <Version name> 
        if (!_.isNil(coveragesForm)) {
            jobStatusInfo = (`${policyTypeDisplayName} ${coveragesForm.toUpperCase()} ${versionName}`);
        }  
        return {
            // productWithQuoteNumber: ''Derp';
            jobStatusInfo,
            policyTypeDisplayName,
        };
    };

    const getPageHeaderExtensionComponent = useCallback(() => {
        // return () => {
        //     return !_.isEmpty(otherActiveJobs) && <ActiveQuotesDropdownComponent accountJobs={otherActiveJobs} postalCode={postalCode} />
        // };
        return () => {
            return <WizardActiveQuotesDropdownComponent />;
        }
    }, []);

    const getSidebarExtensionComponent = useCallback(() => {
        
        return () => {

            return (
                <>
                    {/* <ActiveQuotesListComponent accountJobs={otherActiveJobs} postalCode={postalCode} /> */}
                    <WizardActiveQuotesListComponent />
                    {/* <WizardSidebarLink /> */}
                    <HOWizardSidebarLink />
                </>
            );
            
        };
    }, [postalCode]);

    const onWizardModeChange = _.noop;

    // ===============================================================================
    const defaultOnInitialization = useCallback(({
        wizardPageData,
        updateWizardReadOnly,
        updateFurthestPageVisited
    }) => {
        const isUwLocked = wizardPageData[WizardConstants.isUwLocked];
        if (isUwLocked) {
            updateWizardReadOnly(true);
        }
        // updateFurthestPageVisited('HOAdditionalInterestsPage');
    }, []);

    const readonlyOnInitialization = useCallback(({ updateFurthestPageVisited }) => {
        updateFurthestPageVisited('HOQuotePage');
    }, []);

    // ===============================================================================
    // if (isLoading) {
    //     return <Loader loaded={isLoading} />;
    // }

    // Both initialSubmission and initialWizardPageData is required: one is for wizard,
    // the other is for OthreActiveQuotes;
    // Consider to use another dedicated flag to track this initialization status.
    if (!initialSubmission || !initialWizardPageData) {
        return null;
    }

    return (
        <ErrorBoundary onError={handleError}>
            <MultiModeWizardWithErrorContext
            // <MultiModeWizard
                initialData={initialSubmission}
                modeToWizardPropsMap={
                    {
                        default: {
                            initialSteps: steps,
                            wizardTitle: title,
                            onCancel: handleCancel,
                            // onKnockOut: handleKnockout,
                            skipCompletedSteps: true,
                            wizardStepToFieldMapping,
                            wizardPageHeaderExtension: getPageHeaderExtensionComponent(),
                            wizardSidebarExtension: getSidebarExtensionComponent(),
                            wizardServiceCallbacks: {
                                retrieveSubmission: WniCommonQuoteService.retrieveSubmissionWizardData, // WniLoadSaveService.retrieveSubmission,
                                copySubmission: handleCopySubmission,
                            },
                            // onPreviousModalProps: {
                            //     title: commonMessages.wantToJump,
                            //     message: commonMessages.wantToJumpMessage,
                            //     status: 'warning',
                            //     icon: 'gw-error-outline',
                            //     confirmButtonText: commonMessages.yesModel,
                            //     cancelButtonText: commonMessages.cancelModel
                            // }
                            wizardDataComparator: WizardUtil.isWizardDataEqualForPA,
                            showWizardPromptMessage: true,
                            onLeaveWizard,
                            wizardPageHeaderFormatter,
                            initialWizardPageData,
                            onWizardInitialization: defaultOnInitialization,
                        },
                        readOnly: {
                            initialSteps: readOnlySteps,
                            wizardTitle: title,
                            onCancel: handleReadOnlyCancel,
                            wizardStepToFieldMapping,
                            onWizardInitialization: readonlyOnInitialization,
                            wizardPageHeaderFormatter,
                        },
                    }
                }
                initialMode={isReadOnly ? 'readOnly' : 'default'}
                onWizardModeChange={onWizardModeChange}
            />
        </ErrorBoundary>
    );
}

HOSubmissionWizard.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            quoteentry: PropTypes.shape({
                postalCode: PropTypes.string,
                quoteID: PropTypes.string
            })
        })
    }).isRequired
};

export default withRouter(HOSubmissionWizard);
