import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('gatewayCancellation'), method, data, additionalHeaders);
}

function processWniCancellation(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('wniCancellation'), method, data, additionalHeaders);
}

export default class WniCancellationService {
    static scheduleCancellation(cancellationNumber, processDate, additionalHeaders = {}) {
        return processSubmission('scheduleCancellation', [cancellationNumber, processDate], additionalHeaders);
    }

    static rescindCancellation(cancellationNumber, additionalHeaders = {}) {
        return processSubmission('rescindCancellation', [cancellationNumber], additionalHeaders);
    }

    static withdrawCancellationByCancellationNumber(cancellationNumber, additionalHeaders = {}) {
        return processWniCancellation('withdrawCancellationByJobNumber', [cancellationNumber], additionalHeaders);
    }

    static addRecentlyViewedCancellation(cancellationNumber, additionalHeaders = {}) {
        return processWniCancellation('addRecentlyViewedCancellation', [cancellationNumber], additionalHeaders);
    }

    static createCancellationActivity(cancellationNumber, additionalHeaders = {}) {
        return processWniCancellation('createActivity0028', [cancellationNumber], additionalHeaders);
    }
}
