import {
    QuoteUtil,
} from 'wni-portals-util-js';


const {
    PAYMENT_PLAN_TYPES,
} = QuoteUtil;

// ====================================
const LOB_NAME = 'workersComp';
const SXS_LOB_NAME = LOB_NAME;
const LOB_PACKAGE_NAME = LOB_NAME.toLowerCase();

const BRANCH_NAME_PATH = `lobData.${LOB_NAME}.offerings[0].branchName`;
const LOB_OFFERING_PATH = `lobData.${LOB_NAME}.offerings`;

const SXS_PERIODS_PATH = `${LOB_NAME}.periods`;
const SXS_COVERAGES_PATH = `${LOB_NAME}.coverages`;
const SXS_VEHICLE_SETS_PATH = `${LOB_NAME}.vehicleSets`;

const LOB_COVERABLE_VEHICLES_PATH = `lobData.${LOB_NAME}.coverables.wcVehicles`;
const LINE_COVERAGES_PATH = 'lineCoverages';
const LINE_ADDITIONAL_COVERAGES_PATH = 'lineAdditionalCoverages';
const LINE_EXCLUSIONS_PATH = 'lineExclusions';
const LINE_CONDITIONS_PATH = 'lineConditions';

const LINE_DETAILS_PATH = 'lineDetails';
const STATE_SPECIFIC_COVERAGES_PATH = 'stateSpecificCoverages';

const VEHICLE_COVERAGES_PATH = 'wcVehicleCoverages';
const VEHICLE_COVERAGE_OWNING_COVERABLE_ID = 'owningCoverablePublicID';

export default {
    PAYMENT_PLAN_TYPES,
    //
    LOB_NAME,
    LOB_PACKAGE_NAME,
    BRANCH_NAME_PATH,
    LOB_OFFERING_PATH,
    LOB_COVERABLE_VEHICLES_PATH,
    
    //
    SXS_LOB_NAME,
    SXS_PERIODS_PATH,
    SXS_COVERAGES_PATH,

    //
    LINE_COVERAGES_PATH,
    LINE_ADDITIONAL_COVERAGES_PATH,
    LINE_EXCLUSIONS_PATH,
    LINE_CONDITIONS_PATH,
    
    LINE_DETAILS_PATH,

    STATE_SPECIFIC_COVERAGES_PATH,
    //
    SXS_VEHICLE_SETS_PATH,
    VEHICLE_COVERAGES_PATH,
    //
    VEHICLE_COVERAGE_OWNING_COVERABLE_ID,
};