import { defineMessages } from 'react-intl';

export default defineMessages({
    bankName: {
        id: 'gateway.views.vehicle.additionalInterest.bank-search.Bank Name',
        defaultMessage: 'Bank Name'
    },
    country: {
        id: 'gateway.views.vehicle.additionalInterest.bank-search.Country',
        defaultMessage: 'Country'
    },
    pobox: {
        id: 'gateway.views.vehicle.additionalInterest.bank-search.PO Box',
        defaultMessage: 'PO Box'
    },
    city: {
        id: 'gateway.views.vehicle.additionalInterest.bank-search.City',
        defaultMessage: 'City'
    },
    county: {
        id: 'gateway.views.vehicle.additionalInterest.bank-search.County',
        defaultMessage: 'County'
    },
    state: {
        id: 'gateway.views.vehicle.additionalInterest.bank-search.State',
        defaultMessage: 'State'
    },
    zipCode: {
        id: 'gateway.views.vehicle.additionalInterest.bank-search.Zip Code',
        defaultMessage: 'Zip Code'
    },
    bankNameRequired: {
        id: 'gateway.views.vehicle.additionalInterest.bank-search.Please Specify the Bank Name.',
        defaultMessage: 'Please Specify the Bank Name.'
    },
    searchBank: {
        id: 'gateway.views.vehicle.additionalInterest.bank-search.Search Bank',
        defaultMessage: 'Search Bank'
    },
    tooManyResult: {
        id: 'gateway.views.vehicle.additionalInterest.bank-search.Too Many Results',
        defaultMessage: 'The Additional Interest search returned too many results, please refine your search criteria.'
    }
});
