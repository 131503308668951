import { defineMessages } from 'react-intl';

export default defineMessages({
    bankName: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.bank.bank-search.Financial Institution',
        defaultMessage: 'Financial Institution'
    },
    country: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.bank.bank-search.Country',
        defaultMessage: 'Country'
    },
    pobox: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.bank.bank-search.PO Box',
        defaultMessage: 'PO Box'
    },
    city: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.bank.bank-search.City',
        defaultMessage: 'City'
    },
    county: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.bank.bank-search.County',
        defaultMessage: 'County'
    },
    state: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.bank.bank-search.State',
        defaultMessage: 'State'
    },
    zipCode: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.bank.bank-search.Zip Code',
        defaultMessage: 'Zip Code'
    },
    bankNameRequired: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.bank.bank-search.Please Specify the Financial Institution.',
        defaultMessage: 'Please Specify the Financial Institution.'
    },
    searchBank: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.bank.bank-search.Search Financial Institution',
        defaultMessage: 'Search Financial Institution'
    },
    tooManyResult: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.bank.bank-search.Too Many Results',
        defaultMessage: 'The Additional Interest search returned too many results, please refine your search criteria.'
    },
    addNew: {
        id: 'wni.common.wizard.additionalInterest.base-additional-interest.bank.bank-search.Add New',
        defaultMessage: 'Add New'
    }
});
