import { defineMessages } from 'react-intl';

export default defineMessages({
    cancellationHasBeenDraft: {
        id: 'gateway.views.cancellation-detail.You can either Quote or Delete Cancellation',
        defaultMessage: 'You can either Quote or Delete Cancellation.'
    },
    quoteCancellation: {
        id: 'gateway.views.cancellation-detail.Quote',
        defaultMessage: 'Quote'
    },
    deleteCancellation: {
        id: 'gateway.views.cancellation-detail.Delete Cancellation',
        defaultMessage: 'Delete Cancellation'
    },
    wantDeleteCancellation: {
        id: 'gateway.views.cancellation-detail.Are you sure you want to delete the cancellation?',
        defaultMessage: 'Are you sure you want to delete the cancellation?'
    },
    failedDeleteCancellation: {
        id: 'gateway.directives.JobSummary.Failed to delete cancellation',
        defaultMessage: 'Failed to delete cancellation'
    },
    failedQuoteCancellation: {
        id: 'gateway.directives.JobSummary.Failed to quote cancellation',
        defaultMessage: 'Failed to quote cancellation'
    },
    doNotDelete: {
        id: 'gateway.views.cancellation-detail.Do Not Delete',
        defaultMessage: 'Do Not Delete'
    },
    submitCancellation: {
        id: 'gateway.views.cancellation-detail.Submit Cancellation',
        defaultMessage: 'Submit Cancellation'
    },
    editCancellation: {
        id: 'gateway.views.cancellation-detail.Edit',
        defaultMessage: 'Edit'
    },
    primaryNamedInsured: {
        id: 'gateway.views.cancellation-detail.Named Insured',
        defaultMessage: 'Named Insured'
    },
    submitOrDeleteCancellation: {
        id: 'gateway.views.cancellation-detail.You can either Submit or Delete the cancellation.',
        defaultMessage: 'You can either Submit or Delete the cancellation.'
    },
    confirmCancellation: {
        id: 'gateway.views.cancellation-detail.Confirm Cancellation',
        defaultMessage: 'Confirm Cancellation'
    },
    confirmRescind: {
        id: 'gateway.views.cancellation-detail.Confirm Rescind',
        defaultMessage: 'Confirm Rescind'
    },
    rescindConfirmMessage: {
        id: 'gateway.views.cancellation-detail.AAre you sure you want to rescind Cancellation',
        defaultMessage: 'Are you sure you want to rescind Cancellation?'
    },
    doNotRescind: {
        id: 'gateway.views.cancellation-detail.Do Not Rescind',
        defaultMessage: 'Do Not Rescind'
    },
    doNotIssue: {
        id: 'gateway.views.cancellation-detail.Do Not Issue',
        defaultMessage: 'Do Not Issue'
    },
    validateEffectiveDateMsg: {
        id: 'gateway.views.cancellation-detail.No period for this effective date',
        defaultMessage: 'No period for this effective date'
    },
    scheduleConfirmMessage: {
        id: 'gateway.views.cancellation-detail.Are you sure you want to schedule this Cancellation for issuance on',
        defaultMessage: 'Are you sure you want to schedule this Cancellation for issuance on'
    },
    cancelConfirmMessagePL: {
        id: 'gateway.views.cancellation-detail.cancelConfirmMessagePL',
        defaultMessage: 'Please confirm you wish to proceed with this cancellation transaction.  Once processed, you will not have the ability to change this request without underwriter approval.  Are you sure you want to issue this cancellation?'
    },
    cancelConfirmMessageCL: {
        id: 'gateway.views.cancellation-detail.cancelConfirmMessageCL',
        defaultMessage: 'Please confirm you wish to continue with this cancellation. Once requested, you will not have the ability to complete or change this request without underwriter approval. Are you sure you want to request this cancellation?'
    },
    yes: {
        id: 'gateway.views.cancellation-detail.Yes',
        defaultMessage: 'Yes'
    },
    cancelNow: {
        id: 'gateway.views.cancellation-detail.Cancel Now',
        defaultMessage: 'Cancel Now'
    },
    scheduleCancel: {
        id: 'gateway.views.cancellation-detail.Schedule Cancellation',
        defaultMessage: 'Schedule Cancellation'
    },
    cancellationHasBeenWithCanceling: {
        id: 'gateway.views.cancellation-detail.This policy has been scheduled to be Cancelled, effective',
        defaultMessage: 'This policy has been scheduled to be Cancelled, effective'
    },
    rescindCancellation: {
        id: 'gateway.views.cancellation-detail.Rescind Cancellation',
        defaultMessage: 'Rescind Cancellation'
    },
    withLossWarningMessage: {
        id: 'gateway.views.cancellation-detail.Cannot cancel this policy due to recent claim activity. Please change the effective date or contact your Underwriter for approval',
        defaultMessage: 'Cannot cancel this policy due to recent claim activity. Please change the effective date or contact your Underwriter for approval.'
    },
    referToUnderwriter: {
        id: 'gateway.views.cancellation-detail.Refer to Underwriter',
        defaultMessage: 'Refer to Underwriter'
    },
    back: {
        id: 'gateway.views.cancellation-detail.Back',
        defaultMessage: 'Back'
    },
    withLossWarning: {
        id: 'gateway.views.cancellation-detail.This policy is currently blocked from cancellation due to recent claim activity. It will need to be submitted to your Underwriter for review prior to proceeding.',
        defaultMessage: 'This policy is currently blocked from cancellation due to recent claim activity. It will need to be submitted to your Underwriter for review prior to proceeding.'
    },
    withLossSubject: {
        id: 'gateway.views.cancellation-detail.Backdated Cancellation with Loss by Insured',
        defaultMessage: 'Backdated Cancellation with Loss by Insured.'
    },
    withLossActivityDesc: {
        id: 'gateway.views.cancellation-detail.withLossActivitySubject',
        defaultMessage: 'Please review Cancellation Request {jobNumber} since this policy has claims submitted after cancellation date, also provide selection on \'Cancellation with Loss?\' Field',
    },
    canSubmitOrDelete: {
        id: 'gateway.views.cancellation-detail.summary.You can either Submit or Delete the cancellation.',
        defaultMessage: 'You can either Submit or Delete the cancellation.'
    },
    canNotBindOrWithDrawButCanAddInfo: {
        id: 'gateway.views.cancellation-detail.summary.You can either Edit, Submit or Delete the cancellation.',
        defaultMessage: 'You can either Edit, Submit or Delete the cancellation.'
    },
    canBindCancelCannotWithdraw: {
        id: 'gateway.views.cancellation-detail.summary.You can bind the cancellation, but you cannot delete it.',
        defaultMessage: 'You can bind the cancellation, but you cannot delete it.'
    },
    withdrawCancellation: {
        id: 'gateway.views.cancellation-detail.summary.Delete Cancellation',
        defaultMessage: 'Delete Cancellation'
    },
    wantWithdrawCancellation: {
        id: 'gateway.views.cancellation-detail.summary.Are you sure you want to delete the cancellation?',
        defaultMessage: 'Are you sure you want to delete the cancellation?'
    },
    doNotWithdraw: {
        id: 'gateway.views.cancellation-detail.summary.Do Not delete',
        defaultMessage: 'Do Not delete'
    },
    cancellationHasBeenWithDrawn: {
        id: 'gateway.views.cancellation-detail.summary.This cancellation has been deleted.',
        defaultMessage: 'This cancellation has been deleted.'
    },
    needManagerApproval: {
        id: 'gateway.views.cancellation-detail.summary.This cancellation has claims submitted after the cancellation date. Need manager approval to proceed.',
        defaultMessage: 'This cancellation has claims submitted after the cancellation date. Need manager approval to proceed.'
    },
    managerDeclined: {
        id: 'gateway.views.cancellation-detail.summary.The cancellation has been declined by a manager. You can edit and resubmit or delete the transaction',
        defaultMessage: 'The cancellation has been declined by a manager. You can edit and resubmit or delete the transaction'
    },
    managerApprove: {
        id: 'gateway.views.cancellation-detail.summary.UW has approved this cancellation Request.',
        defaultMessage: 'UW has approved this cancellation Request.'
    },
    hasBeenRescinded: {
        id: 'gateway.views.cancellation-detail.summary.The cancellation has been Rescinded.',
        defaultMessage: 'The cancellation has been Rescinded.'
    },
    cancellationAmountToBeCalculatedPL: {
        id: 'wni.gateway.views.cancellation-detail.The cancellation refund amount has been calculated.',
        defaultMessage: 'The cancellation refund amount has been calculated.'
    },
    cancellationAmountToBeCalculatedCL: {
        id: 'wni.gateway.views.cancellation-detail.The cancellation credit amount has been calculated.',
        defaultMessage: 'The cancellation credit amount has been calculated.'
    },
    PLRefundLabel: {
        id: 'wni.gateway.directives.cancel-policy-summary.Refund Method',
        defaultMessage: 'Refund Method'
    },
    CLRefundLabel: {
        id: 'wni.gateway.directives.cancel-policy-summary.Method of Cancellation',
        defaultMessage: 'Method of Cancellation'
    },
    cancellationReferredUWA: {
        id: 'wni.gateway.directives.cancel-policy-summary.cancellationReferredUWA',
        defaultMessage: 'This cancellation is being reviewed by the Underwriter.'
    }
});
