import React, {
    useEffect,
    useState,
    useCallback,
    useRef,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Redirect, withRouter } from 'react-router-dom';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { ClauseService } from 'gw-capability-policycommon';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WniLoadSaveService, WniCommonQuoteService } from 'wni-capability-quoteandbind';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { useWniModal } from 'wni-components-platform-react';
// import { MockUpUtil } from '@xengage/gw-portals-url-js';
import { PortalConstants, WizardConstants } from 'wni-portals-config-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { WniPlatformMessages as customMessages } from 'wni-platform-translations';
import messages from 'gw-capability-gateway-quoteandbind-pa-react/PEPAWizard.messages';
import {
    ServiceErrorUtil, WizardUtil, QuoteUtil, WniUrlUtil,
    WizardPageJumpUtil,
} from 'wni-portals-util-js';
import {
    WizardSidebarLink,
    ActiveQuotesDropdownComponent,
    ActiveQuotesListComponent,
    WizardActiveQuotesDropdownComponent,
    WizardActiveQuotesListComponent,
} from 'wni-capability-gateway-react';
import { MultiModeWizard } from 'wni-portals-wizard-react';
import { WniSubmissionService } from 'wni-capability-gateway';

import wizardConfig from './config/pa-wizard-config.json5';
import wizardReadOnlyConfig from './config/pa-wizard-readonly-config.json5';
import wizardStepToFieldMapping from './config/pa-wizard-step-to-field-mapping.json5';

function PASubmissionWizard(props) {
    const modalApi = useWniModal();
    const { steps, title } = wizardConfig;
    const { steps: initialReadOnlySteps } = wizardReadOnlyConfig;
    const { authHeader } = useAuthentication();

    const [initialSubmission, setInitialSubmission] = useState(null);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    // const [isLoading, setIsLoading] = useState(true);
    // Is the Submission obtained by copying, updateWizardSnapshot
    // Whether the Submission has been copied, Shows that it has been successfully copied
    const { viewModelService, history, location } = props;
    const [readOnlySteps, updateReadOnlySteps] = useState(initialReadOnlySteps);

    const [initialWizardPageData, setInitialWizardPageData] = useState(undefined);

    // const isReadOnly = _.get(location.state, 'isReadOnly', false);

    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const {
        state: {
            isReadOnly = false,
            quoteentry: {
                quoteID,
                postalCode,
                // periodPublicID,
            } = {},
        } = {}
    } = location;

    const updateActiveJobsData = useCallback(async (jobID, sessionUUID) => {
        let wizardExitData;
        if (isReadOnly) {
            wizardExitData = await WniCommonQuoteService.loadWizardExitData(jobID, sessionUUID, authHeader);
        } else {
            wizardExitData = await WniCommonQuoteService.loadWizardExitDataWithActiveQuotes(jobID, sessionUUID, authHeader);
        }
        return wizardExitData;
        
        // const otherActiveJobsNewVal = WizardUtil.getOtherActiveJobs(jobID, wizardExitData.accountJobs);
        // setOtherActiveJobs(otherActiveJobsNewVal);

        // return {
        //     wizardExitData,
        //     // otherActiveJobs: otherActiveJobsNewVal,
        // };
    }, [isReadOnly]);

    const retrieveSubmission = useCallback(async (newSubmission) => {
        const viewModelContext = {
            AccountEmailRequired: false,
            DriverEmailRequired: true,
            AccountDOBRequired: false
        };
        // const { quoteentry } = location.state;
        // const { postalCode, quoteID } = quoteentry;
        let requestData = {
            quoteID: quoteID,
            postalCode: postalCode,
        };
        if (newSubmission) {
            requestData = {
                quoteID: _.get(newSubmission, 'jobNumber'),
                postalCode: _.get(
                    newSubmission,
                    'policy.account.accountHolder.primaryAddress.postalCode'
                ),
            };
        }
        setLoadingMask(true);

        // ==========POI-24758: disable branch switching according to clarification from QA===========================
        // if (!_.isEmpty(periodPublicID)) {
        //     const newWizardSessionID = await WniCommonQuoteService.generateNewWizardSessionID(requestData, authHeader);
        //     await WniCommonQuoteService.updateCurrentBranch(requestData.quoteID, newWizardSessionID, periodPublicID, authHeader);
        // }
        // ===========================================================================================================

        const response = await WniLoadSaveService.retrieveSubmission(requestData, authHeader);
        
        response.persons = [response.baseData.accountHolder];
        // ======= To be Removed BEGIN ============================
        // await ClausesUtil.getDependentClausesFromServer(
        //     ClauseService,
        //     _.get(response, 'quoteID'),
        //     authHeader
        // );
        // ======= To be Removed END ===============================
        // const { sessionUUID } = response;
        // const wizardExitData = await WniCommonQuoteService.loadWizardExitData(quoteID, sessionUUID, authHeader);
        const { quoteID: jobID, sessionUUID } = response;
        const wizardExitData = await updateActiveJobsData(jobID, sessionUUID);

        const submission = viewModelService.create(
            response,
            'pc',
            'edge.capabilities.quote.submission.dto.QuoteDataDTO',
            viewModelContext
        );
        const initPageData = WizardUtil.getInitialWizardPageData(wizardExitData);
        // WizardUtil.sortSubmissionData(submission);
        setInitialSubmission(submission);
        setInitialWizardPageData(initPageData);
        setLoadingMask(false);
    }, [authHeader, location, viewModelService]);

    useEffect(() => {
        if (!location.state) {
            history.push('/');
            return;
        }

        retrieveSubmission();
    },
    // Disabled so we don't rerun this function on every rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);



    const onLeaveWizard = useCallback(async ({
        wizardData: submissionVM,
        wizardPageData,
        currentStepIndex,
    }) => {
        const {
            quoteID: quoteIDParam, sessionUUID,
        } = submissionVM.value;
        // setIsLoading(true);

        const wizardExitData = WizardUtil.getQuoteWizardExitData(wizardPageData, { currentStepIndex });
        await WniCommonQuoteService.saveWizardExitData(quoteIDParam, sessionUUID, wizardExitData, authHeader);
        // setIsLoading(false); // this causes refresh of wizard, which crashes AsyncPrompt.
        return true;
    }, [authHeader]);

    const handleCancel = useCallback(({
        wizardData: submissionVM,
    }) => {
        const {
            quoteID: quoteIDParam,
        } = submissionVM.value;

        WizardPageJumpUtil.goToQuoteDetailsSummaryPage(history, { jobID: quoteIDParam});
    }, [history]);

    const handleReadOnlyCancel = useCallback(({ wizardData: submissionVM}) => {
        const quoteIDParam = _.get(submissionVM.value, 'quoteID');
        WizardPageJumpUtil.goToQuoteDetailsSummaryPage(history, { jobID: quoteIDParam});
    }, [history]);

    const handleError = useCallback((error) => {
        // const isQuotePage = _.includes(_.toLower(_.get(error, 'gwInfo.method')), 'quote');
        const quoteIDFromWizard = _.get(error, 'gwInfo.params[0].quoteID');
        const quoteIDFromLocation = _.get(location, 'state.quoteentry.quoteID');
        const quoteIDParam = quoteIDFromWizard || quoteIDFromLocation;
        const redirectPath = `/quotes/${quoteIDParam}/summary`;
        const state = {
            pathname: redirectPath,
            state: error
        };

        const errorMsg = ServiceErrorUtil.getErrorMessage(error, messages.anErrorOccurred);
        return modalApi.showAlert({
            // title: isQuotePage ? messages.saveQuoteError : messages.anErrorOccurredTitle,
            // message: isQuotePage ? quoteErrorMsg : dtoErrorMsg,
            title: messages.anErrorOccurredTitle,
            message: errorMsg,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: customMessages.OK
        }).then(() => {
            setHasErrorOccurred(true);
            return <Redirect to={state} />;
        });
    }, [location]);

    const handleKnockout = useCallback(({ knockOutErrors }) => {
        history.push('/knockoutpage', { underwritingIssues: knockOutErrors });
    }, [history]);

    const handleCopySubmission = async ({
        updateWizardPageData
    }) => {
        const {
            quoteID: jobID, sessionUUID,
        } = initialSubmission.value;
        const copiedSubmission = await WniSubmissionService.copySubmission(jobID, authHeader);
        
        // const {
        //     otherActiveJobs: otherActiveJobsNewVal,
        // } = await updateActiveJobsData(jobID, sessionUUID);
        const activeJobsNewVal = await WniCommonQuoteService.getActiveQuotes(jobID, sessionUUID, authHeader);

        // setOtherActiveJobs(otherActiveJobsNewVal)
        updateWizardPageData({
            [WizardConstants.accountActiveQuotes]: activeJobsNewVal,
            [WizardConstants.copySubmission]: copiedSubmission,
        });
    };

    const wizardPageHeaderFormatter = ({
        wizardData: wizardSubmission,
        policyTypeDisplayName,
    }) => {
        const selectedTypeAndBranch = QuoteUtil.getQuoteSelectedTypeAndBranch(wizardSubmission);
        const periodStatus = _.get(wizardSubmission.value, 'baseData.periodStatus');
        const {
            lobData: {
                personalAuto: {
                    isForNamedNonOwner_Ext: isNamedNonOwner,
                }
            },
        } = wizardSubmission.value;
        let newPolicyTypeDisplayName = policyTypeDisplayName;
        if (isNamedNonOwner) {
            newPolicyTypeDisplayName = 'Named Non-Owner';
        } else {
            newPolicyTypeDisplayName = `${policyTypeDisplayName} Auto`;
        }
        return {
            jobStatusInfo: selectedTypeAndBranch,
            policyTypeDisplayName: newPolicyTypeDisplayName,
            isDisplayPolicyType: periodStatus === 'Bound',
        };
    };

    const getPageHeaderExtensionComponent = useCallback(() => {
        // return () => {
        //     return !_.isEmpty(otherActiveJobs) && <ActiveQuotesDropdownComponent accountJobs={otherActiveJobs} postalCode={postalCode} />
        // };
        return () => {
            return <WizardActiveQuotesDropdownComponent />;
        }
    }, []);

    const getSidebarExtensionComponent = useCallback(() => {
        
        return () => {

            return (
                <>
                    <WizardActiveQuotesListComponent />
                    <WizardSidebarLink />
                </>
            );
            
        };
    }, []);

    const onWizardModeChange = useCallback(({
        wizardData = {},
        nextWizardMode,
    } = {}) => {
        if (nextWizardMode === 'readOnly') {
            const isForNamedNonOwner = _.get(wizardData.value, 'lobData.personalAuto.isForNamedNonOwner_Ext', false);
            if (isForNamedNonOwner) {
                const newReadOnlySteps = WizardUtil.filterPANamedNonOwnerWizardStepsForReadOnlyMode(
                    initialReadOnlySteps, 'PADriversCoveragesPage'
                );
                updateReadOnlySteps(newReadOnlySteps);
            }
        }
    }, [updateReadOnlySteps, initialReadOnlySteps]);

    // ===============================================================================
    const defaultOnInitialization = useCallback(({
        wizardPageData,
        updateWizardReadOnly,
    }) => {
        const isUwLocked = wizardPageData[WizardConstants.isUwLocked];
        if (isUwLocked) {
            updateWizardReadOnly(true);
        }
    }, []);

    const readonlyOnInitialization = useCallback(({ updateFurthestPageVisited }) => {
        updateFurthestPageVisited('PAQuotePage');
    }, []);

    // ===============================================================================
    // if (isLoading) {
    //     return <Loader loaded={isLoading} />;
    // }

    if (!initialSubmission || !initialWizardPageData) {
        return null;
    }

    return (
        <ErrorBoundary onError={handleError}>
            <MultiModeWizard
                initialData={initialSubmission}
                modeToWizardPropsMap={
                    {
                        default: {
                            initialSteps: steps,
                            wizardTitle: title,
                            onCancel: handleCancel,
                            // onKnockOut: handleKnockout,
                            skipCompletedSteps: true,
                            wizardStepToFieldMapping,
                            wizardPageHeaderExtension: getPageHeaderExtensionComponent(),
                            wizardSidebarExtension: getSidebarExtensionComponent(),
                            wizardServiceCallbacks: {
                                retrieveSubmission: WniLoadSaveService.retrieveSubmission,
                                copySubmission: handleCopySubmission,
                            },
                            // onPreviousModalProps: {
                            //     title: commonMessages.wantToJump,
                            //     message: commonMessages.wantToJumpMessage,
                            //     status: 'warning',
                            //     icon: 'gw-error-outline',
                            //     confirmButtonText: commonMessages.yesModel,
                            //     cancelButtonText: commonMessages.cancelModel
                            // }
                            wizardDataComparator: WizardUtil.isWizardDataEqualForPA,
                            showWizardPromptMessage: true,
                            onLeaveWizard,
                            wizardPageHeaderFormatter,
                            initialWizardPageData,
                            onWizardInitialization: defaultOnInitialization,
                        },
                        readOnly: {
                            initialSteps: readOnlySteps,
                            wizardTitle: title,
                            onCancel: handleReadOnlyCancel,
                            wizardStepToFieldMapping,
                            onWizardInitialization: readonlyOnInitialization,
                            wizardPageHeaderFormatter,
                        },
                    }
                }
                initialMode={isReadOnly ? 'readOnly' : 'default'}
                onWizardModeChange={onWizardModeChange}
            />
        </ErrorBoundary>
    );
}

PASubmissionWizard.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            isReadOnly: PropTypes.bool,
            quoteentry: PropTypes.shape({
                postalCode: PropTypes.string,
                quoteID: PropTypes.string
            }),
        })
    }).isRequired
};

export default withViewModelService(withRouter(PASubmissionWizard));
