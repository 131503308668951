import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { MetadataContent } from '@jutro/uiconfig';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { getFieldModel, isYearValid } from './FieldSetModel.util'

const FieldSetModel = (props) => {
    const {
        className,
        layout,
        showRequired,
        readOnly,
        onValueChange,
        showErrors,
        onBlur,
        fieldItem,
        onValidate,
        availableValues,
        onSearch = _.noop,
        additionalValidationFn,
        additionalValidationMessages
    } = props;

    const isAdditionalValid = !additionalValidationMessages || additionalValidationMessages.length < 1;
   
    const {
        label,
        required,
        inputSetMode,
        propertyName,
        rangeValueOptions = [],
    } = fieldItem || {};
    const fieldModel = getFieldModel(inputSetMode);

    const {
        onValidate: onValidationChange, 
        isComponentValid,
        registerComponentValidation
    } = useValidation(propertyName);

    const registerFieldValidation = useCallback(() => {
        if(additionalValidationFn) {
            return additionalValidationFn()
        }
        if(inputSetMode === 'ca7_year_ext'){
            return isYearValid(fieldItem[fieldModel.value])
        }
        return true;
    }, [additionalValidationFn, fieldItem, fieldModel, inputSetMode])

    useEffect(() => {
        registerComponentValidation(registerFieldValidation)
    }, [registerFieldValidation, registerComponentValidation]);
   

    const initAvailableOptions = availableValues || rangeValueOptions;
    const initAvailableValues = initAvailableOptions.map((option) => {
        return {
            code: option,
            name: option,
        };
    });

    useEffect(() => {
        if (onValidate && !readOnly) {
            onValidate(isComponentValid, propertyName);
        }
        return () => {
            if (onValidate) {
                onValidate(true, propertyName);
            }
        }
    }, [onValidate, isComponentValid, propertyName, readOnly])

    const handleValueChange = (newValue) => {
        if(newValue === fieldItem[fieldModel.value]) {
            return false;
        }
        const newDsiplayable = {
            ...fieldItem,
            updated: true,
            isValid: !_.isNil(newValue) || newValue.length !== 0,
            [fieldModel.value]: newValue
        }
        onValueChange(newDsiplayable, fieldModel)
    };

    const handleBlur = (e, valueChanges) => {
        const { beforeValue, value } = valueChanges;
        if(value === beforeValue) {
            return false;
        }
        if(inputSetMode === 'ca7_year_ext' && !isYearValid(value)) {
            return false
        }
        onBlur(value, fieldModel)
    };

    const renderMetadata = () => {
        let dom;
        const newFieldProps = {
            className: className,
            label,
            layout,
            required,
            showRequired,
            showErrors,
            readOnly,
            availableValues: initAvailableValues,
            dataType: fieldModel.datatype,
            value: fieldItem[fieldModel.value],
            onValueChange: handleValueChange,
            onValidationChange: onValidationChange,
            onBlur: fieldModel.triggerFunc === 'onBlur' ? handleBlur : _.noop,
            ...fieldModel.defaultFieldProps,
        };
        switch (inputSetMode) {
            case 'label':
                dom = {
                    id: propertyName,
                    type: 'element',
                    component: 'h5',
                    componentProps: {
                        className: 'font-NeutralUltraDark-bold-16 ph-10'
                    },
                    content: fieldItem.label
                };
                break;
            case 'ca7_classcode_ext':
                dom = {
                    id: propertyName,
                    type: 'field',
                    component: fieldModel.component,
                    componentProps: {
                        ...newFieldProps,
                        actionFn: () => onSearch(fieldItem),
                        actionVisible: !readOnly,
                        actionConfig: {
                            id: `${propertyName}Action`,
                            text: "Search",
                            type: "filled"
                        },
                    }
                };
                break;
            case 'searchablerange':
                dom = {
                    id: propertyName,
                    type: 'field',
                    component: fieldModel.component,
                    componentProps: {
                        ...newFieldProps,
                        actionFn: () => onSearch(fieldItem),
                        actionVisible: !readOnly,
                        actionConfig: {
                            id: `${propertyName}Action`,
                            icon: "gw-search",
                            type: "filled"
                        },
                    }
                };
                break;
            default:
                const initValidationMessages = !isAdditionalValid ? {
                    validationMessages: additionalValidationMessages
                } : {}
                dom = {
                    id: propertyName,
                    type: 'field',
                    component: fieldModel.component,
                    componentProps: {
                        ...newFieldProps,
                        ...initValidationMessages
                    },
                };
                break;
        }
        return [dom]
    };

    const overrides = {};

    const resolvers = {};

    return (
        <MetadataContent
            uiProps={renderMetadata()}
            overrideProps={overrides}
            {...resolvers}
        />
    );
};

export default FieldSetModel;
