import WizardPageDataKeys from '../registries/WizardPageDataKeyRegistry';

/**
 * A place to track keys used in updateWizardPageData.
 * For the full list, see WizardPageDataKeyRegistry
 *
 * Sample Content:
 * accountClearanceData: 'WPDK_accountClearanceData',
 * paymentViewType: 'WPDK_paymentViewType',
 * sideBySideData: 'WPDK_sideBySideData',
 * isUwLocked: 'WPDK_isUwLocked',
 * quoteErrorsAndWarnings: 'WPDK_quoteErrorsAndWarnings',
 * copySubmission: 'WPDK_copySubmission',
 * landingPage: 'WPDK_landingPage',
 * policyDiffData: 'WPDK_policyDiffData',
 * paymentPlans: 'WPDK_paymentPlans',
 * paymentMethods: 'WPDK_paymentMethods',
 * dueDateExt: 'WPDK_dueDateExt',
 * driverCoverages: 'WPDK_driverCoverages',
 * lastExitWizardPage: 'WPDK_lastExitWizardPage',
 * yearForMakeOptions: 'WPDK_yearForMakeOptions',
 * yearMakeForModelOptions: 'WPDK_yearMakeForModelOptions',
 * yearMakeModelForBodyTypeOptions: 'WPDK_yearMakeModelForBodyTypeOptions',
 * productSelected: 'WPDK_productSelected',
 * isNewAccount: 'WPDK_isNewAccount'
 *
 * Note: No longer need to use WizardConstnats.CacheKey to access value of
 * wizardPageData. Instead, use wizardPageData.WPDK_cacheKey should also work.
 *
 * Sample usage:
 * wizardPageData[WizardConstants.cacheKey]
 * wizardPageData.WPDK_cacheKey
 *
 */
// { cacheKey: WPDK_cacheKey, cacheKey2: WPDK_cacheKey2 }
const WizardPageDataConstants = WizardPageDataKeys;

// [WPDK_cacheKey, WPDK_cacheKey2, WPDK_cacheKey3]
const WizardPageDataCacheKeys = Object.values(WizardPageDataConstants);


/**
 * Checks whether the <em>cacheKey</em> is valid for WizardPageData
 * @param {string} cacheKey
 * @returns {boolean}
 */
function isWizardPageDataKey(cacheKey) {
    if (!cacheKey) {
        return false;
    }
    return WizardPageDataCacheKeys.includes(cacheKey);
}

const WizardConstants = {
    ...WizardPageDataConstants,
    isWizardPageDataKey,
};

export default WizardConstants;
