
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WniAccountService } from 'wni-capability-gateway';
import { useTranslator } from '@jutro/locale';
import metadata from './AgencyInformation.metadata.json5';

const getOptions = (data = []) => {
    return data.map((item) => {
        return {
            ...item,
            name: item.displayName,
            code: item.publicID
        }
    });
};
/**

 * @param {object} props
 * @returns {object} React Component
 */
const AgencyInformation = (props) => {
    const {
        model,
        basePath,
        onValueChange,
        onValidate,
        showErrors,
        disabled
    } = props;
    const VM = model[basePath];
    const {
        agencyInfo: {
            agencyMatchData,
            licensedAgentData,
            servicingAgentData
        }
    } = VM.value;

    const { authHeader, authUserData: { publicID: userPublicID } } = useAuthentication();
    const translator = useTranslator();

    const [licensedOptions, updateLicensedOptions] = useState(getOptions(licensedAgentData));
    const [servicingOptions, updateServicingOptions] = useState(getOptions(servicingAgentData));


    const getAgencyOptions = () => {
        return agencyMatchData.map((item) => {
            return {
                ...item,
                name: `${item.code}-${item.description}-${item.city_Ext}, ${item.state_Ext}`,
                code: item.publicID,
            }
        });
    };
    
    const writeValue = (value, path) => {
        onValueChange(value, `${basePath}.${path}`);
    };
    
    const defaultAgent = () => {
        // default Licensed Producer
        const findLicensedUser = licensedAgentData.find((item) => item.publicID === userPublicID);
        const defaultLicensed = findLicensedUser ? userPublicID : null;
        // default account contact
        const findServicingUser = servicingAgentData.find((item) => item.publicID === userPublicID);
        const defaultServicing = findServicingUser ? userPublicID : null;
        // update VM
        const vmValue = {
            ...VM.value,
            producerOrLicensedAgent: defaultLicensed,
            servicingAgent: defaultServicing
        }
        writeValue(vmValue, 'value');
    };

    const handleProducerChange = async (value, path) => {
        const vmValue = {
            ...VM.value,
            producerOrLicensedAgent: null,
            servicingAgent: null,
            [path]: value
        }
        writeValue(vmValue, 'value');
        const agencyInfoData = await WniAccountService.getAgencyInfoData('', value, userPublicID, authHeader);
        const newLicensed = getOptions(agencyInfoData.licensedAgentData);
        updateLicensedOptions(newLicensed);
        const newServicing = getOptions(agencyInfoData.servicingAgentData);
        updateServicingOptions(newServicing);
    }

    useEffect(() => {
        defaultAgent();
    }, []);

    //---------
    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: 'left',
            showRequired: true,
            showOptional: false,
            disabled: disabled
        },
        externalAgency: {
            availableValues: getAgencyOptions(),
            onValueChange :handleProducerChange
        },
        licensedProducer: {
            availableValues: licensedOptions
        },
        servicingProducer: {
            availableValues: servicingOptions
        }
    };
    const resolvers = {
        resolveCallbackMap: {

        },
        resolveComponentMap: {

        }
    }
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={VM}
            onValueChange={writeValue}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
};

export default AgencyInformation;
