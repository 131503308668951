import React, { useCallback, useEffect, useState, useContext } from 'react';
import { error } from "@jutro/logger";
import _ from 'lodash';
import {
    useHistory, useLocation
} from 'react-router-dom';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { IconButton, Button, Chevron } from '@jutro/components';
// import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
// import { BaseWizardPage as WizardPage } from 'wni-portals-wizard-react';
import { useFeatureToggle, useWniModal } from 'wni-components-platform-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useProductsData, useBusinessData } from 'wni-portals-util-react';
import { FieldLinkComponent } from 'gw-components-platform-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WniLoadSaveService } from 'wni-capability-quoteandbind';
import { WniSubmissionService, WniPolicyService } from 'wni-capability-gateway';
import { WniGatewayBillingSubmissionService } from 'wni-capability-gateway-billing';
import { BreakpointTrackerContext } from '@jutro/layout';
import { DocumentsUtil, PaymentUtil } from 'wni-portals-util-js';
import { CAPaymentService } from 'wni-capability-quoteandbind-ca';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { BackLinkComponent } from 'gw-capability-gateway-react-ext';
import { InvoiceCloudPopup } from 'gw-components-platform-react-ext';
import DocumentSectionComponent from './components/DocumentSectionComponent/DocumentSectionComponent';
import AQPaymentIssuesComponent from './components/AQPaymentIssuesComponent/AQPaymentIssuesComponent';
import AQWizardPageTempalte from '../../template/AQWizardPageTemplate';
import AQWizardPage from '../../template/AQWizardPage';
import styles from './AQPaymentPage.module.scss';
import metadata from './AQPaymentPage.metadata.json5';
import messages from './AQPaymentPage.messages';
import AQQuotetableComponent from './components/QuoteTableComponent/AQQuoteTableComponent';
import EditQuotePopup from './components/EditQuotePopup/EditQuotePopup';
import DownPaymentSetup from './components/DownPaymentSetup/DownPaymentSetup';
import AQPaymentPageUtil from './utils/AQPaymentPageUtil';
import AQPaymentTableUtil from './utils/AQPaymentTableUtil';
import ICDownPaymentSetup from './components/DownPaymentSetup/ICDownPaymentSetup/ICDownPaymentSetup';

function AQPaymentPage(props) {
    const modalApi = useWniModal();
    const { state = {} } = useLocation();
    const {
        jobNumber = '',
        payStatus,
        issueJobNumber,
        initStep
    } = state;
    let initAccountNumber;
    let initAcccountHolderDisplayName;
    let issuedQuotes;
    // update issuedApprovedQuotes and issuedQuotes from param
    const issuedApprovedQuotes = jobNumber.split('_');
    if (issueJobNumber) {
        issuedQuotes = issueJobNumber.split('_');
    }

    const [accountNumber, updateAccountNumber] = useState(initAccountNumber);
    const [acccountHolderDisplayName, updateAcccountHolderDisplayName] = useState(initAcccountHolderDisplayName);

    const [quoteTableData, updateQuoteTableData] = useState([]);
    const [accountTableData, updateAccountTableData] = useState([]);
    const [accountDocuments, updateAccountDocuments] = useState([]);
    const [allTransactionIssued, updateAllTransactionIssued] = useState(false);
    const [allQuoteBillingParamValid, updateAllQuoteBillingParamValid] = useState(false);
    const { getProductName } = useProductsData();
    const history = useHistory();
    const {
        interactionModel,
        loadingMask: { setLoadingMask },
        domainCompany,
    } = useDependencies(['interactionModel', 'loadingMask', 'domainCompany']);

    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const { enableCommercialLine } = useBusinessData();

    const [currentStep, updateCurrentStep] = useState('step1');
    const [initialised, updateInitialised] = useState(false);
    const [fetchedAccountTableData, updateFetchedAccountTableData] = useState([]);
    const [initDataRetrieved, updateInitDataRetrieved] = useState(false);
    const [documentsRetrieved, updateDocumentsRetrieved] = useState(false);
    const [step2QuoteTableData, updateStep2QuoteTableData] = useState([]);
    const { routerBaseName } = appConfig;
    const [validationIssuesWarning, updateValidationIssuesWarning] = useState([]);
    const [submission, updateSubmission] = useState({});
    const [isCLProduct, updateIsCLProduct] = useState(false);
    const [productCodeState, setProductCode] = useState('');
    const [selectedQuoteNumber, setSelectedQuoteNumber] = useState('');
    const [issuesVisible, setIssuesVisible] = useState(true);
    const [producerOfRecordName, updateProducerOfRecordName] = useState('');

    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);

    const featureToggle = useFeatureToggle();
    const enableInvoiceCloudForPE = featureToggle.getFeatureCodeValue('EnableInvoiceCloudForPE');

    // jump to account summary page
    const jumpToAQAccountSummaryPage = useCallback(() => {
        return history.push(`/internal/account-quotes/${accountNumber}`);
    }, [accountNumber, history]);

    const checkAllQuoteBillingParamValid = useCallback((datas) => {
        const invalidResult = _.find(datas, (data) => {
            const dueDayOfTheMonth = _.get(data, 'dueDayOfTheMonth');
            const selectedPaymentPlan = _.get(data, 'selectedPaymentPlan');
            const primaryPayer = _.get(data, 'primaryPayer');
            return _.isNil(dueDayOfTheMonth) || _.isNil(selectedPaymentPlan) || _.isEmpty(primaryPayer);
        })
        if (_.isEmpty(invalidResult)) {
            updateAllQuoteBillingParamValid(true);
        } else {
            updateAllQuoteBillingParamValid(false);
        }
        return true;
    }, []);

    const retrieveInitData = useCallback(async (quoteIDs, isCL, agencyPayerDisplayName = '') => {
        try {
            setLoadingMask(true);
            let initData;
            if (isCLProduct || isCL) {
                initData = await CAPaymentService.retrieveCLQuotePaymentData(quoteIDs, authHeader);
                // only set PrimaryPayer Name for Agency Bil
                // quoteTable
                _.each(_.get(initData, 'quoteData', []), (data) => {
                    if (_.get(data, 'billingMethod') === 'AgencyBill') {
                        _.set(data, 'primaryPayer.originalDisplayName', _.get(data, 'primaryPayer.displayName'));
                        _.set(data, 'primaryPayer.agencyDisplayName', agencyPayerDisplayName);
                        _.set(data, 'primaryPayer.displayName', agencyPayerDisplayName);
                    }
                });
                // accountTable
                _.each(_.get(initData, 'accountData', []), (data) => {
                    if (_.get(data, 'billingMethod') === 'AgencyBill') {
                        _.set(data, 'invoiceStreamPrimaryPayer.displayName', agencyPayerDisplayName);
                    }
                });
            } else {
                initData = await WniLoadSaveService
                    .retrieveQuotePaymentData(quoteIDs, authHeader);
            }
            // quoteTableData
            const retrievedQuoteTableData = _.get(initData, 'quoteData', []);
            updateQuoteTableData(retrievedQuoteTableData);
            const hasNotIssuedQuotes = retrievedQuoteTableData.length === 0;
            updateAllTransactionIssued(hasNotIssuedQuotes);
            // accountTableData
            const retrievedAccountTableData = _.get(initData, 'accountData', []);
            updateFetchedAccountTableData(retrievedAccountTableData);
            const accountTableDataGroupByInvoiceStreamCode = AQPaymentPageUtil
                .groupDataByInvoiceStreamCode(retrievedAccountTableData);
            updateAccountTableData(accountTableDataGroupByInvoiceStreamCode);
            checkAllQuoteBillingParamValid(retrievedQuoteTableData)
        } catch (e) {
            _.noop();
        } finally {
            updateInitDataRetrieved(true);
        }
    }, [setLoadingMask, isCLProduct, checkAllQuoteBillingParamValid, authHeader]);

    const retrieveDocuments = useCallback(async (publicIDs, isCL) => {
        try {
            let documentWithProductDTOs;
            if (isCLProduct || isCL) {
                documentWithProductDTOs = await WniSubmissionService
                    .getPortalCLDocumentsForMultiSubmissionGroupByType(
                        [publicIDs], authHeader
                    );
            } else {
                documentWithProductDTOs = await WniSubmissionService
                    .getPortalDocumentsForMultiSubmissionGroupByType(
                        [publicIDs], authHeader
                    );
            }
            const newDocumentWithProductDTOs = [];
            _.each(documentWithProductDTOs, (documentWithProductDTO) => {
                const productCode = _.get(documentWithProductDTO, 'productCode');
                const newDocumentData = {
                    productName: productCode ? getProductName(productCode) : _.get(documentWithProductDTO, 'productName'),
                    jobNumber: _.get(documentWithProductDTO, 'jobNumber'),
                    selectedVersion: _.get(documentWithProductDTO, 'selectedVersion_Ext'),
                    errorsAndWarnings: {
                        underwritingIssues: _.get(documentWithProductDTO, 'underwritingIssues')
                    }
                };
                const documents = _.get(documentWithProductDTO, 'documents');
                if (documents) {
                    const retainInAgencyDocs = DocumentsUtil.getDocumentDataByVersion(documents,
                        DocumentsUtil.RETAIN_IN_AGENCY, _.get(documentWithProductDTO, 'selectedVersion_Ext'));
                    if (retainInAgencyDocs) {
                        newDocumentData.retainInAgencyDocs = retainInAgencyDocs;
                        newDocumentData.retainInAgencySubTitle = {
                            id: 'quoteandbind.pa.views.pa-policy-info.Retain in Agency',
                            defaultMessage: 'Retain in Agency',
                        };
                    }
                    const submitToWesternNationalDocs = DocumentsUtil.getDocumentData(
                        documents,
                        DocumentsUtil.SUBMIT_TO_WESTERNNATIONAL
                    );
                    if (submitToWesternNationalDocs) {
                        newDocumentData.submitToWesternNationalDocs = submitToWesternNationalDocs;
                        newDocumentData.submitToWesternNationalSubTitle = translator(messages.westernNational, { domainName: `${domainCompany.domainName}` });
                    }
                }
                newDocumentData.documents = [
                    { data: newDocumentData.retainInAgencyDocs },
                    { data: newDocumentData.submitToWesternNationalDocs }
                ];
                newDocumentWithProductDTOs.push(newDocumentData);
            });
            updateAccountDocuments(newDocumentWithProductDTOs);
        } catch (e) {
            _.noop();
        } finally {
            updateDocumentsRetrieved(true);
        }
    }, [authHeader, domainCompany.domainName, getProductName, isCLProduct, translator]);

    const fetchAccountInfoWithJobNumber = useCallback(async (quoteIDs) => {
        const rs = await WniLoadSaveService.fetchAccountInfoWithJobNumber(quoteIDs, authHeader);
        
        updateAccountNumber(_.get(rs, 'accountNumber'));
        updateAcccountHolderDisplayName(_.get(rs, 'acccountHolderDisplayName'));
        // update the accountHolder and PNI info for email default
        const tempSubmission = {};
        _.set(tempSubmission, 'policy.account.accountHolder', _.get(rs, 'accountHolder'));
        _.set(tempSubmission, 'value.baseData.primaryNamedInsured_Ext', _.get(rs, 'primaryNamedInsuredContact'));
        updateSubmission(tempSubmission);
        const isCL = _.get(rs, 'accountHolder.contactType_Ext') === 'company';
        updateIsCLProduct(isCL);
        const producerOfRecordDisplayName = _.get(rs, 'producerOfRecordName');
        updateProducerOfRecordName(producerOfRecordDisplayName);
        return {
            isCL,
            producerOfRecordDisplayName
        };
    }, [authHeader]);

    useEffect(() => {
        async function initLoading() {
            if (!initialised) {
                let isCL = false;
                let agencyPayerDisplayName = '';
                // load the data from backend service
                if (!accountNumber) {
                    const rs = await fetchAccountInfoWithJobNumber(issuedApprovedQuotes);
                    isCL = _.get(rs, 'isCL');
                    agencyPayerDisplayName = _.get(rs, 'producerOfRecordDisplayName');
                }
                await retrieveInitData(issuedApprovedQuotes, isCL, agencyPayerDisplayName);
                await retrieveDocuments(issuedApprovedQuotes, isCL);
                updateInitialised(true);
            };
        }
        initLoading();
    }, [initialised]);

        const updateQuoteTableAndStep2QuoteTableData = useCallback(async (quoteTableDataList) => {
            _.each(quoteTableDataList, (data) => {
                const primaryPayerPublicID = _.get(data, 'primaryPayer.publicID');
                _.set(data, 'primaryPayerPublicID', primaryPayerPublicID);     
                const primaryPayerDisplayName = _.get(data, 'primaryPayer.displayName');
                _.set(data, 'primaryPayerDisplayName', primaryPayerDisplayName)
            })
            const newQuote2TableData = await WniLoadSaveService
                .calculateInvoiceStreamCode(quoteTableDataList, authHeader);
            _.each(quoteTableDataList, (data) => {
                const quoteNumber = _.get(data, 'quoteNumber');
                const sessionUUID = _.get(data, 'sessionUUID');
                const toUpdate = _.find(newQuote2TableData, (newData) => {
                    return _.get(newData, 'quoteNumber') === quoteNumber
                                && _.get(newData, 'sessionUUID') === sessionUUID;
                });
                _.set(data, 'invoiceStreamCode', toUpdate.invoiceStreamCode);
                _.set(data, 'invoiceStreamDescription', toUpdate.invoiceStreamDescription);
                _.set(data, 'invoiceStreamDay', toUpdate.invoiceStreamDay);
                _.set(data, 'hasCurrentDue', toUpdate.hasCurrentDue);
            });
            const toUpdateQuoteTableData = _.cloneDeep(quoteTableDataList);
            updateQuoteTableData(toUpdateQuoteTableData);
            const step2QuoteTableDataGroupByInvoiceStreamCode = AQPaymentPageUtil
                .groupDataByInvoiceStreamCode(quoteTableDataList);
            updateStep2QuoteTableData(step2QuoteTableDataGroupByInvoiceStreamCode);
            // fetch newest documents after recalculate
            await retrieveDocuments(issuedApprovedQuotes);
            updateCurrentStep('step2');
            window.scrollTo(0, 0);
        }, [authHeader, issuedApprovedQuotes, retrieveDocuments])

    const updateCLQuoteTableAndStep2QuoteTableData = useCallback(async (quoteTableDataList) => {
        _.each(quoteTableDataList, (data) => {
            const primaryPayerPublicID = _.get(data, 'primaryPayer.publicID');
            _.set(data, 'primaryPayerPublicID', primaryPayerPublicID);     
            const primaryPayerDisplayName = _.get(data, 'primaryPayer.displayName');
            _.set(data, 'primaryPayerDisplayName', primaryPayerDisplayName)
        })
        const newQuote2TableData = await CAPaymentService
            .calculateInvoiceStreamCode(quoteTableDataList, authHeader);
        _.each(quoteTableDataList, (data) => {
            const quoteNumber = _.get(data, 'quoteNumber');
            const sessionUUID = _.get(data, 'sessionUUID');
            const toUpdate = _.find(newQuote2TableData, (newData) => {
                return _.get(newData, 'quoteNumber') === quoteNumber
                            && _.get(newData, 'sessionUUID') === sessionUUID;
            });
            _.set(data, 'invoiceStreamCode', toUpdate.invoiceStreamCode);
            _.set(data, 'invoiceStreamDescription', toUpdate.invoiceStreamDescription);
            _.set(data, 'invoiceStreamDay', toUpdate.invoiceStreamDay);
            _.set(data, 'hasCurrentDue', toUpdate.hasCurrentDue);
        });
        const toUpdateQuoteTableData = _.cloneDeep(quoteTableDataList);
        updateQuoteTableData(toUpdateQuoteTableData);
        const step2QuoteTableDataGroupByInvoiceStreamCode = AQPaymentPageUtil
            .groupDataByInvoiceStreamCode(quoteTableDataList);
        const accountAgencyBillingData = _.find(fetchedAccountTableData, (accountQuote) => {
            return _.get(accountQuote, 'billingMethod') === 'AgencyBill';
        });
        // CL set the invoice stream code to agency bill when this account exist
        if (!_.isEmpty(accountAgencyBillingData)) {
            _.each(step2QuoteTableDataGroupByInvoiceStreamCode, (groupedData) => {
                if (_.get(groupedData, 'invoiceStreamDescription') === 'New') {
                    const datas = _.get(groupedData, 'data', []);
                    const hasAgencyBill = _.find(datas, (data) => {
                        return _.get(data, 'billingMethod') === 'AgencyBill';
                    })
                    if (hasAgencyBill) {
                        _.each(datas, (data) => {
                            _.set(data, 'invoiceStreamDescription', _.get(accountAgencyBillingData, 'invoiceStreamDescription'));
                            _.set(data, 'invoiceStreamCode', _.get(accountAgencyBillingData, 'invoiceStreamCode'));
                        })
                        _.set(groupedData, 'invoiceStreamDescription', _.get(accountAgencyBillingData, 'invoiceStreamDescription'));
                        _.set(groupedData, 'invoiceStreamCode', _.get(accountAgencyBillingData, 'invoiceStreamCode'));
                    }
                }
            })
        };
        updateStep2QuoteTableData(step2QuoteTableDataGroupByInvoiceStreamCode);
        // fetch newest documents after recalculate
        await retrieveDocuments(issuedApprovedQuotes);
        updateCurrentStep('step2');
        window.scrollTo(0, 0);
    }, [authHeader, fetchedAccountTableData, issuedApprovedQuotes, retrieveDocuments])

    const onNext = useCallback(async () => {
        try {
            setLoadingMask(true);
            if (isCLProduct) {
                updateCLQuoteTableAndStep2QuoteTableData(quoteTableData)
            } else {
                updateQuoteTableAndStep2QuoteTableData(quoteTableData);
            }
            return false;
        } finally {
            setLoadingMask(false);
        }
    }, [isCLProduct, quoteTableData, setLoadingMask, updateCLQuoteTableAndStep2QuoteTableData, updateQuoteTableAndStep2QuoteTableData]);

    useEffect(() => {
        if (initDataRetrieved && documentsRetrieved) {
            setLoadingMask(false);
            if (!_.isEmpty(initStep)) {
                onNext();
            }
        }
    }, [initDataRetrieved, documentsRetrieved]);

    const onPrevious = useCallback(() => {
        updateCurrentStep('step1');
        window.scrollTo(0, 0);
        return false;
    }, []);
    const getAccountInfo = useCallback((item, index, property) => {
        const accountType = _.get(submission, 'policy.account.accountHolder.contactType_Ext');
        const url = interactionModel.getURLObj(accountType, 'accountSummary', accountNumber);
        return (
            <div>
                <div>
                    <FieldLinkComponent
                        id="accountNameLink"
                        className={styles.removeLinkStyle}
                        value={acccountHolderDisplayName}
                        {...url}
                    />
                </div>
                <div>
                    <FieldLinkComponent
                        id="accountNumberLink"
                        className={styles.removeLinkStyle}
                        value={accountNumber}
                        {...url}
                    />
                </div>
            </div>
        );
    }, [acccountHolderDisplayName, accountNumber, interactionModel, submission]);

    const saveAndUpdateBillingData = useCallback(async (res, newQuoteTableData) => {
        setLoadingMask(true);
        let rs;
        const isCL = _.get(res, 'isCL', false);
        if (isCL) {
            rs = await CAPaymentService.saveBillingParameterToPeriod(res, authHeader);
        } else {
            rs = await WniLoadSaveService.saveBillingParameterToPeriod(res, authHeader);
        }

                
        _.each(quoteTableData, (data) => {
            const dataQuoteNumber = _.get(data, 'quoteNumber');
            const updated = _.find(rs, (changed) => dataQuoteNumber === _.get(changed, 'quoteNumber'));
            if (updated) {
                // only set PrimaryPayer Name for Agency Bil
                if (_.get(updated, 'billingMethod') === 'AgencyBill') {
                    _.set(updated, 'primaryPayer.originalDisplayName', _.get(updated, 'primaryPayer.displayName'));
                    _.set(updated, 'primaryPayer.agencyDisplayName', producerOfRecordName);
                    _.set(updated, 'primaryPayer.displayName', producerOfRecordName);
                }
                newQuoteTableData.push(updated);
            } else {
                newQuoteTableData.push(data);
            }
        });
        updateQuoteTableData(newQuoteTableData);
        checkAllQuoteBillingParamValid(newQuoteTableData);
        setLoadingMask(false);
    }, [setLoadingMask, quoteTableData, checkAllQuoteBillingParamValid, authHeader, producerOfRecordName]);

    const rePopupEditQuoteRow = useCallback((modalProps) => {
        const newQuoteTableData = [];
        return modalApi.showModal(<EditQuotePopup {...modalProps} />)
            .then(async (res) => {
                await saveAndUpdateBillingData(res, newQuoteTableData);
            }).catch(() => {
                _.noop();
            }).finally(() => {
                setLoadingMask(false);
            });
    }, [modalApi, saveAndUpdateBillingData, setLoadingMask]);

    const getSearchIDs = useCallback(() => {
        let searchIDs = '';
        _.each(issuedApprovedQuotes, (searchQuoteID) => {
            searchIDs += `${searchQuoteID}_`;
        })
        searchIDs = searchIDs.substring(0, searchIDs.length - 1);
        return searchIDs;
    }, [issuedApprovedQuotes]);

    const getIssuedIDs = useCallback((modalProps) => {
        let issuedIDs = '';
        const rowData = _.get(modalProps, 'rowData');
        const datas = _.get(rowData, 'data', []);
        _.each(datas, (sub) => {
            issuedIDs += `${_.get(sub, 'quoteNumber')}_`;
        })
        issuedIDs = issuedIDs.substring(0, issuedIDs.length - 1);
        return issuedIDs;
    }, []);

    const refreshTableDataAfterIssue = useCallback(async () => {
        setLoadingMask(true);
        let initData;
        if (isCLProduct) {
            initData = await CAPaymentService
                .retrieveCLQuotePaymentData(issuedApprovedQuotes, authHeader);
        } else {
            initData = await WniLoadSaveService
                .retrieveQuotePaymentData(issuedApprovedQuotes, authHeader);
        }
        // quoteTableData
        const retrievedQuoteTableData = _.get(initData, 'quoteData', []);
        updateQuoteTableData(retrievedQuoteTableData);
        const hasNotIssuedQuotes = retrievedQuoteTableData.length === 0;
        updateAllTransactionIssued(hasNotIssuedQuotes);
        // accountTableData
        const retrievedAccountTableData = _.get(initData, 'accountData', []);
        updateFetchedAccountTableData(retrievedAccountTableData);
        const accountTableDataGroupByInvoiceStreamCode = AQPaymentPageUtil
            .groupDataByInvoiceStreamCode(retrievedAccountTableData);
        updateAccountTableData(accountTableDataGroupByInvoiceStreamCode);
        // step2QuoteTableData
        const step2QuoteTableDataGroupByInvoiceStreamCode = AQPaymentPageUtil
            .groupDataByInvoiceStreamCode(retrievedQuoteTableData);
        updateStep2QuoteTableData(step2QuoteTableDataGroupByInvoiceStreamCode);
        setLoadingMask(false);
        
        return issuedApprovedQuotes?.length > retrievedQuoteTableData?.length;
    }, [setLoadingMask, isCLProduct, issuedApprovedQuotes, authHeader]);

    const handleSaveInvoiceCloudDownPayment = useCallback(async (res, datas, searchIDs, issuedIDs) => {
        setLoadingMask(true);
        setIssuesVisible(false);
        const { product: { productCode }} = datas[0]
        // validate and issue policies
        const isInvoiceCloud = _.get(res, 'isInvoiceCloud');
        const requestParam = _.get(res, 'requestParam');
        if (!isCLProduct) {
            // For PL, set the billingMethod for the requests.
            const billingMethod = _.get(datas[0], 'billingMethod', 'DirectBill');
            _.set(res, 'billingMethod', billingMethod);
        }
        if (isInvoiceCloud) {
            // get BccUrl and Electronic issue
            try {
                // issue
                let rs;
                if (isCLProduct) {
                    rs = await CAPaymentService.wniMultiQuotesBind(datas, res, true, authHeader);
                } else {
                    rs = await WniLoadSaveService.wniMultiQuotesBind(datas, res, true, authHeader);
                }
                // refresh data
                await refreshTableDataAfterIssue();
                setSelectedQuoteNumber(rs[0]?.quoteID)
                setProductCode(productCode)
                setIssuesVisible(true);
                // check result has errors
                const bindResult = AQPaymentPageUtil.formatIssueReuslt(rs[0], PaymentUtil, updateValidationIssuesWarning);
                if (!bindResult) {
                    return false;
                }

                // update request params for surl and curl
                const invoiceCloudBaseUrl = PaymentUtil.getInvoiceCloudBaseUrl(domainCompany);
                const baseUrl = `${invoiceCloudBaseUrl}/-/vendors/ic/pay-invoice`;
                // const icUrl = `${baseUrl}?accountNum=${accountNumber}`
                const referenceNumber = _.get(requestParam, 'reference');
                const paymentDue = _.get(requestParam, 'amtValue.amount');
                const paymentType = _.get(requestParam, 'paymentType');
                const invoiceStreamDescription = _.get(requestParam, 'invoiceStreamDescription');
                const icUrl = `${baseUrl}?accountNum=BC$${invoiceStreamDescription}&referenceNum=${referenceNumber}&paymentDue=${paymentDue}&paymentType=${paymentType}`;
                const componentProps = {
                    icTitle: 'IC CloudSSO - Manage Payment Methods',
                    icUrl: icUrl
                };

                modalApi.showModal(<InvoiceCloudPopup {...componentProps} />).then(async () => {
                    // no button available for InvoiceCloudPopup
                }, _.noop);
            } catch (ex) {
                error(ex);
                PaymentUtil.showBCCFailPopup(
                    modalApi, commonMessages.genericError,
                    messages.bccErrorMessage, commonMessages.ok
                );
                return false;
            }
        } else {
            // Agency Sweep issue
            let rs;
            if (isCLProduct) {
                rs = await CAPaymentService.wniMultiQuotesBind(datas, res, true, authHeader);
            } else {
                rs = await WniLoadSaveService.wniMultiQuotesBind(datas, res, true, authHeader);
            }
            // refresh data
            await refreshTableDataAfterIssue();
            setSelectedQuoteNumber(rs[0]?.quoteID)
            setProductCode(productCode)
            setIssuesVisible(true);
            const bindResult = AQPaymentPageUtil.formatIssueReuslt(rs[0], PaymentUtil, updateValidationIssuesWarning);
            if (!bindResult) {
                return false;
            }
        }
    }, [accountNumber, authHeader, domainCompany, isCLProduct, modalApi, refreshTableDataAfterIssue, setLoadingMask]);

    const handleSaveDownPayment = useCallback(async (res, datas, searchIDs, issuedIDs) => {
        if (enableInvoiceCloudForPE) {
            const rs = await handleSaveInvoiceCloudDownPayment(res, datas, searchIDs, issuedIDs);
            return rs;
        }
        setLoadingMask(true);
        setIssuesVisible(false);
        const { product: { productCode }} = datas[0]
        // validate and issue policies
        const isBCC = _.get(res, 'isBCC');
        const requestParam = _.get(res, 'requestParam');
        if (isBCC) {
            // get BccUrl and Electronic issue
            try {
                // update request params for surl and curl
                const successCallBackUrl = `${window.location.origin}/${routerBaseName}/broadridge/payment/${searchIDs}/${issuedIDs}/success/step2`;
                const cancelCallBackUrl = `${window.location.origin}/${routerBaseName}/broadridge/payment/${searchIDs}/${issuedIDs}/cancel/step2`;
                _.set(requestParam, 'surl', successCallBackUrl);
                _.set(requestParam, 'curl', cancelCallBackUrl);
                const result = await WniGatewayBillingSubmissionService.getUrlgeneration(
                    [JSON.stringify(requestParam)], authHeader
                );
                const bccUrl = _.get(result, 'url');
                if (_.isNil(bccUrl)) {
                    PaymentUtil.showBCCFailPopup(
                        modalApi, commonMessages.genericError,
                        messages.bccErrorMessage, commonMessages.ok
                    );
                    return false;
                }
                // issue and jump link
                let rs;
                if (isCLProduct) {
                    rs = await CAPaymentService.wniMultiQuotesBind(datas, res, true, authHeader);
                } else {
                    rs = await WniLoadSaveService.wniMultiQuotesBind(datas, res, true, authHeader);
                }
                // refresh data
                await refreshTableDataAfterIssue();
                setSelectedQuoteNumber(rs[0]?.quoteID)
                setProductCode(productCode)
                setIssuesVisible(true);
                // check result has errors
                const bindResult = AQPaymentPageUtil.formatIssueReuslt(rs[0], PaymentUtil, updateValidationIssuesWarning);
                if (!bindResult) {
                    return false;
                }
                // await WniPolicyService.addRecentlyViewedPolicy(quoteID, authHeader);
                // add browser history
                window.history.pushState({}, '', cancelCallBackUrl);
                window.location.href = bccUrl;
            } catch (ex) {
                PaymentUtil.showBCCFailPopup(
                    modalApi, commonMessages.genericError,
                    messages.bccErrorMessage, commonMessages.ok
                );
                return false;
            }
        } else {
            // Agency Sweep issue
            let rs;
            if (isCLProduct) {
                rs = await CAPaymentService.wniMultiQuotesBind(datas, res, true, authHeader);
            } else {
                rs = await WniLoadSaveService.wniMultiQuotesBind(datas, res, true, authHeader);
            }
            // refresh data
            const hasAtLeastOneSucessPayment = await refreshTableDataAfterIssue();
            setSelectedQuoteNumber(rs[0]?.quoteID)
            setProductCode(productCode)
            setIssuesVisible(true);
            const bindResult = AQPaymentPageUtil.formatIssueReuslt(rs[0], PaymentUtil, updateValidationIssuesWarning);
            return hasAtLeastOneSucessPayment
            // await WniPolicyService.addRecentlyViewedPolicy(quoteID, authHeader);
        }
    }, [authHeader, enableInvoiceCloudForPE, handleSaveInvoiceCloudDownPayment, isCLProduct, modalApi, refreshTableDataAfterIssue, routerBaseName, setLoadingMask]);

    const rePopupDownPaymentSetup = useCallback((modalProps) => {
        const searchIDs = getSearchIDs();
        const issuedIDs = getIssuedIDs(modalProps);
        const rowData = _.get(modalProps, 'rowData');
        const datas = _.get(rowData, 'data', []);
        if (enableInvoiceCloudForPE) {
            return modalApi.showModal(<ICDownPaymentSetup {...modalProps} />)
            .then(async (res) => {
                await handleSaveDownPayment(res, datas, searchIDs, issuedIDs);
                _.noop();
            }).catch(() => {
                _.noop();
            }).finally(() => {
                setLoadingMask(false);
            });
        }
        return modalApi.showModal(<DownPaymentSetup {...modalProps} />)
            .then(async (res) => {
                await handleSaveDownPayment(res, datas, searchIDs, issuedIDs);
                _.noop();
            }).catch(() => {
                _.noop();
            }).finally(() => {
                setLoadingMask(false);
            });
    }, [enableInvoiceCloudForPE, getIssuedIDs, getSearchIDs, handleSaveDownPayment, modalApi, setLoadingMask]);

    const editQuoteRow = useCallback((e, item) => {
        // const productName = _.get(item, 'product.productName');
        const productCode = _.get(item, 'product.productCode');
        const productName = getProductName(productCode)
        const quoteNumber = _.get(item, 'quoteNumber');
        _.set(item, 'accountNumber', accountNumber);
        // set newAddedPrimaryPayer to false to aviod filed value empty when re-edit after new added payer
        _.set(item, 'newAddedPrimaryPayer', false);
        const selectedItem = {
            title: translator(messages.editPaymentDetails,
                { productName: productName, quoteNumber: quoteNumber }),
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: true,
            actionBtnLabel: messages.dialogOk,
            cancelBtnLabel: messages.dialogCancel,
            authHeader,
            rowData: item,
            warningMessage: translator(messages.editPaymentDetailsWarning),
            rePopupEditQuoteRow: rePopupEditQuoteRow,
            fetchedAccountTableData: fetchedAccountTableData,
            quoteTableData: quoteTableData,
            setLoadingMask,
            getProductName,
            viewModelService,
            breakpoint,
            producerOfRecordName
        };
        const newQuoteTableData = [];
        modalApi.showModal(<EditQuotePopup {...selectedItem} />)
            .then(async (res) => {
                await saveAndUpdateBillingData(res, newQuoteTableData);
            }).catch(() => {
                _.noop();
            }).finally(() => {
                setLoadingMask(false);
            });
    }, [getProductName, accountNumber, translator, authHeader,
        rePopupEditQuoteRow, fetchedAccountTableData, quoteTableData,
        setLoadingMask, viewModelService, breakpoint, modalApi, saveAndUpdateBillingData,
        producerOfRecordName]);


    const renderAgencySweepAlertMessage = () => {
        return <div>
            <p>{translator(messages.agencySweepSucessInfoMain)}</p>
            <p>{translator(messages.agencySweepSucessInfoLast)}</p>
        </div>
    }

    const callDownPaymentSetup = useCallback(async(selectedItem, datas, searchIDs, issuedIDs) => {
        if (enableInvoiceCloudForPE) {
            return modalApi.showModal(<ICDownPaymentSetup {...selectedItem} />)
            .then(async (res) => {
                await handleSaveDownPayment(res, datas, searchIDs, issuedIDs);
                // fetch newest documents after issue
                await retrieveDocuments(issuedApprovedQuotes);
                await WniPolicyService.addRecentlyViewedPolicy(selectedItem.quoteID, authHeader);
            }).catch(() => {
                _.noop();
            }).finally(() => {
                setLoadingMask(false);
            })
        }
        return modalApi.showModal(<DownPaymentSetup {...selectedItem} />)
            .then(async (res) => {
                const paymentResult = await handleSaveDownPayment(res, datas, searchIDs, issuedIDs);
                // fetch newest documents after issue
                await retrieveDocuments(issuedApprovedQuotes);
                await WniPolicyService.addRecentlyViewedPolicy(selectedItem.quoteID, authHeader);
                setLoadingMask(false);
                if (res?.downPayment === 'AgencySweep' && paymentResult) {
                    modalApi.showAlert({
                        status: 'info',
                        message: renderAgencySweepAlertMessage(),
                        icon: 'gw-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(() => {
                        _.noop();
                    });
                }

            }).catch(() => {
                _.noop();
            })
    }, [modalApi, handleSaveDownPayment, retrieveDocuments, issuedApprovedQuotes,
        authHeader, setLoadingMask, enableInvoiceCloudForPE]);

    const payAndIssueRow = useCallback((e, item) => {
        const datas = _.get(item, 'data', []);
        if (_.isEmpty(datas)) {
            datas.push(item);
        }
        const firstSubmission = _.find(datas, (sub) => {
            return _.get(sub, 'quoteNumber');
        });
        const quoteID = _.get(firstSubmission, 'quoteNumber');
        const sessionUUID = _.get(firstSubmission, 'sessionUUID');
        // payUsing default value
        const isAutoPay = _.get(firstSubmission, 'isAutoPay');
        let payUsing = _.get(firstSubmission, 'payUsing');
        const totalDownPaymentAmount = {
            amount: 0.0,
            currency: ''
        };
        _.each(datas, (sub) => {
            const submissionDownpaymentAmount = _.get(sub, 'downPaymentAmount.amount', '');
            totalDownPaymentAmount.amount = totalDownPaymentAmount.amount * 100
                + submissionDownpaymentAmount * 100;
            totalDownPaymentAmount.amount /= 100;
            const currency = _.get(sub, 'downPaymentAmount.currency', '');
            if (_.isEmpty(totalDownPaymentAmount.currency)) {
                totalDownPaymentAmount.currency = currency;
            }
        });

        const selectedItem = {
            title: translator(messages.downPaymentSetupTitle),
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            actionBtnLabel: messages.payAndIssueButton,
            cancelBtnLabel: messages.dialogCancel,
            authHeader,
            rowData: item,
            rePopupDownPaymentSetup,
            quoteID: quoteID,
            sessionUUID: sessionUUID,
            payUsing: payUsing,
            totalDownPaymentAmount: totalDownPaymentAmount,
            isMulti: datas.length > 1,
            accountNumber: accountNumber,
            setLoadingMask,
            getProductName,
            viewModelService,
            breakpoint,
            isCLProduct,
            useFeatureToggleData: featureToggle,
            domainCompany
        };
        const searchIDs = getSearchIDs();
        const issuedIDs = getIssuedIDs(selectedItem);
        // has multi quotes
        if (_.get(datas, 'length') > 1) {
            const firstSubmissionQuoteNumber = _.get(firstSubmission, 'quoteNumber');
            const editingQuotes = _.map(datas, (sub) => { 
                return {
                    quoteID: _.get(sub, 'quoteNumber'),
                    sessionUUID: _.get(sub, 'sessionUUID')
                }
            });
            let applyToOtherQuotes = _.filter(editingQuotes, (editingQuote) => _.get(editingQuote, 'quoteID') !== firstSubmissionQuoteNumber);
            applyToOtherQuotes = _.map(applyToOtherQuotes, (applyToOtherQuote) => JSON.stringify(applyToOtherQuote));
            _.set(firstSubmission, 'applyToOtherQuotes', applyToOtherQuotes);
        }
        if (!isAutoPay) {
            payUsing = 'Check';
            return modalApi.showConfirm({
                title: messages.setUpAutoPay,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result === 'confirm') {
                    // back to step1
                    updateCurrentStep('step1');
                    // open Edit Quote Row
                    // update the applyToOthers if item has multi datas
                    editQuoteRow(e, firstSubmission);
                } else if (result === 'cancel') {
                    callDownPaymentSetup(selectedItem, datas, searchIDs, issuedIDs);
                }
            }).catch(() => {
                _.noop();
            });
        }
        callDownPaymentSetup(selectedItem, datas, searchIDs, issuedIDs);
    }, [translator, authHeader, rePopupDownPaymentSetup, accountNumber,
        setLoadingMask, getProductName, viewModelService, breakpoint,
        getSearchIDs, getIssuedIDs, callDownPaymentSetup, modalApi, editQuoteRow,
        isCLProduct, featureToggle, domainCompany]);

    const issueRow = useCallback(async (e, item) => {
        try {
            setLoadingMask(true);
            const datas = _.get(item, 'data', []);
            if (_.isEmpty(datas)) {
                datas.push(item);
            }
            const firstSubmission = _.find(datas, (sub) => {
                return _.get(sub, 'quoteNumber');
            });
            const quoteID = _.get(firstSubmission, 'quoteNumber');
            const sessionUUID = _.get(firstSubmission, 'sessionUUID');
            _.set(item, 'quoteNumber', quoteID);
            _.set(item, 'sessionUUID', sessionUUID);
            let rs;
            if (isCLProduct) {
                rs = await CAPaymentService.wniMultiQuotesDirectBind([item], true, authHeader);
            } else {
                rs = await WniLoadSaveService.wniMultiQuotesDirectBind([item], true, authHeader);
            }
            const bindResult = AQPaymentPageUtil.formatIssueReuslt(rs[0], PaymentUtil, updateValidationIssuesWarning);
            if (!bindResult) {
                return false;
            }
            const { product: { productCode }} = datas[0];
            // await WniPolicyService.addRecentlyViewedPolicy(quoteID, authHeader);
            await refreshTableDataAfterIssue();
            setSelectedQuoteNumber(quoteID)
            setProductCode(productCode)
            setIssuesVisible(true);
            // fetch newest documents after issue
            await retrieveDocuments(issuedApprovedQuotes);
        } catch (ex) {
            _.noop();
        } finally {
            setLoadingMask(false);
        }
    }, [setLoadingMask, authHeader, refreshTableDataAfterIssue,
        retrieveDocuments, issuedApprovedQuotes, isCLProduct]);

    const oneTimePaymentRow = useCallback((e, item) => {
        const accountType = _.get(submission, 'policy.account.accountHolder.contactType_Ext');
        const oneTimePaymentLink = interactionModel.getURLObj(null, 'accountBilling', _.get(item, 'accountNumber'));
        window.location.href = _.get(oneTimePaymentLink, 'href') || _.get(oneTimePaymentLink, 'to');
    }, [interactionModel]);

    const renderEmtpyTableAction = useCallback((item, index) => {
        return '';
    }, []);

    const renderQuoteTableAction = useCallback((item, index) => {
        const mortgageBill = _.get(item, 'mortgageBill');
        if (mortgageBill) {
            return '';
        }
        return (
            <IconButton
                id={`edit${index}`}
                className={styles.editQuoteRowIcon}
                icon="gw-edit"
                iconColor="dark"
                size="medium"
                onClick={(e) => editQuoteRow(e, item)}
            />
        );
    }, [editQuoteRow]);

    const renderCLIssuanceQuoteTableAction = useCallback((item, index) => {
        const datas = _.get(item, 'data', []);
        if (_.isEmpty(datas)) {
            datas.push(item);
        }
        const isMortgageBill = _.find(datas, (sub) => {
            return _.get(sub, 'mortgageBill', false);
        });
        let hasDownPayment = false;
        if (!isMortgageBill) {
            hasDownPayment = _.find(datas, (sub) => {
                return _.get(sub, 'downPaymentAmount.amount', 0) > 0;
            });
        }
        
        return (
            <div style={AQPaymentTableUtil.STYLE}>
                {datas.map((sub) => {
                    if (hasDownPayment) {
                        return (
                            <div className={styles.flexDiv}>
                                <Button
                                    id={`issue${index}`}
                                    size="small"
                                    className={styles.payAndIssueButton}
                                    onClick={(e) => issueRow(e, { data: [sub] })}
                                >
                                    {translator(messages.issueNowButton)}
                                </Button>

                                <Button
                                    id={`payAndIssue${index}`}
                                    size="small"
                                    className={styles.payAndIssueButton}
                                    onClick={(e) => payAndIssueRow(e, { data: [sub] })}
                                >
                                    {translator(messages.payAndIssueButton)}
                                </Button>
                            </div>
                        );
                    } else {
                        return (
                            <div className={styles.flexDiv}>
                                <Button
                                    id={`issue${index}`}
                                    size="small"
                                    className={styles.payAndIssueButton}
                                    onClick={(e) => issueRow(e, { data: [sub] })}
                                >
                                    {translator(messages.issueNowButton)}
                                </Button>
                            </div>
                        );
                    }
                })}
            </div>
        );
    }, [issueRow, payAndIssueRow, translator])

    const renderIssuanceQuoteTableAction = useCallback((item, index) => {
        if (isCLProduct) {
            return renderCLIssuanceQuoteTableAction(item, index);
        }
        const datas = _.get(item, 'data', []);
        if (_.isEmpty(datas)) {
            datas.push(item);
        }
        const isMortgageBill = _.find(datas, (sub) => {
            return _.get(sub, 'mortgageBill', false);
        });
        let hasDownPayment = false;
        if (!isMortgageBill) {
            hasDownPayment = _.find(datas, (sub) => {
                return _.get(sub, 'downPaymentAmount.amount', 0) > 0;
            });
        }
        if (hasDownPayment) {
            const hasCurrentDue = _.find(datas, (sub) => {
                return _.get(sub, 'hasCurrentDue', false);
            });
            if (hasCurrentDue) {
                return (
                    <div style={AQPaymentTableUtil.STYLE}>
                        {datas.map((sub) => {
                            return (
                                <div>
                                    <Button
                                        id={`payAndIssue${index}`}
                                        size="small"
                                        className={styles.payAndIssueButton}
                                        onClick={(e) =>
                                            payAndIssueRow(e, { data: [sub] })
                                        }
                                    >
                                        {translator(messages.payAndIssueButton)}
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                );
            }
            return (
                <Button
                    id={`payAndIssue${index}`}
                    size="small"
                    className={styles.payAndIssueButton}
                    onClick={(e) => payAndIssueRow(e, item)}
                >
                    {translator(messages.payAndIssueButton)}
                </Button>
            );
        }
        return (
            <Button
                id={`issue${index}`}
                size="small"
                className={styles.payAndIssueButton}
                onClick={(e) => issueRow(e, item)}
            >
                {translator(messages.issueButton)}
            </Button>
        );
    }, [isCLProduct, translator, renderCLIssuanceQuoteTableAction, payAndIssueRow, issueRow]);

    const renderOneTimePaymentAction = useCallback((item, index) => {
        const datas = _.get(item, 'data');
        const bccIssued = _.find(datas, (sub) => _.includes(issuedQuotes, _.get(sub, 'quoteNumber')));
        _.set(item, 'accountNumber', accountNumber);
        if (payStatus === 'cancel' && bccIssued) {
            return (
                <Button
                    id={`oneTimePayment${index}`}
                    size="small"
                    className={styles.payAndIssueButton}
                    onClick={(e) => oneTimePaymentRow(e, item)}
                >
                    {translator(messages.oneTimePaymentButton)}
                </Button>
            );
        }
        return '';
    }, [accountNumber, issuedQuotes, oneTimePaymentRow, payStatus, translator]);


    const renderAccordionHeader = useCallback((isOpen) => {
        return (
            <React.Fragment>
                <Chevron
                    isOpen={isOpen}
                    className={styles.chevronStyle}
                />
                <h5 className={`${styles.accordionTitle} font-Primary-bold-20`}>{translator(messages.exsitingBillAccount)}</h5>
            </React.Fragment>
        );
    }, [translator]);

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                labelPosition: 'left',
            },
            pageTitle: {
                content: translator(messages.pageTitle)
            },
            accountLabelTitle: {
                content: translator(messages.account)
            },
            accountNumberTitle: {
                content: getAccountInfo()
            },
            paymentInfo: {
                content: translator(messages.policiesInfo),
            },
            invoiceFeesInfo: {
                visible: !isCLProduct,
                content: translator(messages.invoiceFeesInfo),
            },
            paymentSectionStep1: {
                visible: currentStep === 'step1'
            },
            paymentSectionStep2: {
                visible: currentStep === 'step2'
            },
            quoteTable: {
                tableData: quoteTableData,
                currentStep,
                renderQuoteTableAction,
                showPremiumColumnValue: true,
                premiumColumnVisible: true,
                showDownPaymentColumnValue: true,
                downPaymentColumnVisible: true,
                editBillingHeaderVisible: true,
                renderBillAccountCellFn: AQPaymentTableUtil.renderEmptyCell,
                dueDayOfMonthColumnPath: 'dueDayOfTheMonth',
                primaryPayerColumnPath: 'primaryPayer.displayName',
                billingMethodColumnVisible: isCLProduct
            },
            existingTable: {
                tableData: accountTableData,
                renderQuoteTableAction: renderEmtpyTableAction,
                quoteTableTitleVisible: false,
                quoteTableTitle: translator(messages.exsitingBillAccount),
                showPremiumColumnValue: false,
                premiumColumnVisible: true,
                showDownPaymentColumnValue: false,
                downPaymentColumnVisible: true,
                renderBillAccountCellFn: AQPaymentTableUtil.renderBillAccountCell,
                dueDayOfMonthColumnPath: 'invoiceStreamDay',
                primaryPayerColumnPath: 'invoiceStreamPrimaryPayer.displayName',
                billingMethodColumnVisible: isCLProduct
            },
            exsitingTableDetailsSection: {
                renderHeader: renderAccordionHeader,
                className: styles.accordionTable
            },
            step2QuoteTable: {
                tableData: step2QuoteTableData,
                renderQuoteTableAction: renderIssuanceQuoteTableAction,
                showPremiumColumnValue: true,
                premiumColumnVisible: true,
                showFeeColumnValue: true,
                feeColumnVisible: false,
                showDownPayment: false,
                showStatusColumnValue: false,
                statusColumnVisible: false,
                renderBillAccountCellFn: AQPaymentTableUtil.renderEmptyBillAccountCell,
                dueDayOfMonthColumnPath: 'dueDayOfTheMonth',
                primaryPayerColumnPath: 'primaryPayer.displayName',
                actionColumnProportion: 1.5,
                billingMethodColumnVisible: isCLProduct
            },
            step2ExistingTable: {
                tableData: accountTableData,
                renderQuoteTableAction: renderOneTimePaymentAction,
                quoteTableTitleVisible: false,
                quoteTableTitle: translator(messages.exsitingBillAccount),
                showPremiumColumnValue: false,
                premiumColumnVisible: true,
                showFeeColumnValue: false,
                feeColumnVisible: false,
                showDownPayment: false,
                showStatusColumnValue: false,
                statusColumnVisible: false,
                renderBillAccountCellFn: AQPaymentTableUtil.renderBillAccountCell,
                dueDayOfMonthColumnPath: 'invoiceStreamDay',
                primaryPayerColumnPath: 'invoiceStreamPrimaryPayer.displayName',
                billingMethodColumnVisible: isCLProduct
            },
            step2ExsitingTableDetailsSection: {
                renderHeader: renderAccordionHeader,
                className: styles.accordionTable
            },
            documentcheckedList: {
                visible: true,
                history: history,
                submission: submission,
                renderData: accountDocuments,
                showSendViaEmail: true,
                // useJobNumber: true,
                // PL -> true, CL -> false
                useJobNumber: !isCLProduct,
                useAccountNumber: isCLProduct,
                accountNumber: accountNumber,
                documentNameColumnPath: !enableCommercialLine ? undefined : 'simpleName_Ext'
            },
            conditionallyInfo: {
                content: translator(messages.conditionallyInfo),
                visible: payStatus === 'cancel' && currentStep === 'step2'
            },
            paymentIssuanceTitle: {
                content: translator(messages.paymentIssuanceTitle),
                visible: currentStep === 'step2'
            },
            backLinkContainer: {
                accountNumber,
                accountType: _.get(submission, 'policy.account.accountHolder.contactType_Ext'),
                history: history,
                showBackToAccountBillingAndPaymentLink: enableCommercialLine && _.get(accountTableData, 'length') > 0,
                justifyContent: 'right'
            },
            dynamicInlineNotificationContainer: {
                validationIssues: validationIssuesWarning,
                visible: validationIssuesWarning.length > 0 && issuesVisible,
                id: 'validationIssuesComponentId',
                scrollToIssues: true,
                jobNumber: selectedQuoteNumber,
                submission,
                productCode: productCodeState,
                history
            },
            paymentFooterButtonSection: {
                visible: payStatus,
                className: styles.alignRight
            },
            previousButton: {
                content: translator(messages.previous),
                onClick: onPrevious,
                visible: currentStep === 'step2' && step2QuoteTableData.length > 0
            },
            nextButton: {
                disabled: !allQuoteBillingParamValid,
                content: translator(messages.proceedToIssue),
                onClick: onNext,
                visible: currentStep === 'step1'
            },

        };
    }, [translator, getAccountInfo, currentStep, quoteTableData,
        renderQuoteTableAction, accountTableData, renderEmtpyTableAction,
        step2QuoteTableData, renderIssuanceQuoteTableAction,
        renderOneTimePaymentAction, history, accountDocuments,
        payStatus, accountNumber, submission,
        validationIssuesWarning, allQuoteBillingParamValid,
        onPrevious, onNext, renderAccordionHeader, enableCommercialLine, isCLProduct]);

    const renderPage = useCallback(() => {
        const overrideProps = generateOverrides();
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                renderQuoteTableAction: renderQuoteTableAction
            },
            resolveComponentMap: {
                aqquotetablecomponent: AQQuotetableComponent,
                documentsectioncomponent: DocumentSectionComponent,
                validationissuescomponent: AQPaymentIssuesComponent,
                backlinkcomponent: BackLinkComponent
            }
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={{}}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        );
    }, [generateOverrides, renderQuoteTableAction]);

    const overrideProps = generateOverrides();
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            renderQuoteTableAction: renderQuoteTableAction
        },
        resolveComponentMap: {
            aqquotetablecomponent: AQQuotetableComponent,
            documentsectioncomponent: DocumentSectionComponent,
            validationissuescomponent: AQPaymentIssuesComponent,
            backlinkcomponent: BackLinkComponent
        }
    };

    const urlComing = payStatus;
    if (urlComing) {
        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={{}}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        );
    }

    return (
        <AQWizardPage
            onNext={onNext}
            showNext={currentStep === 'step1'}
            showPrevious={currentStep === 'step2'}
            onPrevious={onPrevious}
            showCancel={false}
            template={AQWizardPageTempalte}
        >
            {renderPage}
        </AQWizardPage>
    );
}

AQPaymentPage.propTypes = AQWizardPage.propTypes;
export default AQPaymentPage;
