const yearReg = /^(19|2\d)\d{2}$/

const inputSetModelMaps = {
    ca7_vin_ext: {
        datatype: 'string',
        component: 'WniInputText',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    ca7_year_ext: {
        datatype: 'number',
        component: 'WniInputNumber',
        value: 'integerValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => Number(val),
        defaultFieldProps: {
            showGrouping: false,
            decimalPlaces: 0,
            minValue: 1900,
            maxValue: 2999,
            validator: {
                pattern: yearReg,
                message: 'Value must be a valid year between 1900 and 2999'
            }
        }
    },
    ca7_classcode_ext: {
        datatype: 'string',
        component: 'WniInputTextWithAction',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    date: {
        datatype: 'date',
        component: 'WniDate',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    dynamicdate: {
        datatype: 'date',
        component: 'WniDate',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    booleanradio: {
        datatype: 'boolen',
        component: 'WniToggle',
        value: 'boolenValue',
        triggerFunc: 'onValueChange',
        formatDefaultValue: (val) => val === 'true',
        defaultFieldProps: {}
    },
    dynamicbooleanradio: {
        datatype: 'boolen',
        component: 'WniToggle',
        value: 'boolenValue',
        triggerFunc: 'onValueChange',
        formatDefaultValue: (val) => val === 'true',
        defaultFieldProps: {}
    },
    integer: {
        datatype: 'number',
        component: 'WniInputNumber',
        value: 'integerValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => Number(val),
        defaultFieldProps: {}
    },
    dynamicinteger: {
        datatype: 'number',
        component: 'WniInputNumber',
        value: 'integerValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => Number(val),
        defaultFieldProps: {}
    },
    range: {
        datatype: 'string',
        component: 'WniDropdownSelect',
        value: 'rangeValue',
        triggerFunc: 'onValueChange',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {
            singleSelect: true
        }
    },
    dynamicrange: {
        datatype: 'string',
        component: 'WniDropdownSelect',
        value: 'rangeValue',
        triggerFunc: 'onValueChange',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {
            singleSelect: true,
            dataType: "string",
        }
    },
    picker: {
        datatype: 'date',
        component: 'WniDate',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    dynamicpicker: {
        datatype: 'date',
        component: 'WniDate',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    rangeradio: {
        datatype: 'boolen',
        component: 'WniToggle',
        value: 'boolenValue',
        triggerFunc: 'onValueChange',
        formatDefaultValue: (val) => val === 'true',
        defaultFieldProps: {}
    },
    dynamicrangeradio: {
        datatype: 'boolen',
        component: 'WniToggle',
        value: 'boolenValue',
        triggerFunc: 'onValueChange',
        formatDefaultValue: (val) => val === 'true',
        defaultFieldProps: {}
    },
    searchablerange: {
        datatype: 'string',
        component: 'WniDropdownWithAction',
        value: 'fieldValue',
        triggerFunc: 'onValueChange',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    textarea: {
        datatype: 'string',
        component: 'WniTextArea',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    dynamictextarea: {
        datatype: 'string',
        component: 'WniTextArea',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    decimal: {
        datatype: 'number',
        component: 'WniInputNumber',
        value: 'decimalValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => Number(val),
        defaultFieldProps: {}
    },
    dynamicdecimal: {
        datatype: 'number',
        component: 'WniInputNumber',
        value: 'decimalValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => Number(val),
        defaultFieldProps: {}
    },
    default: {
        datatype: 'string',
        component: 'WniInputText',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    },
    dynamicdefault: {
        datatype: 'string',
        component: 'WniInputText',
        value: 'fieldValue',
        triggerFunc: 'onBlur',
        formatDefaultValue: (val) => val,
        defaultFieldProps: {}
    }
};

const getFieldModel = (inputSetModel) => {
    return inputSetModelMaps[inputSetModel] || {}
}

const getDefaultValue = (val, fieldModel) => {
    if(!val) {
        return null
    }
    return fieldModel.formatDefaultValue(val)
};

const isYearValid = (year) => {
    return yearReg.test(year)
}
export {
    inputSetModelMaps,
    getFieldModel,
    getDefaultValue,
    isYearValid

}