import { setComponentMapOverrides } from '@jutro/uiconfig';

import CPDummyPage from './pages/Dummy/CPDummyPage';
import CPDummyReadOnlyPage from './pages/Dummy/CPDummyPage.readonly';

import CPQuotePage  from './pages/Quote/CPQuotePage';
import CPQuoteReadOnlyPage from './pages/Quote/CPQuotePage.readonly';


setComponentMapOverrides(
    {
        CPDummyPage: {component: 'CPDummyPage'},
        CPDummyReadOnlyPage: { component: 'CPDummyReadOnlyPage' },

        //
        CPQuotePage: {component: 'CPQuotePage'},
        CPQuoteReadOnlyPage: {component: 'CPQuoteReadOnlyPage'},

    },
    {
        CPDummyPage,
        CPDummyReadOnlyPage,

        //
        CPQuotePage,
        CPQuoteReadOnlyPage,
    }
);


export {
    CPDummyPage,
    CPDummyReadOnlyPage,
    //
};

// eslint-disable-next-line import/prefer-default-export
export { default as CPSubmissionWizard } from './CPSubmissionWizard';
export { default as CPWizardPage } from './templates/CPWizardPage';

export { default as CPQuoteIssueRenderHelper } from './pages/Quote/util/CPQuoteIssueRenderHelper';
export { default as CPQuotePageConstants } from './pages/Quote/config/CPQuotePage.config';
