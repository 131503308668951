import React, {
    useContext,
    useState,
    useCallback,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IntlContext } from '@jutro/locale';
import { R1Config } from 'wni-portals-config-js';
import { WniClausesUtil } from 'wni-portals-util-js';
import { ClauseComponent } from 'gw-components-platform-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { BaseWizardPage } from 'wni-portals-wizard-react';

// import CPCoveargesPageMain from '../../../Coverages/CPCoveragesPage.main';
const CPCoveargesPageMain = () => null;

/**
 * Clause component hierarchy diagram:
 * SideBySIdeSingleClauseComponentVM -> SideBySideClauseCmoponent
 * @param {object} props
 * @returns {object}
 */
function CPQuoteClauseTableComponent(props) {

    const {
        onValidate,
        showErrors,
        //
        afterUpdateCoverage,
        updateErrorsAndWarnings,
        updateWizardData,
        //
        isEditable, // default to true
        //
        ...restProps
    } = props;

    const intl = useContext(IntlContext);
    const [isEditing, setIsEditing] = useState(false);
    const [loadingLineCoverages, setLoadingLineCoverages] = useState(false);

    // const getCoverageLabel = (labelName, labelAmount) => {
    //     if (hiddenAmountCoveragePatterns.includes(clausePatternCode)) {
    //         return labelName;
    //     }
    //     return WniClausesUtil.getLabelNameWithAmount(labelName, labelAmount, intl, showAmount);
    // };

    const updateErrorsAndWarningsForCovComponent = useCallback((errorsAndWarnings) => {
        updateErrorsAndWarnings(errorsAndWarnings);
    }, [updateErrorsAndWarnings]);

    const updateWizardDataOnQuotePage = useCallback(async (wizardData) => {
        updateWizardData(wizardData);
        await afterUpdateCoverage();
    }, [afterUpdateCoverage]);

    return (
        <CPCoveargesPageMain
            {...restProps}
            updateWizardData = {updateWizardDataOnQuotePage}
            //
            coveragesPageProps = {{
                onValidate,
                updateErrorsAndWarningsForCovComponent,
                //
                showErrors,
                //
                setIsEditing,
                isEditing,
                //
                setLoadingLineCoverages,
                loadingLineCoverages,
                //
                isEditable,
            }}
        />
    );
}

CPQuoteClauseTableComponent.propTypes = {
    ...wizardProps,
    //
    onValidate: PropTypes.func,
    afterUpdateCoverage: PropTypes.func,
    updateErrorsAndWarnings: PropTypes.func,
    //
    isEditable: PropTypes.bool,
};

CPQuoteClauseTableComponent.defaultProps = {
    ...BaseWizardPage.defaultProps,
    //
    onValidate: _.noop,
    afterUpdateCoverage: _.noop,
    updateErrorsAndWarnings: _.noop,
    //
    isEditable: true,
};


export default CPQuoteClauseTableComponent;